import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useAsideContext } from "../../generic/providers/AsideProvider";
import { Cart } from "../../images/cart/cart";
import { FilledCart } from "../../images/cart/filled-cart";
import styles from "./index.module.css";

type Props = {
  variant?: "white" | "black";
};
const AsideCartButton: FC<Props> = ({ variant = "white" }) => {
  const { setAsideAsPopupIsOpen } = useAsideContext();

  const navigate = useNavigate();

  const isActiveStartWithCheck = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div
      className={styles.cart}
      onClick={() => {
        navigate("/cart");
        setAsideAsPopupIsOpen(false);
      }}
    >
      {variant === "white" ? (
        <FilledCart isActive={isActiveStartWithCheck("/cart")} />
      ) : (
        <Cart isActive={isActiveStartWithCheck("/cart")} />
      )}
    </div>
  );
};

export default AsideCartButton;
