import React, { FC } from "react";

import { Question } from "../../../lib/models/questionnaire";
import QuestionnaireInputs from "../QuestionnaireInputs";
import styles from "./index.module.css";

type Props = {
  question: Question;
};

const QuestionnaireMultiInput: FC<Props> = ({ question }) => {
  return (
    <div className={question.markup.size === "small" ? styles.container : styles.grid}>
      {question.child_questions?.map((q) => <QuestionnaireInputs question={q} key={q.question_id} />)}
    </div>
  );
};

export default React.memo(QuestionnaireMultiInput);
