import "./customDatePicker.css";

import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Calendar, DateObject, Value } from "react-multi-date-picker";

import { ReactComponent as LeftArrow } from "../../images/arrows/leftSliderArrow.svg";
import { ReactComponent as RightArrow } from "../../images/arrows/rightSliderArrow.svg";
import styles from "./index.module.css";

const gregorian_russian = {
  name: "gregorian_russian",
  months: [
    ["Январь", "Январь"],
    ["Февраль", "Февраль"],
    ["Март", "Март"],
    ["Апрель", "Апрель"],
    ["Май", "Май"],
    ["Июнь", "Июнь"],
    ["Июль", "Июль"],
    ["Август", "Август"],
    ["Сентбярь", "Сентбярь"],
    ["Октябрь", "Октябрь"],
    ["Ноябрь", "Ноябрь"],
    ["Декабрь", "Декабрь"],
  ],
  weekDays: [
    ["Суббота", "СБ"],
    ["Воскресенье", "ВС"],
    ["Понедельник", "ПН"],
    ["Вторник", "ВТ"],
    ["Среда", "СР"],
    ["Четверг", "ЧТ"],
    ["Пятница", "ПТ"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

type Props = {
  defaultValue: Date;
  value: Date;
  onChange: (value: Date) => void;
  maxYear?: number;
};

const CustomDatePicker: FC<Props> = ({
  defaultValue,
  onChange: setPropsValue,
  maxYear = new DateObject().year,
  value: propsValue,
}) => {
  const [value, setValue] = useState<Value>(new DateObject(defaultValue));
  const selectedDate = useMemo(() => new DateObject(String(value)), [value]);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setPropsValue(new Date(String(value)));
    }
  }, [value]);

  useEffect(() => {
    if (propsValue !== value) {
      isInitialMount.current = true;
      setValue(new DateObject(propsValue));
    }
  }, [propsValue]);

  const addYear = (duration: number) => {
    if (selectedDate.year + duration <= maxYear) {
      setValue(selectedDate.add(duration, "year"));
    }
  };

  const addMonth = (duration: number) => {
    const copyDate = new DateObject(selectedDate);
    copyDate.add(duration, "month");
    if (copyDate.year <= maxYear) {
      setValue(selectedDate.add(duration, "month"));
    }
  };

  return (
    <div className={`customDatePicker ${styles.container}`}>
      <div className={styles.header}>
        <div className={styles.slider}>
          <button className={styles.arrowButton} onClick={() => addMonth(-1)}>
            <LeftArrow />
          </button>
          <span className={styles.month}>{String(gregorian_russian.months[selectedDate.monthIndex][0])}</span>
          <button className={styles.arrowButton} onClick={() => addMonth(1)}>
            <RightArrow />
          </button>
        </div>
        <div className={styles.slider}>
          <button className={styles.arrowButton} onClick={() => addYear(-1)}>
            <LeftArrow />
          </button>
          <span>{selectedDate.year}</span>
          <button className={styles.arrowButton} onClick={() => addYear(1)}>
            <RightArrow />
          </button>
        </div>
      </div>
      <Calendar
        weekStartDayIndex={1}
        locale={gregorian_russian}
        disableYearPicker
        disableMonthPicker
        value={value}
        onChange={setValue}
      />
    </div>
  );
};

export default CustomDatePicker;
