import React, { CSSProperties, FC, ImgHTMLAttributes } from "react";

import styles from "./index.module.css";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  overlap?: boolean;
  objectFit?: CSSProperties["objectFit"];
}

const ImageWithOverlapColor: FC<Props> = (props) => {
  const { objectFit = "cover" } = props;
  return (
    <div style={props.style} className={`${props.className} ${styles.gradient} `}>
      <img {...props} alt={props.alt} style={{ objectFit: objectFit, color: "transparent" }} className={styles.image} />
    </div>
  );
};

export default ImageWithOverlapColor;
