import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { http, publicHttp } from "../../API";
import { REFRESH_TOKEN } from "../../lib/consts/consts";
import { GlobalUrls } from "../../lib/routes";
import { useAuth } from "../AuthProvider/AuthProvider";

interface AxiosProviderProps {
  children: React.ReactNode;
}

const AxiosProvider = (props: AxiosProviderProps) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const interceptor = http.interceptors.response.use(
      (config) => {
        return config;
      },
      async (error) => {
        const originalRequest = { ...error.config };
        originalRequest._isRetry = true;
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
          try {
            const resp = await publicHttp.post(`/auth/jwt/refresh`, {
              refresh: localStorage.getItem(REFRESH_TOKEN),
            });
            localStorage.setItem("access", resp.data.access);
            return http.request(originalRequest);
          } catch (error) {
            console.error("Authorization Error");
            logout();
            navigate(GlobalUrls.LOGIN, { replace: true });
            throw new Error("Authorization Error");
          }
        }
        throw error;
      }
    );
    return () => {
      http.interceptors.response.eject(interceptor);
    };
  }, []);

  return <>{props.children}</>;
};

export default AxiosProvider;
