import React, { FC } from "react";

type Props = {
  isActive?: boolean;
  white?: boolean;
};

const BlankIcon: FC<Props> = ({ isActive = false, white }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask x="0" y="0" width="25" height="24">
        <rect x="0.667969" width="24" height="24" fill={isActive ? "#6100FF" : "#151515"} />
      </mask>
      <g mask="url(#mask0_3119_8180)">
        <path
          d="M11.6679 20.5008H5.97567C5.47054 20.5008 5.04297 20.3258 4.69297 19.9758C4.34297 19.6258 4.16797 19.1983 4.16797 18.6931V5.30857C4.16797 4.80344 4.34297 4.37587 4.69297 4.02587C5.04297 3.67587 5.47054 3.50087 5.97567 3.50087H10.3814C10.5199 3.0137 10.8029 2.60987 11.2305 2.28937C11.658 1.96885 12.1372 1.80859 12.6679 1.80859C13.2192 1.80859 13.7061 1.96885 14.1285 2.28937C14.5509 2.60987 14.8314 3.0137 14.9698 3.50087H19.3602C19.8654 3.50087 20.2929 3.67587 20.6429 4.02587C20.9929 4.37587 21.1679 4.80344 21.1679 5.30857V10.0008H19.6679V5.30857C19.6679 5.23164 19.6359 5.16111 19.5718 5.09699C19.5077 5.03289 19.4372 5.00084 19.3602 5.00084H17.1679V7.61619H8.16797V5.00084H5.97567C5.89874 5.00084 5.82821 5.03289 5.76409 5.09699C5.69999 5.16111 5.66794 5.23164 5.66794 5.30857V18.6931C5.66794 18.7701 5.69999 18.8406 5.76409 18.9047C5.82821 18.9688 5.89874 19.0008 5.97567 19.0008H11.6679V20.5008ZM16.1679 19.57L12.2641 15.6662L13.3179 14.6124L16.1679 17.4624L21.8372 11.7932L22.891 12.847L16.1679 19.57ZM12.6679 5.11624C12.9256 5.11624 13.1407 5.03003 13.3131 4.85759C13.4856 4.68516 13.5718 4.47009 13.5718 4.21239C13.5718 3.95471 13.4856 3.73965 13.3131 3.56722C13.1407 3.39479 12.9256 3.30857 12.6679 3.30857C12.4103 3.30857 12.1952 3.39479 12.0228 3.56722C11.8503 3.73965 11.7641 3.95471 11.7641 4.21239C11.7641 4.47009 11.8503 4.68516 12.0228 4.85759C12.1952 5.03003 12.4103 5.11624 12.6679 5.11624Z"
          fill={isActive ? "#6100FF" : white ? "#FFFFFF" : "#151515"}
        />
      </g>
    </svg>
  );
};

export default BlankIcon;
