import { isAxiosError } from "axios";
import { useState } from "react";
import { create } from "zustand";
export const useFetching = (errorMessage = "Произошла ошибка") => {
  type useFetchingType = {
    isLoading: boolean;
    errorMessage: string;
    fetching: (callback: () => void) => void;
  };

  const [useStore] = useState(() =>
    create<useFetchingType>()((set) => ({
      isLoading: false,
      errorMessage: "",
      fetching: async (callback: () => void) => {
        try {
          set({
            isLoading: true,
            errorMessage: "",
          });
          await callback();
        } catch (e) {
          if (isAxiosError(e) && e.response && e.response.data.detail) {
            set({ errorMessage: e.response.data.detail });
          } else {
            set({ errorMessage: errorMessage });
          }
        } finally {
          set({ isLoading: false });
        }
      },
    }))
  );

  return { ...useStore((state) => state) };
};
