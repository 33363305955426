import { motion } from "framer-motion";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { useQuestionnaireState } from "../../../../lib/hooks/useQuestionnaireState";
import { AnswerType, Question } from "../../../../lib/models/questionnaire";
import QuestionnaireTimer from "../../QuestionnaireTimer";
import styles from "./index.module.css";

type Props = {
  question: Question;
};

const variants = {
  hidden: {
    scale: 0,
  },
  show: (index: number) => ({
    scale: 1,
    transition: { delay: index * 0.1 },
  }),
};

const timeRegex = /^([01]\d|2[0-3]):[0-5]\d$/;

function isTimeValid(timeString: string) {
  return timeRegex.test(timeString);
}

const getTime = (answers: AnswerType) => {
  if (typeof answers === "string") {
    if (isTimeValid(answers)) {
      const time = answers.split(":");
      return { hours: time[0], minutes: time[1] };
    }
  }
  return { hours: "00", minutes: "00" };
};

const TimeForm: FC<Props> = ({ question }) => {
  const {
    state: [value, setValue],
  } = useQuestionnaireState<string>({ question });
  const [hours, setHours] = useState<string>(getTime(value).hours);
  const [minutes, setMinutes] = useState<string>(getTime(value).minutes);
  const timeValue = useMemo(() => ({ hours, minutes }), [hours, minutes]);
  const initialMountRef = useRef(true);

  useEffect(() => {
    if (initialMountRef.current) {
      initialMountRef.current = false;
    } else {
      setValue(`${timeValue.hours}:${timeValue.minutes}`);
    }
  }, [timeValue]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.timerWrapper}>
          <motion.div
            className={styles.backgroundIcon}
            style={{ backgroundImage: `url(${question.markup.iconBackgroundUrl})` }}
          />
          <motion.div
            key={"hours"}
            variants={variants}
            initial="hidden"
            animate="show"
            custom={0}
            className={styles.element}
          >
            <QuestionnaireTimer initValue={hours} setValue={setHours} max={24} />
            <span className={styles.caption}>Часы</span>
          </motion.div>
          <span className={styles.dots}>:</span>
          <motion.div
            key={"minutes"}
            variants={variants}
            initial="hidden"
            animate="show"
            custom={1}
            className={styles.element}
          >
            <QuestionnaireTimer initValue={minutes} setValue={setMinutes} max={60} />
            <span className={styles.caption}>Минуты</span>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default TimeForm;
