import { motion } from "framer-motion";
import React from "react";

import { useSectionPassingContext } from "../QuestionnairePassing/SectionPassingProvider";
import QuestionnaireStep from "../QuestionnaireStep";

const container = {
  hidden: { opacity: 1, scale: 0 },
  exit: {
    opacity: 0,
    scale: 1,
    x: -100,
    transition: {
      delayChildren: 0.0,
      staggerChildren: 0.1,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.03,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 20,
    opacity: 0,
  },
};

const QuestionnaireSteps = () => {
  const { questions, currentQuestion, setCurrentQuestion, displayNumbers } = useSectionPassingContext();

  return (
    <motion.ul
      style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}
      variants={container}
      initial="hidden"
      animate="visible"
      exit="exit"
      key={"questionList"}
    >
      {questions.map((question, index) => (
        <motion.li style={{ listStyle: "none" }} variants={item} key={"question" + question.question_id}>
          <QuestionnaireStep
            completed={!!question.answer}
            selected={currentQuestion.question_id === question.question_id}
            onClick={() => {
              window.scroll(0, 0);
              setCurrentQuestion(question);
            }}
            number={`${displayNumbers.at(index)}`}
          />
        </motion.li>
      ))}
    </motion.ul>
  );
};

export default QuestionnaireSteps;
