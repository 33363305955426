import { create } from "zustand";

import { http } from "../API";
import { AllBacterum, AllMainRisk, FullTestResult, Patogenes, ProductList } from "../lib/models/test";

interface TestStore extends FullTestResult {
  taxones: AllBacterum[];
  vitamines: {
    bacteria: string[];
    current_speed: number;
    lower_speed: number;
    upper_speed: number;
    vitamin: string;
  }[];
  supplementVitamines: {
    vitamin: string;
    dose: string;
    bact_proof: string[];
  }[];
  risks: AllMainRisk[];
  supplements: {
    category: string;
    additives: {
      name: string;
      bact_proof: string[];
    }[];
  }[];
  nutrition: {
    calories?: number;
    proteins?: number;
    fats?: number;
    carbohydrates?: number;
  };
  specialBacteries: {
    id: number;
    effect: string;
    name: string;
    patient_quantity: number;
    norm: {
      lower: number;
      upper: number;
    };
  }[];
  patogenesBacteries: Patogenes;
  positiveBacteries: {
    id: number;
    effect: string;
    name: string;
    norm: {
      lower: number;
    };
    patient_quantity: number;
  }[];
  dietaryGreen: ProductList[];
  dietaryYellow: ProductList[];
  dietaryRed: ProductList[];
  microbioteAge: number;
  patientAge: number;
  healthyIndex: number;
  fetchTest: (id: number | string) => void;
  setTest: (test: FullTestResult) => void;
  idDiet: number | null;
}

const initialState: any = {
  taxones: [],
  vitamines: [],
  risks: [],
  dietaryGreen: [],
  dietaryYellow: [],
  dietaryRed: [],
  supplements: [],
  specialBacteries: [],
  patogenesBacteries: {},
  positiveBacteries: [],
  microbioteAge: 0,
  nutrition: {},
  patientAge: 0,
  healthyIndex: 0,
  idDiet: 0,
};

export const useTestStore = create<TestStore>((set) => ({
  ...initialState,
  fetchTest: async (id) => {
    try {
      const response = await http.get(`/api/tests/${id}/result`);
      set({
        taxones: response.data.result.ALL_Bacteria,
        microbioteAge: response.data.result.microbiota_age,
        patientAge: response.data.result.patient_info.age,
        healthyIndex: response.data.result.healthy_index,
        patogenesBacteries: response.data.result.patogenes,
        specialBacteries: response.data.result.special_bacter,
        positiveBacteries: response.data.result.probiotics,
        dietaryGreen: response.data.result.green_list,
        dietaryRed: response.data.result.red_list,
        dietaryYellow: response.data.result.red_list,
        risks: response.data.result.all_main_risk,
        nutrition: response.data.result.diet.nutrition,
        supplements: response.data.result.additives,
        vitamines: response.data.result.vitamin_speed,
        supplementVitamines: response.data.result.vitamins,
        idDiet: response.data.result.patient_info.id_diet,
      });
    } catch (e) {
      set(initialState);
    }
  },
  setTest: (testData) => {
    try {
      set({
        taxones: testData.ALL_Bacteria,
        microbioteAge: testData.microbiota_age,
        patientAge: testData.patient_info.age,
        healthyIndex: testData.healthy_index,
        patogenesBacteries: testData.pathogens,
        specialBacteries: testData.special_bact,
        positiveBacteries: testData.probiotics,
        dietaryGreen: testData.green_list,
        dietaryRed: testData.red_list,
        dietaryYellow: testData.red_list,
        risks: testData.all_main_risk,
        nutrition: testData.diet?.nutrition,
        supplements: testData.additives,
        vitamines: testData.vitamin_speed,
        idDiet: Number(testData.patient_info.id_diet),
      });
    } catch (e) {
      set(initialState);
    }
  },
}));
