import Cleave from "cleave.js/react";
import { motion } from "framer-motion";
import React, { FC } from "react";

import { useQuestionnaireState } from "../../../../lib/hooks/useQuestionnaireState";
import { Question } from "../../../../lib/models/questionnaire";
import styles from "./index.module.css";

type Props = {
  question: Question;
  size?: string;
  format?: string;
};

const inputVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: {
    opacity: 1,
    scale: 1,
  },
  whileHover: { y: -5, opacity: 1 },
  whileTap: { opacity: 0.5, y: 0 },
};

const PressureInput = ({ question }: { question: Question }) => {
  const {
    state: [value, setValue],
  } = useQuestionnaireState<string>({ question, sendFormat: (value) => value.replaceAll(" ", "") });
  return (
    <Cleave
      onChange={(e) => {
        setValue(e.target.value);
      }}
      value={value}
      placeholder={"120 / 80"}
      className={styles.input}
      options={{ blocks: [3, 3], delimiter: " / " }}
    />
  );
};

const OpenAnswerForm: FC<Props> = ({ question, size, format }) => {
  const {
    state: [value, setValue],
  } = useQuestionnaireState<string>({ question });

  const render = () => {
    if (format === "pressure") {
      return <PressureInput question={question} />;
    }
    if (question.markup.format === "int" || question.markup.format === "float") {
      return (
        <>
          <Cleave
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
            placeholder={question.markup.placeholder}
            className={styles.input}
            options={{
              numeral: true,
              numeralPositiveOnly: true,
              delimiter: "",
            }}
          />
        </>
      );
    }
    if (size === "small") {
      return (
        <>
          <input
            className={styles.input}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder={question.markup.placeholder || "Введите ответ"}
          />
        </>
      );
    }
    if (size === "big") {
      return (
        <div style={question?.is_child ? { width: "18.75em" } : {}} className={styles.textareaWrapper}>
          <textarea
            className={styles.textarea}
            key={question.question_id}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder={question.markup.placeholder || "Введите ответ"}
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={styles.container}>
      {size == "small" ? (
        <>
          <motion.div
            variants={inputVariants}
            initial="hidden"
            animate="show"
            whileTap="whileTap"
            className={styles.inputWrapper}
            whileHover="whileHover"
          >
            <div
              className={styles.backgroundIcon}
              style={{ backgroundImage: `url(${question.markup.iconBackgroundUrl})` }}
            />
            <div className={styles.icon} style={{ backgroundImage: `url(${question.markup.iconFieldUrl})` }} />
            {render()}
          </motion.div>
        </>
      ) : (
        <>
          <motion.div variants={inputVariants} initial="hidden" animate="show">
            {render()}
          </motion.div>
        </>
      )}
    </div>
  );
};

export default React.memo(OpenAnswerForm);
