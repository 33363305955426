import React, { forwardRef, useEffect } from "react";

import styles from "./index.module.css";

type Props = {
  iframeIsLoading: boolean;
  setIframeIsLoading: (value: boolean) => void;
  onLoad: () => void;
  startLocation: string;
};

const OrgPatientIframe = forwardRef<HTMLIFrameElement, Props>(
  ({ onLoad, setIframeIsLoading, iframeIsLoading, startLocation }, ref) => {
    useEffect(() => {
      return () => {
        setIframeIsLoading(true);
      };
    }, []);

    return (
      <iframe
        style={iframeIsLoading ? { opacity: 0 } : { opacity: 1 }}
        onError={() => setIframeIsLoading(false)}
        onLoad={onLoad}
        className={styles.container}
        ref={ref}
        src={window.location.origin + "/iframe" + startLocation}
      />
    );
  }
);

OrgPatientIframe.displayName = "OrgPatientIframe";

export default OrgPatientIframe;
