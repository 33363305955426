import React, { FC, ReactNode } from "react";

import OverlayingPopup from "../OverlayingPopup";
import styles from "./index.module.css";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
};

const Drawer: FC<Props> = ({ children, isOpen, onClose }) => {
  return (
    <OverlayingPopup isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>{children}</div>
    </OverlayingPopup>
  );
};

export default Drawer;
