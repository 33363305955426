import React, { createContext, FC, ReactNode, useContext, useState } from "react";

import AsideAsPopup from "../../components/AsideAsPopup";
import AsideMobileHeaderPortal from "../../components/AsideMobileHeaderPortal";
import { PatientAsideCard } from "../../components/PatientAsideCard";
import PatientAsideHeaderCard from "../../components/PatientAsideHeaderCard";
import TestPickerPopup from "../../components/TestPickerPopup";
import { useDoctorStore } from "../../store/useDoctorStore";
import Portal from "../../UI/Portal";

type AsideProviderProps = {
  children: ReactNode;
};

type AsideContextType = {
  asideElement: HTMLDivElement | null;
  asideHeaderPortalElement: HTMLDivElement | null;
  asideMobilePortalElement: HTMLDivElement | null;
  setAsideElement: (value: HTMLDivElement | null) => void;
  setAsideHeaderPortalElement: (value: HTMLDivElement | null) => void;
  setAsideMobilePortalElement: (value: HTMLDivElement | null) => void;
  consultationsPopupIsOpen: boolean;
  setConsultationsPopupIsOpen: (value: boolean) => void;
  setTestPickerPopupIsOpen: (value: boolean) => void;
  asideAsPopupIsOpen: boolean;
  setAsideAsPopupIsOpen: (value: boolean) => void;
};

const AsideContext = createContext<AsideContextType | null>(null);

export const useAsideContext = () => {
  const context = useContext(AsideContext);

  if (!context) {
    throw new Error("useAsideContext must be used within a AsideProvider");
  }

  return context;
};

const AsideProvider: FC<AsideProviderProps> = ({ children }) => {
  const [testPickerPopupIsOpen, setTestPickerPopupIsOpen] = useState<boolean>(false);
  const [asideElement, setAsideElement] = useState<HTMLDivElement | null>(null);
  const [asideHeaderPortalElement, setAsideHeaderPortalElement] = useState<HTMLDivElement | null>(null);
  const [asideMobilePortalElement, setAsideMobilePortalElement] = useState<HTMLDivElement | null>(null);
  const { reviewPatient } = useDoctorStore((state) => state);
  const [asideAsPopupIsOpen, setAsideAsPopupIsOpen] = useState<boolean>(false);

  const [consultationsPopupIsOpen, setConsultationsPopupIsOpen] = useState<boolean>(false);
  return (
    <AsideContext.Provider
      value={{
        asideAsPopupIsOpen,
        setAsideAsPopupIsOpen,
        consultationsPopupIsOpen,
        setConsultationsPopupIsOpen,
        asideElement,
        setAsideElement,
        asideHeaderPortalElement,
        setAsideHeaderPortalElement,
        asideMobilePortalElement,
        setAsideMobilePortalElement,
        setTestPickerPopupIsOpen,
      }}
    >
      {reviewPatient && (
        <>
          <AsideMobileHeaderPortal>
            <PatientAsideHeaderCard reviewPatient={reviewPatient} />
          </AsideMobileHeaderPortal>
          {asideHeaderPortalElement && (
            <Portal element={asideHeaderPortalElement}>
              <PatientAsideCard reviewPatient={reviewPatient} />
            </Portal>
          )}
        </>
      )}
      {children}
      <TestPickerPopup isOpen={testPickerPopupIsOpen} setIsOpen={setTestPickerPopupIsOpen} />
      <AsideAsPopup isOpen={asideAsPopupIsOpen} onClose={() => setAsideAsPopupIsOpen(false)} />
    </AsideContext.Provider>
  );
};

export default AsideProvider;
