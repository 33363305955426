import React from "react";
import { Link } from "react-router-dom";

import { Logo } from "../../../../images/logo";
import AsideCartButton from "../../../AsideCartButton";
import AsideConsultationsButton from "../../../AsideConsultationsButton";
import styles from "./NovabiomPatientAsideHeaderVariant.module.css";

const NovabiomPatientAsideHeaderVariant = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Link to="/">
          <Logo white={true} />
        </Link>
      </div>
      <div className={styles.buttons}>
        <AsideConsultationsButton />
        <AsideCartButton />
      </div>
    </div>
  );
};

export default NovabiomPatientAsideHeaderVariant;
