import { create } from "zustand";

import { createConsultation, deleteConsultation, getConsultations } from "../API/ConsultationService";
import { Consultation } from "../lib/models/Consultation";
import { Timeslot } from "../lib/models/Timeslot";

interface ConsultationsStoreState {
  popupIsOpen: boolean;
  switchPopup: () => void;
  closePopup: () => void;
  openPopup: () => void;
  consultations: Consultation[];
  setConsultations: (consultations: Consultation[]) => void;
  fetchConsultations: () => void;
  deleteConsultation: (consultationId: number) => void;
  makeRecord: (TimeSlot: Timeslot, doctorId: number) => void;
}

export const useConsultationsStore = create<ConsultationsStoreState>((set, get) => ({
  popupIsOpen: false,
  switchPopup: () => {
    set({ popupIsOpen: !get().popupIsOpen });
  },
  closePopup: () => {
    set({ popupIsOpen: false });
  },
  openPopup: () => {
    set({ popupIsOpen: true });
  },
  consultations: [],
  setConsultations: (consultations) => {
    set({ consultations });
  },
  fetchConsultations: async () => {
    const response = await getConsultations();
    set({ consultations: response });
  },
  deleteConsultation: async (consultationId) => {
    await deleteConsultation(consultationId);
    get().fetchConsultations();
  },
  makeRecord: async (TimeSlot, doctorId) => {
    const response = await createConsultation(TimeSlot.day, TimeSlot.time, doctorId, TimeSlot.is_regular);
    await get().fetchConsultations();
    get().openPopup();
  },
}));
