import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";

import {
  getAvailableSections,
  getPatientQuestionnaire,
  getRequiredQuestions,
  getSectionAvailableQuestions,
  sendAnswer,
} from "../../API/QuestionnairesService";
import { Answer, DetailQuestionnaire, Question, Section } from "../../lib/models/questionnaire";

export const useFetchAvailableSections = (args?: { options?: UseQueryOptions<Section[], Error> }) => {
  const { ...rest } = useQuery<Section[], Error>(["questionnaire", "sections"], getAvailableSections, {
    ...args?.options,
  });
  return { ...rest, data: rest.data || [] };
};

export const useFetchSectionAvailableQuestions = (
  sectionId: number,
  args?: { options?: UseQueryOptions<Question[], Error> }
) => {
  const { ...rest } = useQuery<Question[], Error>(
    ["questionnaire", "sections", sectionId],
    async () => await getSectionAvailableQuestions(sectionId),
    {
      ...args?.options,
    }
  );
  return { ...rest, data: rest.data || [] };
};

export const useSendAnswers = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ questionId, answer }: { questionId: string; answer: Answer }) => sendAnswer(questionId, answer),
    {
      onSettled: () => queryClient.invalidateQueries(["questionnaire", "sections"]),
    }
  );
};

export const useFetchRequiredQuestions = (args?: { options?: UseQueryOptions<Question[], Error> }) => {
  const { ...rest } = useQuery<Question[], Error>(
    ["questionnaire", "sections", "required"],
    async () => await getRequiredQuestions(),
    {
      ...args?.options,
    }
  );
  return { ...rest, data: rest.data || [] };
};
export const useFetchPatientQuestionnaire = (
  patientId: number,
  args?: { options?: UseQueryOptions<DetailQuestionnaire[], Error> }
) => {
  const { ...rest } = useQuery<DetailQuestionnaire[], Error>(
    ["questionnaire", "patient", patientId],
    async () => await getPatientQuestionnaire(patientId),
    {
      ...args?.options,
    }
  );

  return { ...rest, data: rest.data || [] };
};
