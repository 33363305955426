import React, { FC } from "react";

type Props = {
  isActive?: boolean;
};

export const FilledCart: FC<Props> = ({ isActive }) => {
  return (
    <>
      {isActive ? (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="white" />
          <mask
            id="mask0_14_92"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="24"
            height="24"
          >
            <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_14_92)">
            <path
              d="M23 26H9C8.45 26 7.97917 25.8042 7.5875 25.4125C7.19583 25.0208 7 24.55 7 24V12C7 11.45 7.19583 10.9792 7.5875 10.5875C7.97917 10.1958 8.45 10 9 10H11C11 8.61667 11.4875 7.4375 12.4625 6.4625C13.4375 5.4875 14.6167 5 16 5C17.3833 5 18.5625 5.4875 19.5375 6.4625C20.5125 7.4375 21 8.61667 21 10H23C23.55 10 24.0208 10.1958 24.4125 10.5875C24.8042 10.9792 25 11.45 25 12V24C25 24.55 24.8042 25.0208 24.4125 25.4125C24.0208 25.8042 23.55 26 23 26ZM13 10H19C19 9.16667 18.7083 8.45833 18.125 7.875C17.5417 7.29167 16.8333 7 16 7C15.1667 7 14.4583 7.29167 13.875 7.875C13.2917 8.45833 13 9.16667 13 10ZM16 18C17.2333 18 18.35 17.5917 19.35 16.775C20.35 15.9583 20.8417 15.0417 20.825 14.025C20.825 13.7417 20.7333 13.5 20.55 13.3C20.3667 13.1 20.1333 13 19.85 13C19.6167 13 19.4083 13.075 19.225 13.225C19.0417 13.375 18.9083 13.6 18.825 13.9C18.6417 14.5333 18.2833 15.0417 17.75 15.425C17.2167 15.8083 16.6333 16 16 16C15.3667 16 14.7792 15.8083 14.2375 15.425C13.6958 15.0417 13.3417 14.5333 13.175 13.9C13.0917 13.5833 12.9667 13.3542 12.8 13.2125C12.6333 13.0708 12.4333 13 12.2 13C11.9167 13 11.6792 13.1 11.4875 13.3C11.2958 13.5 11.2 13.7417 11.2 14.025C11.2 15.0417 11.6917 15.9583 12.675 16.775C13.6583 17.5917 14.7667 18 16 18Z"
              fill="#6100FF"
            />
          </g>
        </svg>
      ) : (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask
            id="mask0_14_101"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="24"
            height="24"
          >
            <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_14_101)">
            <path
              d="M23 26H9C8.45 26 7.97917 25.8042 7.5875 25.4125C7.19583 25.0208 7 24.55 7 24V12C7 11.45 7.19583 10.9792 7.5875 10.5875C7.97917 10.1958 8.45 10 9 10H11C11 8.61667 11.4875 7.4375 12.4625 6.4625C13.4375 5.4875 14.6167 5 16 5C17.3833 5 18.5625 5.4875 19.5375 6.4625C20.5125 7.4375 21 8.61667 21 10H23C23.55 10 24.0208 10.1958 24.4125 10.5875C24.8042 10.9792 25 11.45 25 12V24C25 24.55 24.8042 25.0208 24.4125 25.4125C24.0208 25.8042 23.55 26 23 26ZM13 10H19C19 9.16667 18.7083 8.45833 18.125 7.875C17.5417 7.29167 16.8333 7 16 7C15.1667 7 14.4583 7.29167 13.875 7.875C13.2917 8.45833 13 9.16667 13 10ZM23 24V12H9V24H23ZM16 18C17.2333 18 18.35 17.5917 19.35 16.775C20.35 15.9583 20.8417 15.0417 20.825 14.025C20.825 13.7417 20.7333 13.5 20.55 13.3C20.3667 13.1 20.1333 13 19.85 13C19.6167 13 19.4083 13.075 19.225 13.225C19.0417 13.375 18.9083 13.6 18.825 13.9C18.6417 14.5333 18.2833 15.0417 17.75 15.425C17.2167 15.8083 16.6333 16 16 16C15.3667 16 14.7792 15.8083 14.2375 15.425C13.6958 15.0417 13.3417 14.5333 13.175 13.9C13.0917 13.5833 12.9667 13.3542 12.8 13.2125C12.6333 13.0708 12.4333 13 12.2 13C11.9167 13 11.6792 13.1 11.4875 13.3C11.2958 13.5 11.2 13.7417 11.2 14.025C11.2 15.0417 11.6917 15.9583 12.675 16.775C13.6583 17.5917 14.7667 18 16 18Z"
              fill="white"
            />
          </g>
          <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="white" strokeOpacity="0.16" />
        </svg>
      )}
    </>
  );
};
