import classNames from "classnames";
import { motion } from "framer-motion";
import React, { forwardRef, useLayoutEffect, useRef } from "react";

import ImageWithOverlapColor from "../../../components/ImageWithOverlapColor/index.";
import { ReactComponent as PenIcon } from "../../../images/edit.svg";
import { PrimaryColors } from "../../../lib/consts/colors";
import styles from "./index.module.css";

type Props = {
  answer: string;
  onChange: (answer: string) => void;
  selected?: boolean;
  icon?: string;
  backgroundImage?: string;
  otherValue?: string;
};

const QuestionnaireSingleInputFormItem = forwardRef<HTMLButtonElement, Props>(
  ({ onChange, answer, selected, icon, backgroundImage, otherValue }, ref) => {
    const iconRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
      if (iconRef.current) {
        iconRef.current.style.setProperty("-webkit-mask", `url("${icon}") no-repeat left`);
        iconRef.current.style.setProperty("mask", `url("${icon}") no-repeat left`);
      }
    }, [icon]);

    const otherOnClick = () => {
      console.log("other", otherValue);
      if (otherValue?.length) {
        onChange(otherValue);
      } else {
        onChange("Другое");
      }
      textareaRef.current && textareaRef.current.focus();
    };

    if (answer === "Другое") {
      return (
        <motion.button
          ref={ref}
          style={backgroundImage ? { height: "17.5em" } : { height: "14.5em" }}
          className={classNames(styles.container, selected && styles.selected)}
          onClick={otherOnClick}
        >
          <div className={styles.caption}>
            <span>
              <PenIcon fill={selected ? "#FFFFFF" : PrimaryColors._900} />
              <p className={styles.answer}>{answer}</p>
            </span>
            <textarea
              onChange={(e) => onChange(e.target.value)}
              ref={textareaRef}
              value={otherValue}
              placeholder={"Нажмите на карточку, чтобы ввести свой вариант"}
            />
          </div>
          <div className={styles.cover}>
            <div className={styles.circle}>
              <div className={styles.innerCircle} />
            </div>
          </div>
        </motion.button>
      );
    }

    return (
      <motion.button
        ref={ref}
        style={backgroundImage ? { height: "17.5em" } : { height: "14.5em" }}
        className={classNames(styles.container, selected && styles.selected)}
        onClick={() => onChange(answer)}
      >
        <div className={styles.caption}>
          {icon ? <div ref={iconRef} className={classNames(styles.icon, selected && styles.selectedIcon)} /> : null}
          <p className={styles.answer}>{answer}</p>
        </div>
        <div className={styles.cover}>
          {backgroundImage ? (
            <ImageWithOverlapColor
              style={selected ? {} : { opacity: 0.2 }}
              className={styles.backgroundImage}
              src={backgroundImage}
            />
          ) : null}
          <div className={styles.circle}>
            <div className={styles.innerCircle} />
          </div>
        </div>
      </motion.button>
    );
  }
);

QuestionnaireSingleInputFormItem.displayName = "QuestionnaireSingleInputFormItem";
export default motion(QuestionnaireSingleInputFormItem);
