import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import arrowRight from "../../images/arrow.svg";
import avatar from "../../images/default_avatar.png";
import { ReviewPatient } from "../../lib/models/ReviewPatient";
import { DoctorUrls } from "../../lib/routes";
import { useDoctorStore } from "../../store/useDoctorStore";
import styles from "./index.module.css";

type Props = {
  reviewPatient: ReviewPatient;
};
export const PatientAsideCard: FC<Props> = ({ reviewPatient }) => {
  const [status, setStatus] = useState(false);
  const { setReviewPatient } = useDoctorStore((state) => state);

  const navigate = useNavigate();
  useEffect(() => {
    if (reviewPatient.test.status === "review") {
      setStatus(true);
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.patient}>
          <img src={reviewPatient.patient.profile_image ? reviewPatient.patient.profile_image : avatar} />
          <p className={styles.name}>
            {reviewPatient.patient.last_name ? reviewPatient.patient.last_name + " " : ""}
            {reviewPatient.patient.first_name ? reviewPatient.patient.first_name + " " : ""}
            {reviewPatient.patient.middle_name ? reviewPatient.patient.middle_name : ""}
          </p>
        </div>
        <p className={styles.test}>Тест: {reviewPatient.test.serial_number}</p>
        <div className={styles.buttonContainer}>
          <img
            src={arrowRight}
            onClick={() => {
              setReviewPatient(null);
              navigate(DoctorUrls.PATIENTS);
            }}
          />
          <button
            onClick={() => {
              navigate(DoctorUrls.DOCTOR_CONCLUSION);
            }}
          >
            {status ? "Сделать заключение" : "Просмотреть заключение"}
          </button>
        </div>
      </div>
    </>
  );
};
