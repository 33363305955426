import "./App.css";

import React, { ReactNode, Suspense, useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";

import OrgUserProvider from "./generic/providers/OrgUserProvider";
import PatientProvider from "./generic/providers/PatientProvider";
import RequiredQuestionnaireProvider from "./generic/providers/RequiredQuestionnaireProvider";
import ToastProvider from "./generic/providers/ToastProvider";
import AppLayout from "./layouts/AppLayout";
import AuthProvider from "./layouts/AuthProvider/AuthProvider";
import LayoutWithoutAside from "./layouts/LayoutWithoutAside";
import OrgUserLayout from "./layouts/OrgUserLayout";
import { UserType } from "./lib/models/User";
import PrivateRoute from "./lib/PrivateRoute";
import { GlobalUrls, OrgPatientUrls, PatientUrls } from "./lib/routes";
import { privateOrgUserRouteLoader } from "./loaders/privateOrgUserRouteLoader";
import { ConfirmDeleteEmptyPage } from "./pages/ConfirmDeleteEmptyPage";
import { ConfirmEmailEmptyPage } from "./pages/ConfirmEmailEmptyPage/ConfirmEmailEmtyPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { LoginPage } from "./pages/LoginPage";
import LoginViaTestSerialNumberPage from "./pages/LoginViaTestSerialNubmerPage";
import { NotFound } from "./pages/NotFound";
import NewQuestionnairesPage from "./pages/QuestionnairePage";
import ReportPage from "./pages/ReportPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage/ResetPasswordPage";
import SignInPage from "./pages/SignInPage";
import DoctorRoutes from "./routes/DoctorRoutes";
import OrgRoutes from "./routes/OrgRoutes";
import PatientRoutes from "./routes/PatientRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const getRouter = (role: UserType) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <AuthProvider>
            <AppLayout />
          </AuthProvider>
        }
      >
        {role && (
          <Route element={<PrivateRoute />}>
            {role === "org" && OrgRoutes()}
            {role === "patient" && PatientRoutes()}
            {role === "doctor" && DoctorRoutes()}
          </Route>
        )}
        <Route path="/" element={<Navigate to={GlobalUrls.LOGIN} />}></Route>
        <Route loader={privateOrgUserRouteLoader} element={<OrgUserProvider />}>
          <Route element={<PatientProvider />}>
            <Route element={<OrgUserLayout />}>
              <Route element={<RequiredQuestionnaireProvider />}>
                <Route path={"/iframe" + String(OrgPatientUrls.REPORT)} element={<ReportPage />} />
                <Route path={"/iframe" + String(OrgPatientUrls.QUESTIONNAIRES)} element={<NewQuestionnairesPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path={GlobalUrls.LOGIN} element={<LoginPage />}></Route>
        <Route path={GlobalUrls.FORGOT_PASSWORD} element={<ForgotPasswordPage />}></Route>
        <Route path={GlobalUrls.SIGN_IN} element={<SignInPage />}></Route>
        <Route path={GlobalUrls.RESET_PASSWORD} element={<ResetPasswordPage />}></Route>
        <Route path={GlobalUrls.CONFIRM_EMAIL} element={<ConfirmEmailEmptyPage />}></Route>
        <Route element={<ConfirmDeleteEmptyPage />} path={GlobalUrls.CONFIRM_DELETE}></Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );
};

const getQuestionnaireRouter = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <AuthProvider>
            <AppLayout />
          </AuthProvider>
        }
      >
        <Route element={<PrivateRoute />}>
          <Route element={<PatientProvider />}>
            <Route element={<LayoutWithoutAside />}>
              <Route path="/" element={<Navigate to={PatientUrls.QUESTIONNAIRES} />} />
              <Route path={PatientUrls.QUESTIONNAIRES} element={<NewQuestionnairesPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="/" element={<Navigate to={GlobalUrls.LOGIN} />}></Route>
        <Route path={GlobalUrls.LOGIN} element={<LoginViaTestSerialNumberPage />}></Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );
};

type RoleContextType = {
  role: UserType | null;
  setRole: (setRole: UserType | null) => void;
};

const RoleContext = React.createContext<RoleContextType | null>(null);

const RoleProvider = ({ children }: { children: (props: { role: UserType | null }) => ReactNode }) => {
  const [role, setRole] = React.useState<UserType | null>(localStorage.getItem("role") as UserType | null);

  const setRoleHandler = (role: UserType | null) => {
    setRole(role);
    if (role) {
      localStorage.setItem("role", role);
    } else {
      localStorage.removeItem("role");
    }
  };

  return <RoleContext.Provider value={{ role, setRole: setRoleHandler }}>{children({ role })}</RoleContext.Provider>;
};

export const useRoleContext = () => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error("useRoleContext must be used within a RoleProvider");
  }
  return context;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastProvider>
        <Suspense fallback={<div />}>
          <RoleProvider>{() => <RouterProvider router={getQuestionnaireRouter()} />}</RoleProvider>
        </Suspense>
      </ToastProvider>
    </QueryClientProvider>
  );
}

export default App;
