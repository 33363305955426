import React, { FC, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { TestPickerIcon } from "../../images/test_picker";
import { useFetchPatientTests } from "../../queries/tests";
import { usePatientStore } from "../../store/usePatientStore";
import OverlayingPopup from "../../UI/OverlayingPopup";
import flask from "./assets/flask.svg";
import plus from "./assets/plus.svg";
import radio from "./assets/radio.svg";
import radioInactive from "./assets/radioInactive.svg";
import warning from "./assets/warning.svg";
import styles from "./index.module.css";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const TestPickerPopup: FC<Props> = ({ isOpen, setIsOpen }) => {
  const { data: tests } = useFetchPatientTests();
  const { mainTest, setMainTest } = usePatientStore((state) => state);
  const ref = useRef<HTMLDivElement>(null);
  const date = tests[0]?.date && new Date(tests[0].date);
  const newDate = (date: Date, months: number) => {
    date.setMonth(date.getMonth() + months);
    return date.toISOString().split("T")[0].split("-").reverse().join(".");
  };
  const navigate = useNavigate();

  const isDateOld = (date: Date) => new Date().getTime() - new Date(date).getTime() > 7776000000;

  return (
    <OverlayingPopup isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className={styles.container}>
        <div className={styles.scrollWrapper} ref={ref}>
          <div className={styles.testPicker}>
            <div className={styles.headerContainer}>
              <img src={flask} />
              <div className={styles.header}>
                <h1>Выбор результатов теста</h1>
                <p>Просматривайте предыдущие результаты, либо актуализируйте данные заказав новый тест</p>
              </div>
            </div>
            <hr />
            <div>
              {date && isDateOld(date) && (
                <div className={styles.warning}>
                  <img src={warning} />
                  <p>Настало время повторной сдачи теста</p>
                </div>
              )}
              <a href="https://novabiom.ru/" rel="noreferrer" target="_blank">
                <div className={styles.newTest}>
                  <img src={plus} />
                  <div className={styles.newTestText}>
                    <h1>Заказ нового теста</h1>
                    {date && <p>Рекомендуемая дата сдачи {newDate(date, 3)}</p>}
                  </div>
                </div>
              </a>
              <p
                className={styles.bindTest}
                onClick={() => {
                  setMainTest(null);
                  setIsOpen(false);
                  navigate("/progress");
                }}
              >
                Тест приобретён? <span>Активировать ID теста ❯</span>
              </p>
            </div>
            <hr />
            <div className={styles.recentTestsContainer}>
              {tests.map((e, index) => (
                <div
                  className={mainTest?.id === e.id ? styles.recentTest : styles.recentTestInactive}
                  key={e.id}
                  onClick={() => {
                    setMainTest(e);
                    setIsOpen(false);
                    navigate("/");
                  }}
                  style={index === 0 ? { opacity: "1" } : {}}
                >
                  <TestPickerIcon isPurple={mainTest?.id !== e.id} />
                  <div className={mainTest?.id === e.id ? styles.recentTestText : styles.recentTestInactiveText}>
                    <h1>Тест за {e.date?.split("-").reverse().join(".")}</h1>
                    <p>{index === 0 ? "Актуальный" : "Устаревший"}</p>
                  </div>
                  <img src={mainTest?.id === e.id ? radio : radioInactive} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </OverlayingPopup>
  );
};

export default TestPickerPopup;
