import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";

import BurgerMenuIcon from "../../../images/BurgerMenuIcon";
import { ReactComponent as TipIcon } from "../../../images/TipIcon.svg";
import useWindowSize from "../../../lib/hooks/useWindowSize";
import { useSectionPassingContext } from "../QuestionnairePassing/SectionPassingProvider";
import { useQuestionnaireWidgetContext } from "../QuestionnaireWidget";
import styles from "./index.module.css";

const QuestionnaireProgressBar = () => {
  const { setMenuIsOpen } = useQuestionnaireWidgetContext();
  const { section, currentQuestion, questions } = useSectionPassingContext();
  const [showTip, setShowTip] = useState<boolean>(false);

  const currentQuestionNumber = useMemo(
    () => questions.findIndex((question) => question.question_id === currentQuestion.question_id) + 1,
    [currentQuestion, questions]
  );
  useEffect(() => {
    setShowTip(false);
  }, [currentQuestionNumber]);

  const { width } = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <motion.button
          whileHover={{ scale: 1.05 }}
          onClick={() => {
            setMenuIsOpen(true);
          }}
          whileTap={{ scale: 1, opacity: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.menuIcon}
        >
          <BurgerMenuIcon big={width > 988} />
        </motion.button>
        <div>
          <p className={styles.section}>{section.name}</p>
          <motion.h1
            key={currentQuestion.question_id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={styles.question}
          >
            {currentQuestion.markup.question}
          </motion.h1>
        </div>
      </div>
      <div className={styles.progress}>
        <motion.div
          initial={{ width: 0 }}
          animate={{
            width: `${(currentQuestionNumber / questions.length) * 100}%`,
          }}
          className={styles.line}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.tipWrapper}>
          {currentQuestion.markup.description ? (
            <>
              <motion.button
                onClick={() => {
                  setShowTip(!showTip);
                }}
                whileHover={{ scale: 1.3 }}
                whileTap={{ scale: 1.2, opacity: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={classNames(styles.menuIcon, styles.tipButton)}
              >
                <span>
                  <TipIcon className={styles.tipIcon} />
                </span>
              </motion.button>
              <AnimatePresence>
                {showTip ? (
                  <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    className={styles.tip}
                  >
                    <p>{currentQuestion.markup.description}</p>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </>
          ) : null}
        </div>
        <p>
          {currentQuestionNumber}/{questions.length}
        </p>
      </div>
    </div>
  );
};

export default QuestionnaireProgressBar;
