import { useQuery, UseQueryOptions } from "react-query";

import { getConsultations } from "../../API/ConsultationService";
import { Consultation } from "../../lib/models/Consultation";
import { useConsultationsStore } from "../../store/useConsultationsStore";

export const useFetchPatientConsultations = (args?: { options?: UseQueryOptions<Consultation[], Error> }) => {
  const { consultations, setConsultations } = useConsultationsStore((state) => state);
  const { ...rest } = useQuery<Consultation[], Error>(["patient_consultations"], () => getConsultations(), {
    onSuccess: (consultations) => setConsultations(consultations),
    ...args?.options,
  });

  return { ...rest, data: consultations };
};
