import { motion } from "framer-motion";
import React, { FC } from "react";

import { useIsTouchScreen } from "../../../../lib/hooks/useIsTouchScreen";
import { useQuestionnaireState } from "../../../../lib/hooks/useQuestionnaireState";
import { Question } from "../../../../lib/models/questionnaire";
import QuestionnaireSingleInputFormItem from "../../QuestionnaireSingleInputFormItem";
import styles from "./index.module.css";

type Props = {
  question: Question;
};

const anim = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: {
    opacity: 1,
    scale: 1,
  },
};

const buttonVariants = {
  hidden: {
    scale: 0,
  },
  show: ({ index, selected }: { index: number; selected: boolean }) => ({
    scale: 1,
    transition: { delay: index * 0.1 },
  }),
  whileHover: { y: -20, opacity: 1 },
  whileTap: { opacity: 0.5, y: -15 },
};

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: ({ index, selected }: { index: number; selected: boolean }) => ({
    opacity: selected ? 1 : 0.2,

    scale: 1,
    transition: { delay: index * 0.1 },
  }),
};

const SingleInputForm: FC<Props> = ({ question }) => {
  const {
    state: [value, setValue],
  } = useQuestionnaireState<string>({
    question,
  });

  const isTouchScreen = useIsTouchScreen();

  return (
    <motion.div
      variants={anim}
      initial="hidden"
      animate="show"
      className={question.markup.answers?.length === 4 ? styles.gridContainer : styles.flexContainer}
    >
      {question.markup.answers?.map((answer, index, array) => (
        <QuestionnaireSingleInputFormItem
          backgroundImage={question.markup.cardBackgrounds?.at(index)}
          icon={question.markup.cardIcons?.at(index)}
          variants={buttonVariants}
          initial="hidden"
          animate="show"
          custom={{ index: index, selected: value === answer }}
          whileTap={isTouchScreen ? "" : "whileTap"}
          whileHover={isTouchScreen ? "" : "whileHover"}
          answer={answer}
          key={index}
          otherValue={!array.includes(value) ? value : ""}
          selected={
            value.length
              ? answer === "Другое"
                ? value === "Другое" || !array.includes(value)
                : value === answer
              : false
          }
          onChange={setValue}
        />
      ))}
    </motion.div>
  );
};

export default SingleInputForm;
