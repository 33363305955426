import React from "react";
import { Link } from "react-router-dom";

import { Logo } from "../../../../images/logo";
import { ReactComponent as MotherAndChildLogo } from "../../../../images/motherAndChildLogo.svg";
import AsideCartButton from "../../../AsideCartButton";
import AsideConsultationsButton from "../../../AsideConsultationsButton";
import styles from "./MotherAndChildPatientAsideHeaderVariant.module.css";

const MotherAndChildPatientAsideHeaderVariant = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logos}>
        <Link to="/">
          <div className={styles.novabiomLogo}>
            <Logo white={false} />
          </div>
        </Link>
        <a rel="noreferrer" target="_blank" href="https://mamadeti.ru/">
          <MotherAndChildLogo />
        </a>
      </div>
      <div className={styles.buttons}>
        <AsideConsultationsButton />
        <AsideCartButton />
      </div>
    </div>
  );
};

export default MotherAndChildPatientAsideHeaderVariant;
