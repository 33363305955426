import React, { useState } from "react";

import { useSendAnswers } from "../../../queries/questionnaire";
import QuestionnaireFinish from "../QuestionnaireFinish";
import QuestionnaireFooterButton from "../QuestionnaireFooterButton";
import { useSectionPassingContext } from "../QuestionnairePassing/SectionPassingProvider";
import { useQuestionnaireWidgetContext } from "../QuestionnaireWidget";
import styles from "./index.module.css";

const QuestionnaireFooterButtons = () => {
  const {
    nextQuestion,
    prevQuestion,
    onFinishButton,
    goToPrevQuestion,
    isLastQuestion,
    goToNextQuestion,
    sectionReadyButtonIsActive,
    currentQuestion,
  } = useSectionPassingContext();

  const { nextSectionIsAvailable, goToNextSection, totalProgress } = useQuestionnaireWidgetContext();

  const { mutateAsync: sendAnswers } = useSendAnswers();
  const [finishPopupIsOpen, setFinishPopupIsOpen] = useState<boolean>(false);

  return (
    <div>
      <div className={styles.container}>
        {prevQuestion && <QuestionnaireFooterButton onClick={goToPrevQuestion} direction={"left"} primary={false} />}
        {!currentQuestion.markup.isRequired && (
          <QuestionnaireFooterButton
            onClick={async () => {
              await sendAnswers({
                questionId: currentQuestion.question_id,
                answer: {
                  answer: "",
                  skipped: true,
                  unformatted_answer: "",
                },
              });
              if (isLastQuestion) {
                nextSectionIsAvailable && goToNextSection();
              } else {
                nextQuestion && goToNextQuestion();
              }
              if (isLastQuestion && !nextSectionIsAvailable && totalProgress === 100) {
                setFinishPopupIsOpen(true);
              }
            }}
            skipAnswer={currentQuestion.markup.skip_button || "Пропустить"}
            primary={false}
          />
        )}
        {nextQuestion && <QuestionnaireFooterButton onClick={goToNextQuestion} direction="right" primary />}
        {isLastQuestion && nextSectionIsAvailable && (
          <QuestionnaireFooterButton
            isActive={sectionReadyButtonIsActive}
            finishButton
            onClick={() => {
              onFinishButton();
              goToNextSection();
            }}
          >
            Готово
          </QuestionnaireFooterButton>
        )}
        {!nextSectionIsAvailable && isLastQuestion && (
          <QuestionnaireFooterButton
            isActive={totalProgress === 100}
            finishButton
            onClick={() => {
              setFinishPopupIsOpen(true);
            }}
          >
            Завершить
          </QuestionnaireFooterButton>
        )}
      </div>
      <QuestionnaireFinish
        isOpen={finishPopupIsOpen}
        onClose={() => {
          setFinishPopupIsOpen(false);
        }}
        sectionFinishVariant={false}
        sectionName={"Обязательные вопросы"}
      />
    </div>
  );
};

const QuestionnaireFooterButtonsRequiredVersion = () => {
  const { nextQuestion, prevQuestion, onFinishButton, sectionReadyButtonIsActive, goToNextQuestion, goToPrevQuestion } =
    useSectionPassingContext();

  return (
    <div>
      <div className={styles.container}>
        {prevQuestion && <QuestionnaireFooterButton onClick={goToPrevQuestion} direction={"left"} primary={false} />}
        {nextQuestion ? (
          <QuestionnaireFooterButton onClick={goToNextQuestion} direction="right" primary />
        ) : (
          <QuestionnaireFooterButton isActive={sectionReadyButtonIsActive} finishButton onClick={onFinishButton}>
            Готово
          </QuestionnaireFooterButton>
        )}
      </div>
    </div>
  );
};

const QuestionnaireFooterProvider = () => {
  const { requiredVersion } = useSectionPassingContext();

  if (requiredVersion) {
    return <QuestionnaireFooterButtonsRequiredVersion />;
  }

  return <QuestionnaireFooterButtons />;
};

export default QuestionnaireFooterProvider;
