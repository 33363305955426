import classNames from "classnames";
import { motion } from "framer-motion";
import React, { FC } from "react";

import ImageWithOverlapColor from "../../../components/ImageWithOverlapColor/index.";
import { Section } from "../../../lib/models/questionnaire";
import { CircularProgressBar } from "../../../UI/Charts";
import { useQuestionnaireWidgetContext } from "../QuestionnaireWidget";
import styles from "./index.module.css";

type QuestionnairesListElementType = {
  section: Section;
  onClick?: () => void;
};

const QuestionnairesListElement: FC<QuestionnairesListElementType> = ({ section, onClick }) => {
  const { currentSection } = useQuestionnaireWidgetContext();
  return (
    <motion.button
      whileHover={{ x: 10 }}
      whileTap={{ x: 0 }}
      className={classNames(styles.container, currentSection?.name === section.name && styles.selected)}
      onClick={onClick}
    >
      <ImageWithOverlapColor className={styles.image} src={section.previewUrl} alt={section.name} />
      <div className={styles.innerContainer}>
        <div className={styles.content}>
          {section.name}
          <br />
          <span>Вопросов: {section.available_questions_count}</span>
        </div>
        <div></div>
        <div className={styles.chartWrapper}>
          <CircularProgressBar
            primaryColor="white"
            backgroundColor="rgba(255, 255, 255, 0.16)"
            percentage={Math.round(section.progress) || 0}
          />
        </div>
      </div>
    </motion.button>
  );
};

export default QuestionnairesListElement;
