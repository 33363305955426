import { HTMLMotionProps, motion } from "framer-motion";
import React, { forwardRef, ReactNode } from "react";

const variants = {
  hidden: { opacity: 0, x: 100 },
  exit: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

interface Props extends HTMLMotionProps<"div"> {
  children: ReactNode;
}
const AnimatedDivFromRightToLeft = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, ...otherProps } = props;

  return (
    <motion.div variants={variants} initial="hidden" animate="visible" exit="exit" ref={ref} {...otherProps}>
      {children}
    </motion.div>
  );
});

AnimatedDivFromRightToLeft.displayName = "AnimatedDivFromRightToLeft";
export default motion(AnimatedDivFromRightToLeft);
