import React from "react";
import { Outlet } from "react-router-dom";

import BottomMobilePopup from "../../components/BottomMobilePopup";
import { Popup } from "../../components/Popup";

const AppLayout = () => {
  return (
    <div className="App">
      <Popup />
      <BottomMobilePopup />
      <Outlet />
    </div>
  );
};

export default AppLayout;
