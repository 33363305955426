import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { useSendAnswers } from "../../queries/questionnaire";
import { AnswerType, Question } from "../models/questionnaire";

type useQuestionnaireStateParams<T> = {
  question: Question;
  ms?: number;
  sendFormat?: (value: T) => AnswerType;
  initialValue?: T;
};

const getDefaultValue = <T>(question: Question, initialValue?: T): T => {
  if (question.answer?.unformatted_answer?.length) {
    if (initialValue) {
      return initialValue;
    }
    return question.answer.unformatted_answer as T;
  } else {
    if (initialValue) {
      return initialValue;
    }
    return [] as T;
  }
};

const sendValue = <T>(value: T, sendFormat?: (value: T) => AnswerType) => {
  if (typeof value === "string") {
    if (value.length) {
      return sendFormat ? sendFormat(value) : value;
    } else {
      return "";
    }
  } else {
    return sendFormat ? sendFormat(value) : value;
  }
};

export const useQuestionnaireState = <T>({
  question,
  ms,
  sendFormat,
  initialValue: initialValueProp,
}: useQuestionnaireStateParams<T>) => {
  if (ms === undefined) ms = 350;

  const initialValue = useRef<T>(getDefaultValue(question, initialValueProp));
  const [displayValue, setDisplayValue] = useState<T>(getDefaultValue(question, initialValueProp));
  const { mutateAsync: sendAnswers } = useSendAnswers();
  const lockUpdate = useRef<boolean>(false);

  useLayoutEffect(() => {
    if (question.answer?.skipped) {
      initialValue.current = getDefaultValue(question, initialValueProp);
      setDisplayValue(getDefaultValue(question, initialValueProp));
    }
  }, [question.answer?.skipped]);

  useEffect(() => {
    const sendCallBack = () => {
      if (!lockUpdate.current) {
        if (JSON.stringify(displayValue) !== JSON.stringify(initialValue.current)) {
          initialValue.current = displayValue;
          sendAnswers({
            questionId: question.question_id,
            answer: {
              answer: sendValue<T>(displayValue, sendFormat) as AnswerType,
              skipped: false,
              unformatted_answer: sendValue<T>(displayValue) as AnswerType,
            },
          });
        }
      }
    };

    const timeout = setTimeout(sendCallBack, ms);
    return () => clearTimeout(timeout);
  }, [displayValue]);

  return { state: [displayValue, setDisplayValue] as const };
};
