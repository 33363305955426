import React, { FC } from "react";

import OverlayingPopup from "../../UI/OverlayingPopup";
import Portal from "../../UI/Portal";
import { Aside } from "../Aside";
import styles from "./index.module.css";

type AsidePortalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AsideAsPopup: FC<AsidePortalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <OverlayingPopup disablePageScroll={true} overlayIsVisible={false} isOpen={isOpen}>
        <div className={styles.container}>
          <Aside onClose={onClose} isPopup />
        </div>
      </OverlayingPopup>
    </Portal>
  );
};

export default AsideAsPopup;
