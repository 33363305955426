import React, { FC, ReactElement, ReactNode, useEffect } from "react";

import Portal from "../Portal";
import styles from "./index.module.css";

type Props = {
  children: ReactNode;
  onClose?: () => void;
  isOpen: boolean;
  overlayIsVisible?: boolean;
  disablePageScroll?: boolean;
  overlayPointerEvents?: "auto" | "none";
};

const OverlayingPopup: FC<Props> = ({
  children,
  onClose,
  isOpen,
  overlayIsVisible = true,
  disablePageScroll = true,
  overlayPointerEvents = "auto",
}) => {
  useEffect(() => {
    if (disablePageScroll && isOpen) {
      document.body.className += " overflowhidecs";
    }
  }, [disablePageScroll, isOpen]);

  return (
    <>
      {isOpen && (
        <Portal
          onUnmount={() => {
            onClose && onClose();
            document.body.className = document.body.className.replace("overflowhidecs", "");
            if (document.body.classList.length === 0) {
              document.body.removeAttribute("class");
            }
          }}
        >
          <>
            <div
              onClick={onClose}
              style={{ opacity: overlayIsVisible ? 1 : 0, pointerEvents: overlayPointerEvents }}
              role="button"
              tabIndex={0}
              className={styles.overlay}
            />
            <div
              className={styles.container}
              style={{ pointerEvents: overlayPointerEvents }}
              onClick={onClose}
              role="dialog"
            >
              {React.isValidElement(children) &&
                React.cloneElement(children as ReactElement<any>, {
                  ...children.props,
                  style: { ...children.props.style, pointerEvents: "auto" },
                  onClick: (e: any) => {
                    e.stopPropagation();
                    children.props.onClick && children.props.onClick(e);
                  },
                })}
            </div>
          </>
        </Portal>
      )}
    </>
  );
};

export default OverlayingPopup;
