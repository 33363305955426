import React, { FC, useMemo } from "react";

import WarningIcon from "../../images/WarningIcon";
import { useFetchAvailableSections } from "../../queries/questionnaire";
import styles from "./index.module.css";

type Props = {
  isActive?: boolean;
};

const QuestionnaireWarning: FC<Props> = ({ isActive = false }) => {
  const { data } = useFetchAvailableSections();
  const totalProgress = useMemo(() => {
    return data.reduce((total, section) => total + Math.round(section.progress), 0) / (data.length * 100);
  }, [data]);

  return (
    <>
      {totalProgress < 1 ? (
        <>
          <div className={isActive ? styles.iconActive : styles.icon}>
            <WarningIcon isActive={isActive} className={styles.svgIcon} />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default QuestionnaireWarning;
