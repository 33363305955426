import React, { FC } from "react";

type Props = {
  isActive?: boolean;
  white?: boolean;
  cart?: boolean;
};

export const Cart: FC<Props> = ({ isActive, white, cart }) => {
  return (
    <>
      {cart ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.5 21.4999V6.49995H7.5C7.5 5.25125 7.93782 4.18908 8.81345 3.31345C9.6891 2.43782 10.7513 2 12 2C13.2487 2 14.3109 2.43782 15.1865 3.31345C16.0621 4.18908 16.5 5.25125 16.5 6.49995H20.5V21.4999H3.5ZM8.99998 6.49995H15C15 5.66662 14.7083 4.95828 14.125 4.37495C13.5416 3.79162 12.8333 3.49995 12 3.49995C11.1666 3.49995 10.4583 3.79162 9.87498 4.37495C9.29164 4.95828 8.99998 5.66662 8.99998 6.49995ZM4.99997 19.9999H19V7.99993H4.99997V19.9999ZM12 13.4999C13.2487 13.4999 14.3109 13.0621 15.1865 12.1865C16.0621 11.3108 16.5 10.2486 16.5 8.99993H15C15 9.83326 14.7083 10.5416 14.125 11.1249C13.5416 11.7083 12.8333 11.9999 12 11.9999C11.1666 11.9999 10.4583 11.7083 9.87498 11.1249C9.29164 10.5416 8.99998 9.83326 8.99998 8.99993H7.5C7.5 10.2486 7.93782 11.3108 8.81345 12.1865C9.6891 13.0621 10.7513 13.4999 12 13.4999Z"
            fill={white ? "white" : isActive ? "#6100FF" : "#151515"}
          />
        </svg>
      ) : (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.705078" width="32" height="32" rx="16" fill="white" />
          <path
            d="M8.20508 25.4999V10.4999H12.2051C12.2051 9.25125 12.6429 8.18908 13.5185 7.31345C14.3942 6.43782 15.4564 6 16.7051 6C17.9538 6 19.0159 6.43782 19.8916 7.31345C20.7672 8.18908 21.205 9.25125 21.205 10.4999H25.205V25.4999H8.20508ZM13.7051 10.4999H19.7051C19.7051 9.66662 19.4134 8.95828 18.8301 8.37495C18.2467 7.79162 17.5384 7.49995 16.7051 7.49995C15.8717 7.49995 15.1634 7.79162 14.5801 8.37495C13.9967 8.95828 13.7051 9.66662 13.7051 10.4999ZM9.70505 23.9999H23.7051V11.9999H9.70505V23.9999ZM16.7051 17.4999C17.9538 17.4999 19.0159 17.0621 19.8916 16.1865C20.7672 15.3108 21.205 14.2486 21.205 12.9999H19.7051C19.7051 13.8333 19.4134 14.5416 18.8301 15.1249C18.2467 15.7083 17.5384 15.9999 16.7051 15.9999C15.8717 15.9999 15.1634 15.7083 14.5801 15.1249C13.9967 14.5416 13.7051 13.8333 13.7051 12.9999H12.2051C12.2051 14.2486 12.6429 15.3108 13.5185 16.1865C14.3942 17.0621 15.4564 17.4999 16.7051 17.4999Z"
            fill={isActive ? "#6100FF" : "#1C1B1F"}
          />
          <rect x="1.20508" y="0.5" width="31" height="31" rx="15.5" stroke="#6100FF" strokeOpacity="0.08" />
        </svg>
      )}
    </>
  );
};
