import React, { FC } from "react";

type Props = {
  isActive?: boolean;
};

export const MenuIcon: FC<Props> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_9645_4791"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9645_4791)">
        <path
          d="M4.37656 21L2.97656 19.6L13.2266 9.35C12.9266 8.65 12.8849 7.85833 13.1016 6.975C13.3182 6.09167 13.7932 5.3 14.5266 4.6C15.4099 3.71667 16.3932 3.2 17.4766 3.05C18.5599 2.9 19.4432 3.16667 20.1266 3.85C20.8099 4.53333 21.0766 5.41667 20.9266 6.5C20.7766 7.58333 20.2599 8.56667 19.3766 9.45C18.6766 10.1833 17.8849 10.6583 17.0016 10.875C16.1182 11.0917 15.3266 11.05 14.6266 10.75L13.3766 12L20.9766 19.6L19.5766 21L11.9766 13.45L4.37656 21ZM7.32656 12.45L4.32656 9.45C3.42656 8.55 2.97656 7.475 2.97656 6.225C2.97656 4.975 3.42656 3.9 4.32656 3L10.5266 9.25L7.32656 12.45Z"
          fill={isActive ? "#6100FF" : "white"}
        />
      </g>
    </svg>
  );
};
