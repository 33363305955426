// export enum ClientUrls {
//   PROFILE = "/profile",
//   KNOWLEDGE = "/knowledge",
//   KNOWLEDGE_ARTICLE = "/knowledge/:link",
//   SUPPORT = "/support",
//   RECOMMENDATIONS = "/recommendations",
//   LIFESTYLE = "/recommendations/lifestyle",
//   SUPPLEMENTS = "/recommendations/supplements",
//   SPECIALISTS = "/recommendations/specialists",
//   HEALTH = "/health",
//   RISKS = "/health/risks",
//   MICROBIOTEAGE = "/health/microbioteage",
//   BACTERIES = "/health/bacteries",
//   VITAMINES = "/health/vitamines",
//   TAXONES = "/health/taxones",
//   MEDCARD = "/card",
//   RESULTS = "/card/results",
//   CONCLUSIONS = "/card/conclusions",
//   QUESTIONNAIRES = "/card/questionnaires",
//   UNAVAILABLE = "/unavailable",
//   CART = "/cart",
//   REPORT = "/report",
//   PROGRESS = "/progress",
// }

export enum DoctorUrls {
  CONSULTATIONS = "/consultations",
  CONSULTATIONS_CONCLUSION = "/consultations/:id/conclusion",
  PATIENTS = "/patients",
  PROFILE = "/profile",
  DOCTOR_CONCLUSION = "/patients/consultations",
  INTERPRETATION = "/interpretation",
}

export enum OrgUrls {
  ORG_PATIENTS = "/org_patients",
  PROFILE = "/profile",
}

export enum ManagerUrls {
  HOME = "",
}

export enum PatientUrls {
  CART = "/cart",
  REPORT = "/report",
  PROGRESS = "/progress",
  SPECIALISTS = "/specialists",
  RESULTS_CONCLUSIONS = "/conclusions/results",
  KNOWLEDGE = "/knowledge",
  KNOWLEDGE_ARTICLE = "/knowledge/:link",
  QUESTIONNAIRES = "/questionnaires",
  CONCLUSIONS = "/conclusions",
  CONSULTATIONS_CONCLUSIONS = "/conclusions/consultations",
  PROFILE = "/profile",
  SUPPORT = "/support",
  MENU_GENERATOR = "/menu",
}

export enum OrgPatientUrls {
  REPORT = "/org_user" + PatientUrls.REPORT,
  QUESTIONNAIRES = "/org_user" + PatientUrls.QUESTIONNAIRES,
}

export enum GlobalUrls {
  NOT_FOUND = "*",
  LOGIN = "/login",
  SIGN_IN = "/sign",
  FORGOT_PASSWORD = "/forgot_password",
  RESET_PASSWORD = "/reset_password/:UID/:token",
  CONFIRM_EMAIL = "/activate/:UID/:token",
  CONFIRM_DELETE = "/confirm_deletion/:UID/:token",
}
