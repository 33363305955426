import { AxiosError, isAxiosError } from "axios";

import { Conclusion } from "../../lib/models/Conclusion";
import { Interpretation } from "../../lib/models/test";
import { http } from "../index";

export const getDoctors = async () => {
  return await http.get("/api/doctors/");
};

export const getTestConclusion = async (testId: number) => {
  const response = await http.get<Conclusion>(`/api/tests/${testId}/conclusion`);
  return response.data;
};

export const createTestConclusion = async (conclusion: Pick<Conclusion, "test" | "text" | "approved">) => {
  const response = await http.post<Conclusion>("/api/tests/conclusion", conclusion);
  return response.data;
};

export const getInterpretation = async (testId: number) => {
  const response = await http.get<Interpretation>(`/api/tests/${testId}/interpretation`);
  return response.data;
};

export const patchInterpretation = async (
  testId: number,
  interpretation: Omit<Interpretation, "consultant" | "consultation_date">
) => {
  const response = await http.patch<Interpretation>(`/api/tests/${testId}/interpretation`, interpretation);
  return response.data;
};

export const patchTestConclusion = async (conclusion: Pick<Conclusion, "test" | "text" | "approved">) => {
  const response = await http.patch<Conclusion>(`/api/tests/${conclusion.test}/conclusion`, conclusion);
  return response.data;
};

export const sendTestConclusion = async (conclusion: Pick<Conclusion, "test" | "text" | "approved">) => {
  try {
    return await patchTestConclusion(conclusion);
  } catch (e) {
    const error = e as Error | AxiosError;
    if (!isAxiosError(error)) {
      throw error;
    }
    if (error.response?.status === 404) {
      return createTestConclusion(conclusion);
    } else {
      throw error;
    }
  }
};
