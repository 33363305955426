import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DeleteProfileForm } from "../../components/DeleteProfileForm";
import { OrgProfileForm } from "../../components/OrgProfileForm";
import { ProfileForm } from "../../components/ProfileForm";
import { useAuth } from "../../layouts/AuthProvider/AuthProvider";
import { hideChat } from "../../lib/utils/hideChat";
import { usePatientStore } from "../../store/usePatientStore";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";

type Props = {
  profileType?: "client" | "organization";
};

const ProfilePage: FC<Props> = ({ profileType = "client" }) => {
  const { setMainTest } = usePatientStore((state) => state);
  const [toggle, setToggle] = useState(1);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const onLogout = () => {
    setMainTest(null);
    logout();
    navigate("/");
  };

  useEffect(() => {
    hideChat();
  }, []);

  return (
    <>
      {toggle === 1 && (
        <div className={styles.page}>
          <div className={styles.buttonWrapper}>
            <Button className={styles.leaveButton} onClick={onLogout} variant="outlined" size="large" type="button">
              Выйти из аккаунта
            </Button>
          </div>
          {profileType === "client" && <ProfileForm setToggle={setToggle} />}
          {profileType === "organization" && <OrgProfileForm setToggle={setToggle} />}
        </div>
      )}
      {toggle === 2 && (
        <div className={styles.delete}>
          <DeleteProfileForm setToggle={setToggle} />
        </div>
      )}
    </>
  );
};

export default ProfilePage;
