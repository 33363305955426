import React from "react";

import { ReactComponent as Checklist } from "../../../images/checklist.svg";
import styles from "./index.module.css";

const QuestionnaireWelcomeHeader = () => {
  return (
    <div className={styles.container}>
      <span>
        <Checklist className={styles.checklist} />
      </span>
      <div>
        <h1>Давайте познакомимся поближе? </h1>
        <p>
          Нам важно знать несколько обязательных параметров, чтобы в ходе исследования предоставить вам максимально
          корректные результаты
        </p>
      </div>
    </div>
  );
};

export default QuestionnaireWelcomeHeader;
