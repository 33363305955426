import React from "react";
import { create } from "zustand";

interface MobilePopupStoreState {
  popupIsOpen: boolean;
  switchPopup: () => void;

  children: React.ReactNode;
  setChildren: (children: React.ReactNode) => void;
  closePopup: () => void;
  openPopup: () => void;
}

export const useMobilePopup = create<MobilePopupStoreState>((set, get) => ({
  children: <></>,
  setChildren: (children: React.ReactNode) => {
    set({ children: children, popupIsOpen: true });
  },
  popupIsOpen: false,
  switchPopup: () => {
    set({ popupIsOpen: !get().popupIsOpen });
  },
  closePopup: () => {
    set({ popupIsOpen: false });
  },
  openPopup: () => {
    set({ popupIsOpen: true });
  },
}));
