import React, { FC } from "react";

import AnimatedDivFromRightToLeft from "../../../components/AnimatedDivFromLeftToRight";
import { Section } from "../../../lib/models/questionnaire";
import { Button } from "../../../UI/Button";
import styles from "./index.module.css";

type Props = {
  onClick: () => void;
  section: Section;
};

const QuestionnaireStartScreen: FC<Props> = ({ onClick, section }) => {
  return (
    <AnimatedDivFromRightToLeft className={styles.container}>
      <div className={styles.imageWrapper}>
        {section.previewUrl ? (
          <>
            <img className={styles.image} src={section.previewUrl} alt={""} />
            <div className={styles.gradient} />
          </>
        ) : null}
      </div>
      <div className={styles.questionnaireInfo}>
        <p>Раздел: {section.name}</p>
        <div>
          <h1>{section.name}</h1>
          <p>
            Пожалуйста, заполните анкету.
            <br />
            Это поможет нам подстроить рекомендации персонально под вас и адаптировать их под привычный вам образ жизни.
          </p>
          <Button variant={"primary"} onClick={onClick} className={styles.nextButton} size={"large"} type={"button"}>
            Далее
          </Button>
        </div>
      </div>
    </AnimatedDivFromRightToLeft>
  );
};

export default QuestionnaireStartScreen;
