import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { changeEmail, changePassword, deletePatient } from "../../API/ProfileService";
import { useAuth } from "../../layouts/AuthProvider/AuthProvider";
import { useUser } from "../../lib/hooks/useUser";
import { useRightBottomPopup } from "../../store/useRightBottomPopup";
import { Button } from "../../UI/Button";
import { Input } from "../../UI/Input";
import styles from "./index.module.css";

type Props = {
  setToggle: React.Dispatch<React.SetStateAction<number>>;
};

export const ChangePasswordForm: FC<Props> = ({ setToggle }) => {
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors },
    reset,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm();

  const { fetchUser, logout } = useAuth();
  const { user } = useUser();
  const { openPopup } = useRightBottomPopup((state) => state);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    setValue("new_email", user.email);
  }, [user]);

  const onChangePassword = async (data: any) => {
    try {
      await changePassword({
        current_password: data.current_password,
        new_password: data.new_password,
        re_new_password: data.re_new_password,
      });
      openPopup("Изменения успешно сохранены");
      setPasswordError("");
      reset();
    } catch (e: any) {
      const obj = e.response.data;
      setPasswordError(obj[Object.keys(obj)[0]][0]);
    }
  };

  const onChangeEmail = async (data: any) => {
    try {
      await changeEmail({
        current_password: data.current_password,
        new_email: data.new_email,
        re_new_email: data.new_email,
      });
      openPopup("Изменения успешно сохранены");
      fetchUser();
      setEmailError("");
      reset2();
    } catch (e: any) {
      const obj = e.response.data;
      setEmailError(obj[Object.keys(obj)[0]][0]);
    }
  };

  const deleteProfile = async () => {
    try {
      await deletePatient();
      setToggle(2);
      logout();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask
            id="mask0_7908_22773"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_7908_22773)">
            <path
              d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V10C4 9.45 4.19583 8.97917 4.5875 8.5875C4.97917 8.19583 5.45 8 6 8H7V6C7 4.61667 7.4875 3.4375 8.4625 2.4625C9.4375 1.4875 10.6167 1 12 1C13.3833 1 14.5625 1.4875 15.5375 2.4625C16.5125 3.4375 17 4.61667 17 6V8H18C18.55 8 19.0208 8.19583 19.4125 8.5875C19.8042 8.97917 20 9.45 20 10V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM12 17C12.55 17 13.0208 16.8042 13.4125 16.4125C13.8042 16.0208 14 15.55 14 15C14 14.45 13.8042 13.9792 13.4125 13.5875C13.0208 13.1958 12.55 13 12 13C11.45 13 10.9792 13.1958 10.5875 13.5875C10.1958 13.9792 10 14.45 10 15C10 15.55 10.1958 16.0208 10.5875 16.4125C10.9792 16.8042 11.45 17 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
              fill="#1C1B1F"
            />
          </g>
        </svg>
        <h3>Безопасность</h3>
      </div>
      <form className={styles.form} onSubmit={handleSubmit2(onChangeEmail)} key={3} style={{ marginBottom: "24px" }}>
        <label className={styles.labelInput}>
          <p>Изменить email</p>
          <Input register={register2("new_email", {})} />
        </label>
        <label className={styles.labelInput}>
          <p></p>
          <Input type="password" placeholder={"Введите пароль"} register={register2("current_password", {})} />
        </label>
        {emailError !== "" && (
          <label className={styles.labelInput}>
            <p></p>
            <p style={{ color: "red" }}>{emailError}</p>
          </label>
        )}
        <div className={styles.buttons}>
          <div></div>
          <Button className={styles.saveButton} type="submit" variant="primary" size="large">
            Сохранить
          </Button>
        </div>
      </form>
      <hr />
      <form className={styles.form} onSubmit={handleSubmit1(onChangePassword)} key={2}>
        <label className={styles.labelInput}>
          <p>Изменить пароль</p>
          <Input type="password" placeholder={"Введите старый пароль"} register={register1("current_password", {})} />
        </label>
        <label className={styles.labelInput}>
          <p></p>
          <Input type="password" placeholder={"Введите новый пароль"} register={register1("new_password", {})} />
        </label>
        <label className={styles.labelInput}>
          <p></p>
          <Input type="password" placeholder={"Повторите новый пароль"} register={register1("re_new_password", {})} />
        </label>
        {passwordError !== "" && (
          <label className={styles.labelInput}>
            <p></p>
            <p style={{ color: "red" }}>{passwordError}</p>
          </label>
        )}
        <div className={styles.buttons}>
          <div></div>
          <Button className={styles.saveButton} type="submit" variant="primary" size="large">
            Сохранить
          </Button>
        </div>
      </form>
    </>
  );
};
