import { AnimatePresence } from "framer-motion";
import React, { FC, useState } from "react";

import AnimatedDivFromRightToLeft from "../../../components/AnimatedDivFromLeftToRight";
import QuestionnaireFooterButtons from "../QuestionnaireFooterButtons";
import QuestionnaireInputs from "../QuestionnaireInputs";
import QuestionnaireMultiInput from "../QuestionnaireMultiInput";
import QuestionnaireProgressBar from "../QuestionnaireProgressBar";
import QuestionnaireStartScreen from "../QuestionnaireStartScreen";
import QuestionnaireSteps from "../QuestionnaireSteps";
import QuestionnaireWelcomeProgressBar from "../QuestionnaireWelcomeProgressBar";
import styles from "./index.module.css";
import { useSectionPassingContext } from "./SectionPassingProvider";

type Props = {
  requiredVersion?: boolean;
};
const QuestionnairePassing: FC<Props> = ({ requiredVersion = false }) => {
  const { section, currentQuestion } = useSectionPassingContext();
  const [ready, setReady] = useState<boolean>(requiredVersion ? true : section.progress !== 0);

  if (!ready) {
    return <QuestionnaireStartScreen section={section} onClick={() => setReady(true)} />;
  }

  return (
    <div className={styles.containerWrapper}>
      <AnimatedDivFromRightToLeft key="QuestionnairePassingAnimated2" className={styles.container}>
        <div className={styles.header}>
          {requiredVersion ? <QuestionnaireWelcomeProgressBar /> : <QuestionnaireProgressBar />}
        </div>
        <div className={styles.bodyWrapper}>
          <div className={styles.body}>
            <div className={styles.inputWrapper}>
              {currentQuestion.child_questions?.length ? (
                <QuestionnaireMultiInput key={currentQuestion.question_id} question={currentQuestion} />
              ) : (
                <QuestionnaireInputs key={currentQuestion.question_id} question={currentQuestion} />
              )}
            </div>
          </div>
        </div>
        <AnimatePresence mode="popLayout">
          <div style={requiredVersion ? { justifyContent: "center" } : {}} className={styles.footer}>
            {!requiredVersion && <QuestionnaireSteps />}
            <QuestionnaireFooterButtons />
          </div>
        </AnimatePresence>
      </AnimatedDivFromRightToLeft>
    </div>
  );
};

export default QuestionnairePassing;
