import { motion } from "framer-motion";
import React, { FC } from "react";

import styles from "./index.module.css";

type Props = {
  completed: boolean;
  selected: boolean;
  number: string;
  onClick: () => void;
};

const QuestionnaireStep: FC<Props> = ({ completed, selected, number, onClick }) => {
  return (
    <motion.button
      onClick={onClick}
      className={`${styles.container} ${selected ? styles.selected : completed ? styles.completed : styles.initial}`}
    >
      {number}
    </motion.button>
  );
};

export default QuestionnaireStep;
