import { motion } from "framer-motion";
import React from "react";

const variants = {
  hidden: {
    opacity: 0,
    scale: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
    },
  },
};

const Salute = ({ className }: { className?: string }) => {
  return (
    <motion.svg
      className={className}
      variants={variants}
      initial="hidden"
      animate="visible"
      fill="none"
      height="250"
      viewBox="0 0 453 250"
      width="453"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m107.507 193.832c.997-.84 2.022-1.652 3.075-2.406 1.054-.755 2.121-1.424 3.218-2.15 1.096-.726 2.221-1.338 3.36-1.95 1.139-.613 2.32-1.182 3.502-1.709-.797.769-1.552 1.552-2.278 2.364l-1.068 1.21-1.011 1.238c-1.082.627-2.135 1.296-3.16 1.994-1.025.697-2.036 1.423-3.004 2.206s-1.908 1.581-2.848 2.421c-.939.84-1.737 1.736-2.52 2.662.342-.982.727-1.979 1.182-2.961l.342-.741.384-.726c.285-.498.555-.968.826-1.452z"
        fill="#6100ff"
      />
      <path
        d="m107.507 193.832c.997-.84 2.022-1.652 3.075-2.406 1.054-.755 2.121-1.424 3.218-2.15 1.096-.726 2.221-1.338 3.36-1.95 1.139-.613 2.32-1.182 3.502-1.709-.797.769-1.552 1.552-2.278 2.364l-1.068 1.21-1.011 1.238c-1.082.627-2.135 1.296-3.16 1.994-1.025.697-2.036 1.423-3.004 2.206s-1.908 1.581-2.848 2.421c-.939.84-1.737 1.736-2.52 2.662.342-.982.727-1.979 1.182-2.961l.342-.741.384-.726c.285-.498.555-.968.826-1.452z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m295.683 241.727c-.026.852-.24 1.688-.626 2.448-.399.869-1.026 1.609-1.424 2.463.77 1.027 1.789 1.84 2.961 2.364.469-.154.887-.431 1.213-.801.325-.371.546-.822.638-1.306.239-1.057.144-2.161-.271-3.161-.128-.427-.825-.854-1.167-1.11-.461-.276-.908-.575-1.338-.897z"
        fill="#6100ff"
      />
      <path
        d="m259.761 113.664c-1.666.37-2.72 2.064-3.546 3.46-.825 1.395-1.779 3.246-1.324 4.94.157.541 3.118 3.132 3.602 2.847 1.111-.612 1.566-3.118 2.179-4.271.74-1.423 2.221-2.762 2.463-4.271.071-.484-2.848-2.847-3.374-2.676z"
        fill="#6100ff"
      />
      <path
        d="m191.951 192.637c-1.235.769-2.2 1.904-2.762 3.246-.842 1.226-1.334 2.658-1.423 4.143.845 1.091 1.938 1.964 3.189 2.548.851-1.121 1.527-2.364 2.007-3.687.873-1.155 1.573-2.431 2.079-3.787-.79-1.084-1.858-1.934-3.09-2.463z"
        fill="#6100ff"
      />
      <path
        d="m343.957 237.513c-.363.541-.543 1.185-.513 1.836-.138.698-.089 1.42.143 2.093.242.569 1.224 1.153 1.68 1.509.185.142 1.281 1.139 1.509.968.498-.341.228-1.509.199-2.078-.019-.78-.153-1.552-.399-2.293-.099-.299-2.463-2.221-2.619-2.035z"
        fill="#6100ff"
      />
      <path
        d="m343.957 237.513c-.363.541-.543 1.185-.513 1.836-.138.698-.089 1.42.143 2.093.242.569 1.224 1.153 1.68 1.509.185.142 1.281 1.139 1.509.968.498-.341.228-1.509.199-2.078-.019-.78-.153-1.552-.399-2.293-.099-.299-2.463-2.221-2.619-2.035z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m344.182 200.039c-1.321 2.253-1.922 4.856-1.723 7.46.114.612 2.947 2.207 2.848 2.321.341-1.196.242-2.534.484-3.773.264-1.276.66-2.521 1.181-3.716.2-.47-2.847-2.278-2.847-2.292z"
        fill="#6100ff"
      />
      <path d="m264.941 195.711.655.1c-.868-.328-1.965-.328-.655-.1z" fill="#6100ff" />
      <path d="m203.506 161.757c.499-.1 1.011-.199 1.51-.327-.499.099-1.011.185-1.524.256z" fill="#6100ff" />
      <path d="m205.406 168.135.513.128c-.2-.156-.413-.27-.513-.128z" fill="#6100ff" />
      <path d="m199.047 159.462v.099l1.196-.17c-.399.042-.797.057-1.196.071z" fill="#6100ff" />
      <path
        d="m203.599 158.641c-1.075.372-2.187.625-3.317.754l-1.196.171c.038.787.148 1.568.327 2.335 1.375.03 2.75-.042 4.115-.214-.054-1.016-.03-2.034.071-3.046z"
        fill="#6100ff"
      />
      <path
        d="m203.587 158.611c.286-2.538.762-5.051 1.424-7.518 1.298-5.298 3.376-10.374 6.165-15.062 2.648-4.457 6.065-9.511 10.863-11.846 1.099-.542 2.284-.894 3.502-1.039.648-.064 1.302-.064 1.95 0h.271c-.46-.576-.969-1.109-1.524-1.595-1.221-.478-2.518-.733-3.829-.754-5.98 0-10.265 4.983-13.454 9.453-3.407 4.805-6.017 10.126-7.731 15.761-.852 2.792-1.476 5.649-1.865 8.542-.25 1.601-.35 3.221-.299 4.841h1.196c1.124-.197 2.237-.458 3.331-.783z"
        fill="#6100ff"
      />
      <path
        d="m199.046 159.564c-1.417.176-2.845.233-4.271.171-1.989-.038-3.957-.408-5.823-1.097-1.047-.258-2.071-.6-3.061-1.025 3.852 2.46 8.255 3.928 12.813 4.271h.698c-.189-.761-.308-1.538-.356-2.32z"
        fill="#6100ff"
      />
      <path
        d="m199.046 159.564c-1.417.176-2.845.233-4.271.171-1.989-.038-3.957-.408-5.823-1.097-1.047-.258-2.071-.6-3.061-1.025 3.852 2.46 8.255 3.928 12.813 4.271h.698c-.189-.761-.308-1.538-.356-2.32z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m203.505 161.688c-1.364.171-2.739.243-4.114.213 0 .1 0 .185.071.285 1.361-.042 2.717-.185 4.057-.427z"
        fill="#6100ff"
      />
      <path
        d="m228.133 123.203c-.122-.096-.232-.206-.328-.328 1.424.598 1.837.783 1.054.556-.239-.066-.481-.118-.726-.157.27.248.522.514.754.797.394.546.697 1.152.897 1.794.4 1.289.511 2.65.328 3.986-.627 5.297-4.072 10.152-7.204 14.238-3.278 4.531-7.39 8.397-12.116 11.389-2.153 1.296-4.452 2.333-6.848 3.09l-.37.142c-.078 1.014-.078 2.033 0 3.047.512 0 1.025-.157 1.523-.257.499-.099 1.196-.298 1.78-.484-.584.186-1.182.342-1.78.484 9.582-1.822 17.64-8.77 23.278-16.671 3.146-4.385 7.332-10.336 6.036-16.06-.313-1.166-.877-2.25-1.651-3.177-.775-.927-1.741-1.674-2.833-2.19-1.158-.682-2.367-1.272-3.616-1.765.541.464 1.041.973 1.494 1.523z"
        fill="#6100ff"
      />
      <path
        d="m228.133 123.203c-.122-.096-.232-.206-.328-.328 1.424.598 1.837.783 1.054.556-.239-.066-.481-.118-.726-.157.27.248.522.514.754.797.394.546.697 1.152.897 1.794.4 1.289.511 2.65.328 3.986-.627 5.297-4.072 10.152-7.204 14.238-3.278 4.531-7.39 8.397-12.116 11.389-2.153 1.296-4.452 2.333-6.848 3.09l-.37.142c-.078 1.014-.078 2.033 0 3.047.512 0 1.025-.157 1.523-.257.499-.099 1.196-.298 1.78-.484-.584.186-1.182.342-1.78.484 9.582-1.822 17.64-8.77 23.278-16.671 3.146-4.385 7.332-10.336 6.036-16.06-.313-1.166-.877-2.25-1.651-3.177-.775-.927-1.741-1.674-2.833-2.19-1.158-.682-2.367-1.272-3.616-1.765.541.464 1.041.973 1.494 1.523z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m187.127 157.655c-2.05-1.483-3.799-3.344-5.154-5.481-1.385-2.157-2.297-4.583-2.676-7.119 0-.37-1.268-.84-1.566-.996-.299-.157-1.723-.698-1.723-.684.239 2.502 1.017 4.922 2.28 7.095 1.263 2.172 2.982 4.045 5.038 5.491.811.598 1.651 1.182 2.534 1.708.991.425 2.014.767 3.061 1.025-.637-.274-1.239-.623-1.794-1.039z"
        fill="#6100ff"
      />
      <path d="m200.258 159.395c1.13-.129 2.242-.382 3.317-.754-1.09.315-2.198.567-3.317.754z" fill="#6100ff" />
      <path
        d="m228.866 123.36c.783.228.427 0-1.054-.555.087.146.192.28.314.398.249.038.496.091.74.157z"
        fill="#6100ff"
      />
      <path
        d="m265.476 194.898c-.341-1.302-.341-2.67 0-3.972.745-2.64 1.811-5.179 3.175-7.56 1.34-2.55 2.358-5.257 3.032-8.058.822-3.414.345-7.012-1.338-10.094-1.614-1.599-3.505-2.892-5.581-3.816.317.347.612.713.883 1.097 3.701 5.324 1.509 12.258-1.011 17.554-1.822 3.858-6.378 10.592-2.52 14.436 1.199.584 2.49.955 3.816 1.097-.191-.2-.345-.431-.456-.684z"
        fill="#6100ff"
      />
      <path d="m301.017 194.058c.03.037.063.07.1.1h.128c.74.099-.669-.584-.228-.1z" fill="#6100ff" />
      <path
        d="m307.137 197.632c-.384-.37-.769-.726-1.182-1.053-3.972-3.336-8.928-5.276-14.109-5.524-5.694 0-11.048 2.05-16.472 3.516-3.012.936-6.158 1.369-9.311 1.282.134.164.244.346.328.541-.087-.146-.203-.273-.34-.373s-.293-.172-.458-.211l-.655-.1c-1.309-.242-.213-.242.655.1h.328.142c0-.1-.128-.185-.185-.271-1.326-.141-2.617-.512-3.816-1.096.237.24.494.459.769.655 2.33 1.78 5.112 2.87 8.03 3.146 3.215.013 6.413-.467 9.482-1.423 6.008-1.652 12.13-4.129 18.508-3.218.712.114 1.566.484 2.278.541-.037-.029-.07-.063-.1-.1-.441-.541.968.143.228.114h-.128c.201.177.415.339.641.484 1.965.641 3.788 1.656 5.367 2.99z"
        fill="#6100ff"
      />
      <path
        d="m307.137 197.632c-.384-.37-.769-.726-1.182-1.053-3.972-3.336-8.928-5.276-14.109-5.524-5.694 0-11.048 2.05-16.472 3.516-3.012.936-6.158 1.369-9.311 1.282.134.164.244.346.328.541-.087-.146-.203-.273-.34-.373s-.293-.172-.458-.211l-.655-.1c-1.309-.242-.213-.242.655.1h.328.142c0-.1-.128-.185-.185-.271-1.326-.141-2.617-.512-3.816-1.096.237.24.494.459.769.655 2.33 1.78 5.112 2.87 8.03 3.146 3.215.013 6.413-.467 9.482-1.423 6.008-1.652 12.13-4.129 18.508-3.218.712.114 1.566.484 2.278.541-.037-.029-.07-.063-.1-.1-.441-.541.968.143.228.114h-.128c.201.177.415.339.641.484 1.965.641 3.788 1.656 5.367 2.99z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m265.594 195.812c.165.039.321.111.458.211.137.101.252.227.339.373-.083-.194-.194-.377-.327-.541h-.143z"
        fill="#6100ff"
      />
      <path
        d="m328.666 234.491c-.071 0-3.815-2.748-4.356-2.221-.732.713-1.65 1.206-2.648 1.424-.653.149-1.326.192-1.993.128h-.114c.709.734 1.475 1.41 2.292 2.022l.939.256c1.034.298 2.129.306 3.167.022 1.037-.284 1.976-.848 2.713-1.631z"
        fill="#6100ff"
      />
      <path
        d="m328.666 234.491c-.071 0-3.815-2.748-4.356-2.221-.732.713-1.65 1.206-2.648 1.424-.653.149-1.326.192-1.993.128h-.114c.709.734 1.475 1.41 2.292 2.022l.939.256c1.034.298 2.129.306 3.167.022 1.037-.284 1.976-.848 2.713-1.631z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m319.346 233.822c.227.242.441.484.655.755-2.108-.925-2.663-1.239-1.68-.954.232.074.47.126.711.157h.314c-.72-.706-1.346-1.5-1.865-2.364-.671-1.319-1.178-2.715-1.51-4.157-1.423-5.979-1.423-12.215-2.847-18.209-.921-4.296-2.999-8.259-6.008-11.461-1.588-1.315-3.416-2.311-5.382-2.933l.129.114c1.049.922 1.981 1.969 2.776 3.118 1.58 2.333 2.75 4.919 3.459 7.645 1.808 6.535 1.424 13.469 3.189 19.932.584 2.633 1.99 5.011 4.015 6.791 1.949 1.542 4.157 2.723 6.521 3.488-.817-.612-1.583-1.288-2.292-2.021z"
        fill="#6100ff"
      />
      <path
        d="m318.314 233.625c-.982-.285-.427 0 1.68.954-.214-.271-.427-.513-.655-.755h-.313c-.243-.044-.481-.111-.712-.199z"
        fill="#6100ff"
      />
      <path
        d="m205.92 168.262-.513-.128c.1-.143.313 0 .513.128l.299.071c-.186-.299-.527-.541-.741-.826-1.216-1.653-1.893-3.642-1.936-5.695-1.34.243-2.696.385-4.058.428.165.615.394 1.212.684 1.779 1.033 1.808 2.579 3.27 4.442 4.2.565.2 1.146.353 1.737.456-.131-.149-.274-.287-.427-.413z"
        fill="#6100ff"
      />
      <path
        d="m270.303 165.219c-.728-1.23-1.696-2.3-2.848-3.147-3.831-2.849-8.44-4.463-13.212-4.627-5.566.068-11.101.843-16.472 2.307-6.116 1.523-12.089 3.572-17.853 6.122-2.927 1.451-6.073 2.413-9.311 2.847-.555.036-1.111.036-1.666 0-.852.06-1.708.012-2.548-.142 0 .085 0 0-.086 0-.59-.104-1.171-.256-1.737-.456.37.214.755.413 1.139.612 2.339 1.355 4.951 2.168 7.646 2.378 6.435.213 12.5-3.787 18.351-5.937 6.496-2.391 13.231-4.071 20.089-5.011 2.714-.441 5.47-.57 8.214-.385 1.269.093 2.525.322 3.745.684l-.256-.2.469.214c.598.27.613.285 0 .071l-.213-.071.356.299.598.598c2.083.931 3.979 2.233 5.595 3.844z"
        fill="#6100ff"
      />
      <path
        d="m270.303 165.219c-.728-1.23-1.696-2.3-2.848-3.147-3.831-2.849-8.44-4.463-13.212-4.627-5.566.068-11.101.843-16.472 2.307-6.116 1.523-12.089 3.572-17.853 6.122-2.927 1.451-6.073 2.413-9.311 2.847-.555.036-1.111.036-1.666 0-.852.06-1.708.012-2.548-.142 0 .085 0 0-.086 0-.59-.104-1.171-.256-1.737-.456.37.214.755.413 1.139.612 2.339 1.355 4.951 2.168 7.646 2.378 6.435.213 12.5-3.787 18.351-5.937 6.496-2.391 13.231-4.071 20.089-5.011 2.714-.441 5.47-.57 8.214-.385 1.269.093 2.525.322 3.745.684l-.256-.2.469.214c.598.27.613.285 0 .071l-.213-.071.356.299.598.598c2.083.931 3.979 2.233 5.595 3.844z"
        fill="#000"
        opacity=".2"
      />
      <path d="m206.317 168.62h.128l-.185-.299-.299-.071c.127.115.246.238.356.37z" fill="#6100ff" />
      <path d="m206.444 168.633h-.085c.085.056.085.084.085 0z" fill="#6100ff" />
      <path d="m264.025 160.589c.569.214.555.2 0-.071l-.47-.213.256.199z" fill="#6100ff" />
      <path
        d="m208.944 168.777c-.515-.012-1.028-.059-1.537-.142-.143 0-.698-.171-1.196-.299l.185.299c.84.154 1.696.202 2.548.142z"
        fill="#6100ff"
      />
      <path d="m335.531 141.726c.228 0 .669-.784.143-.243-.072.143-.114.243-.143.243z" fill="#6100ff" />
      <path
        d="m338.144 130.833c-.539.677-.945 1.45-1.196 2.278-.143 1.651 0 3.303-.342 4.954-.071.427-.157.84-.256 1.253-.146 1.103-.394 2.19-.741 3.246 1.35-2.016 2.445-4.192 3.261-6.478.612-1.865 1.423-5.011.512-6.862-.028-.057-1.196 1.566-1.238 1.609z"
        fill="#6100ff"
      />
      <path
        d="m338.144 130.833c-.539.677-.945 1.45-1.196 2.278-.143 1.651 0 3.303-.342 4.954-.071.427-.157.84-.256 1.253-.146 1.103-.394 2.19-.741 3.246 1.35-2.016 2.445-4.192 3.261-6.478.612-1.865 1.423-5.011.512-6.862-.028-.057-1.196 1.566-1.238 1.609z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m335.998 140.573s-.171.584-.299.911c.526-.541.085.242-.143.242 0 0 .072-.099.143-.242l-.271.285c-2.256 2.475-5.146 4.287-8.357 5.239-.569.185-1.125 1.424-1.424 1.908-.142.228-.939 2.121-1.053 2.136 4.67-.869 8.186-4.143 10.706-8.002.114-.156.214-.327.313-.484.347-1.056.595-2.143.741-3.246-.129.427-.242.84-.356 1.253z"
        fill="#6100ff"
      />
      <path d="m147.016 209.125c.142.199 1.039.1.284 0-.156.014-.27.028-.284 0z" fill="#6100ff" />
      <path
        d="m157.437 205.036c-.857-.069-1.719.037-2.534.313-1.424.826-2.748 1.851-4.271 2.563l-1.182.498c-.982.517-2.012.937-3.075 1.253 2.407-.05 4.798-.399 7.119-1.039 1.879-.57 4.954-1.666 5.922-3.517.043-.057-1.922-.071-1.979-.071z"
        fill="#6100ff"
      />
      <path
        d="m157.437 205.036c-.857-.069-1.719.037-2.534.313-1.424.826-2.748 1.851-4.271 2.563l-1.182.498c-.982.517-2.012.937-3.075 1.253 2.407-.05 4.798-.399 7.119-1.039 1.879-.57 4.954-1.666 5.922-3.517.043-.057-1.922-.071-1.979-.071z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m148.221 208.839c-.071 0-.583.185-.925.257.754.128-.142.227-.285 0h.285-.384c-3.324-.431-6.466-1.765-9.084-3.859-.484-.355-1.822-.099-2.377 0-.257 0-2.278.442-2.349.356 3.388 3.332 8.086 4.343 12.699 4.2h.569c1.064-.316 2.094-.736 3.076-1.253-.399.029-.812.171-1.225.299z"
        fill="#6100ff"
      />
      <path d="m324.768 186.771c0-.185-.1-.356-.143-.527-.284-.455-.227-.27.143.527z" fill="#6100ff" />
      <path d="m330.421 194.16v-.242c-.114-.327-.114-.242 0 .242z" fill="#6100ff" />
      <path
        d="m324.387 184.577c-.056-.779-.028-1.562.086-2.335.285-1.765.972-3.44 2.007-4.897.525-.744 1.132-1.427 1.808-2.036.687-.673 1.338-1.381 1.951-2.122-.151-.98-.548-1.905-1.153-2.69-.51-.007-1.019.06-1.51.199-.366.169-.706.39-1.01.655-.691.565-1.312 1.21-1.851 1.922-.985 1.297-1.662 2.801-1.979 4.399-.443 3.216.156 6.489 1.708 9.34-.057-.716-.057-1.435 0-2.15-.025-.094-.044-.189-.057-.285z"
        fill="#6100ff"
      />
      <path d="m330.422 194.159c.007.085.007.171 0 .256v-.27z" fill="#6100ff" />
      <path
        d="m332.173 198.332c-.111-2.09-.747-4.117-1.851-5.894.069.471.102.947.1 1.423v.214c.156.413.142.512 0 .313v.199c.003.598-.059 1.195-.185 1.78-.187.804-.479 1.579-.869 2.306-1.118 1.862-2.425 3.603-3.901 5.197-.398.498 1.581 3.346 1.424 3.431 2.733-2.207 5.339-5.225 5.282-8.969z"
        fill="#6100ff"
      />
      <path
        d="m332.173 198.332c-.111-2.09-.747-4.117-1.851-5.894.069.471.102.947.1 1.423v.214c.156.413.142.512 0 .313v.199c.003.598-.059 1.195-.185 1.78-.187.804-.479 1.579-.869 2.306-1.118 1.862-2.425 3.603-3.901 5.197-.398.498 1.581 3.346 1.424 3.431 2.733-2.207 5.339-5.225 5.282-8.969z"
        fill="#6100ff"
      />
      <path d="m330.477 194.132c0-.086 0-.157 0-.214v.242.271c.142.213.156.113 0-.299z" fill="#6100ff" />
      <path
        d="m330.418 193.92c.002-.477-.031-.953-.1-1.424-.523-.886-1.159-1.7-1.893-2.42-.669-.641-1.424-1.196-2.093-1.837s-1.096-1.523-1.709-1.993c0 .171.086.342.143.527-.37-.798-.427-.983-.157-.541-.085-.442-.156-.869-.199-1.31-.057.715-.057 1.434 0 2.15.597 1.438 1.387 2.789 2.349 4.015.526.625 1.113 1.198 1.751 1.708.413.356.854.669 1.253 1.039l.399.356c.061.089.128.175.199.256.007-.085.007-.171 0-.256-.057-.512-.057-.598.057-.27z"
        fill="#6100ff"
      />
      <path
        d="m330.418 193.92c.002-.477-.031-.953-.1-1.424-.523-.886-1.159-1.7-1.893-2.42-.669-.641-1.424-1.196-2.093-1.837s-1.096-1.523-1.709-1.993c0 .171.086.342.143.527-.37-.798-.427-.983-.157-.541-.085-.442-.156-.869-.199-1.31-.057.715-.057 1.434 0 2.15.597 1.438 1.387 2.789 2.349 4.015.526.625 1.113 1.198 1.751 1.708.413.356.854.669 1.253 1.039l.399.356c.061.089.128.175.199.256.007-.085.007-.171 0-.256-.057-.512-.057-.598.057-.27z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m306.174 83.9934c-4.159 1.4742-8.018 3.6881-11.39 6.5348-1.63 1.2561-3.069 2.7428-4.271 4.4135-1.068 1.6942-.399 3.0183.584 4.5559.353.5844.743 1.1454 1.167 1.6804v-.499c.039-.485.139-.9636.299-1.4234h-.156c.137-.3537.319-.6885.541-.9966 1.878-2.5319 4.164-4.7353 6.762-6.5206 2.594-1.9685 5.391-3.6534 8.343-5.0257 0 0-1.509-2.8616-1.879-2.7193z"
        fill="#6100ff"
      />
      <path
        d="m317.675 99.0717c-.029.0591-.053.1211-.071.1851-.039.099-.086.1943-.142.2847-.115.2496-.253.4875-.413.7115v.086c-.63-.152-1.274-.233-1.922-.242-2.387.059-4.766.292-7.118.697-2.364.314-4.713.684-7.034 1.125-2.092.384-4.684 1.424-6.805.726-.797-.269-1.499-.764-2.022-1.424v-.498c.039-.485.139-.9637.299-1.4235 1.196.0744 2.396-.0605 3.545-.3987 3.104-.6359 6.222-1.1722 9.354-1.6088 2.751-.5769 5.564-.8018 8.372-.6691.799.0899 1.569.3561 2.254.7796.684.4235 1.266.9936 1.703 1.6692z"
        fill="#6100ff"
      />
      <path
        d="m317.675 99.0717c-.029.0591-.053.1211-.071.1851-.039.099-.086.1943-.142.2847-.115.2496-.253.4875-.413.7115v.086c-.63-.152-1.274-.233-1.922-.242-2.387.059-4.766.292-7.118.697-2.364.314-4.713.684-7.034 1.125-2.092.384-4.684 1.424-6.805.726-.797-.269-1.499-.764-2.022-1.424v-.498c.039-.485.139-.9637.299-1.4235 1.196.0744 2.396-.0605 3.545-.3987 3.104-.6359 6.222-1.1722 9.354-1.6088 2.751-.5769 5.564-.8018 8.372-.6691.799.0899 1.569.3561 2.254.7796.684.4235 1.266.9936 1.703 1.6692z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m317.635 105.693c-2.684 1.809-5.474 3.454-8.357 4.926-2.847 1.68-5.78 3.402-8.67 5.111-1.546.718-2.961 1.69-4.186 2.876h.086c-.233.336-.39.719-.461 1.122-.072.403-.055.817.048 1.213.059.216.14.426.242.626-.614-.514-1.135-1.13-1.538-1.822-.897-1.424-1.879-2.705-.854-4.271 1.212-1.402 2.726-2.512 4.428-3.246 4.556-2.611 9.088-5.244 13.596-7.902 1.595-.954 4.357-2.079 5.054-4.029-.002-.033-.002-.067 0-.1.16-.2236.298-.4619.413-.7115.056-.0905.104-.1858.142-.2848.018-.0639.042-.1259.072-.1851.199.2848.384.5695.583.8827.704.8477 1.132 1.8917 1.225 2.9897-.088.57-.296 1.115-.61 1.598-.314.484-.728.895-1.213 1.207z"
        fill="#6100ff"
      />
      <path
        d="m321.086 122.433c-2.781 3.012-6.263 5.291-10.136 6.635-.427.156-2.236-3.275-2.15-3.318 2.226-1.101 4.359-2.382 6.378-3.829 1-.745 1.951-1.554 2.847-2.421.493-.41.925-.89 1.282-1.423.077-.144.134-.297.171-.456.163.034.325.077.484.128.2.072.391.168.569.285.37.27.541.356.527.228l.484.356c.364.594.516 1.294.434 1.986-.083.693-.396 1.337-.89 1.829z"
        fill="#6100ff"
      />
      <path
        d="m321.557 118.605-.484-.356c-.344-.214-.712-.386-1.097-.513l-.484-.142c-.496-.1-1.004-.129-1.509-.086-1.265.152-2.513.419-3.73.798l-7.816 2.021c-2.503.785-5.051 1.417-7.631 1.894-.425.08-.861.071-1.282-.024-.421-.096-.818-.276-1.167-.531-.101-.201-.182-.41-.242-.627-.103-.396-.119-.809-.048-1.212s.228-.786.461-1.123c1.338-.258 2.655-.61 3.944-1.053l6.62-1.723c2.249-.584 4.485-1.21 6.748-1.737.765-.275 1.581-.383 2.391-.317.81.067 1.597.306 2.307.702 1.301 1.092 2.337 2.465 3.033 4.014z"
        fill="#6100ff"
      />
      <path
        d="m321.557 118.605-.484-.356c-.344-.214-.712-.386-1.097-.513l-.484-.142c-.496-.1-1.004-.129-1.509-.086-1.265.152-2.513.419-3.73.798l-7.816 2.021c-2.503.785-5.051 1.417-7.631 1.894-.425.08-.861.071-1.282-.024-.421-.096-.818-.276-1.167-.531-.101-.201-.182-.41-.242-.627-.103-.396-.119-.809-.048-1.212s.228-.786.461-1.123c1.338-.258 2.655-.61 3.944-1.053l6.62-1.723c2.249-.584 4.485-1.21 6.748-1.737.765-.275 1.581-.383 2.391-.317.81.067 1.597.306 2.307.702 1.301 1.092 2.337 2.465 3.033 4.014z"
        fill="#000"
        opacity=".2"
      />
      <path d="m319.968 117.691c-.159-.051-.32-.094-.484-.129z" fill="#6100ff" />
      <path
        d="m320.538 117.976c-.177-.117-.369-.213-.569-.285.384.127.752.299 1.096.513.014.128-.157.043-.527-.228z"
        fill="#6100ff"
      />
      <path
        d="m123.55 146.639c-1.805 2.727-3.094 5.762-3.801 8.955-.408 1.449-.586 2.952-.527 4.456.142 1.424 1.167 1.837 2.463 2.221.488.16.988.284 1.495.37-.084-.083-.16-.174-.228-.27-.217-.286-.39-.603-.512-.94h-.1c-.091-.256-.139-.525-.143-.797-.058-2.29.28-4.573.997-6.749.642-2.313 1.53-4.552 2.648-6.677 0 0-2.135-.811-2.292-.569z"
        fill="#6100ff"
      />
      <path
        d="m137.486 149.513v.128c.006.076.006.152 0 .228.043.192.072.387.085.584-.446.206-.867.464-1.253.769-1.393 1.137-2.702 2.374-3.915 3.701-1.267 1.267-2.492 2.577-3.687 3.915-1.196 1.339-2.15 2.962-3.731 3.588-.604.23-1.261.28-1.893.143-.084-.084-.16-.175-.228-.271-.217-.286-.39-.602-.512-.94.746-.519 1.4-1.159 1.936-1.893 1.566-1.794 3.175-3.559 4.855-5.268 1.388-1.608 2.965-3.042 4.698-4.271.539-.335 1.145-.546 1.776-.618.63-.071 1.269-.001 1.869.205z"
        fill="#6100ff"
      />
      <path
        d="m137.486 149.513v.128c.006.076.006.152 0 .228.043.192.072.387.085.584-.446.206-.867.464-1.253.769-1.393 1.137-2.702 2.374-3.915 3.701-1.267 1.267-2.492 2.577-3.687 3.915-1.196 1.339-2.15 2.962-3.731 3.588-.604.23-1.261.28-1.893.143-.084-.084-.16-.175-.228-.271-.217-.286-.39-.602-.512-.94.746-.519 1.4-1.159 1.936-1.893 1.566-1.794 3.175-3.559 4.855-5.268 1.388-1.608 2.965-3.042 4.698-4.271.539-.335 1.145-.546 1.776-.618.63-.071 1.269-.001 1.869.205z"
        fill="#000"
        opacity=".2"
      />
      <path
        d="m140.555 153.157c-.767 2.259-1.67 4.47-2.705 6.62-.954 2.278-1.88 4.584-2.848 6.876-.583 1.117-.969 2.326-1.139 3.574.03.283.121.557.266.803s.341.457.574.621l.427.227c-.599 0-1.195-.096-1.765-.284-1.167-.328-2.377-.598-2.506-1.937.078-1.356.468-2.677 1.139-3.858 1.509-3.573 2.99-7.118 4.442-10.72.527-1.267 1.652-3.175 1.154-4.585-.014-.196-.043-.391-.086-.583.007-.076.007-.152 0-.228v-.128l.769.199c.799.131 1.54.504 2.121 1.068.19.356.302.748.329 1.15.027.403-.031.807-.172 1.185z"
        fill="#6100ff"
      />
      <path
        d="m150.448 160.715c-.277 2.999-1.3 5.882-2.976 8.386-.185.284-2.847-.741-2.847-.798.81-1.641 1.487-3.346 2.022-5.097.255-.873.45-1.762.583-2.662.095-.468.119-.948.072-1.424-.023-.112-.056-.221-.1-.327l.342-.157c.169-.074.346-.131.526-.171.356 0 .484 0 .427-.113h.442c.493.148.916.468 1.193.901.277.434.389.953.316 1.462z"
        fill="#6100ff"
      />
      <path
        d="m148.897 158.367h-.441c-.308.039-.609.116-.897.228-.124.04-.243.093-.356.157-.342.179-.658.404-.94.669-.689.665-1.314 1.394-1.865 2.178l-3.73 4.784c-1.137 1.609-2.368 3.149-3.687 4.612-.449.497-1.071.802-1.737.855l-.427-.228c-.234-.163-.43-.375-.575-.621-.145-.245-.235-.519-.265-.803.676-.768 1.299-1.581 1.865-2.434l3.16-4.044c1.068-1.423 2.122-2.847 3.232-4.128.34-.515.78-.957 1.294-1.299.513-.342 1.09-.579 1.696-.695 1.272-.016 2.532.251 3.687.783z"
        fill="#6100ff"
      />
      <path
        d="m148.897 158.367h-.441c-.308.039-.609.116-.897.228-.124.04-.243.093-.356.157-.342.179-.658.404-.94.669-.689.665-1.314 1.394-1.865 2.178l-3.73 4.784c-1.137 1.609-2.368 3.149-3.687 4.612-.449.497-1.071.802-1.737.855l-.427-.228c-.234-.163-.43-.375-.575-.621-.145-.245-.235-.519-.265-.803.676-.768 1.299-1.581 1.865-2.434l3.16-4.044c1.068-1.423 2.122-2.847 3.232-4.128.34-.515.78-.957 1.294-1.299.513-.342 1.09-.579 1.696-.695 1.272-.016 2.532.251 3.687.783z"
        fill="#000"
        opacity=".2"
      />
      <path d="m147.56 158.641-.341.156c.108-.063.223-.115.341-.156z" fill="#6100ff" />
      <path
        d="m148.071 158.465c-.159.025-.316.063-.469.114.288-.112.589-.189.897-.227 0 0-.114.113-.428.113z"
        fill="#6100ff"
      />
      <path
        d="m373.438 173.133c1.067.811 2.22 1.537 3.345 2.235 2.136 1.31 3.944 2.42 5.054 4.769 2.079 4.457.769 9.596.257 14.238-.072.583 3.046 1.936 3.018 2.021 1.424-4.2 2.078-9.339.37-13.554-.945-2.116-2.464-3.926-4.385-5.225l-1.139-.669z"
        fill="#6100ff"
      />
      <path
        d="m373.438 173.133c1.067.811 2.22 1.537 3.345 2.235 2.136 1.31 3.944 2.42 5.054 4.769 2.079 4.457.769 9.596.257 14.238-.072.583 3.046 1.936 3.018 2.021 1.424-4.2 2.078-9.339.37-13.554-.945-2.116-2.464-3.926-4.385-5.225l-1.139-.669z"
        fill="#000"
        opacity=".2"
      />
      <g fill="#6100ff">
        <path d="m371.15 162.411c.214-5.353 2.634-10.18 4.172-15.22.1-.327-2.477-1.794-2.549-1.694-4.755 7.232-8.385 18.793-1.338 25.911.618.627 1.284 1.203 1.993 1.723l6.521 3.801 1.139.67c-1.572-1.115-3.202-2.147-4.883-3.09-3.958-2.349-5.225-7.816-5.055-12.101z" />
        <path d="m237.25 43.3169 23.562-32.0761c.161-.2509.415-.4278.706-.4918.291-.0641.595-.0101.846.1501.248.1631.423.4166.486.7065.064.2898.012.5931-.144.8453l-.086.1139z" />
        <path d="m237.25 54.125 48.406 4.1288c.298.0264.574.1702.766.3998.192.2297.286.5262.259.8245-.026.2983-.17.5739-.4.7661-.229.1923-.526.2854-.824.259z" />
        <path d="m233.883 64.2461 22.38 34.0266c.082.1243.138.2634.165.4094.028.146.027.296-.004.4414-.03.1455-.089.2835-.173.4062s-.191.2277-.315.3091c-.124.0813-.263.1373-.409.1652-.146.027-.296.026-.442-.0046-.145-.0304-.283-.0892-.406-.1729-.123-.0838-.228-.1909-.309-.3152z" />
        <path d="m224.943 64.2461-11.247 43.9499c-.082.279-.27.516-.523.66s-.552.184-.834.112-.525-.25-.678-.498c-.154-.247-.205-.545-.143-.829z" />
        <path d="m216.634 59.375-33.471 23.121c-.245.1679-.546.2335-.838.183-.293-.0505-.554-.2132-.728-.4535-.165-.2483-.226-.5513-.171-.8441.056-.2928.225-.552.47-.722z" />
        <path d="m216.639 47.7593-34.497-12.0731c-.257-.1178-.463-.3264-.576-.5859-.114-.2594-.128-.5517-.04-.8209.088-.2693.272-.4968.517-.6391.245-.1422.534-.1893.811-.1323h.1z" />
        <path d="m224.94 41.1972-7.246-34.08356c-.04-.1526-.048-.31174-.024-.4676s.08-.30513.164-.43857.194-.24821.325-.33723c.13-.08902.277-.15038.432-.18031.151-.04445.311-.05644.467-.03517.157.02126.307.07531.442.15859.134.08329.249.19395.338.32478.089.13082.149.27887.176.43449v.08543z" />
        <path d="m230.797 45.6106 1.979-18.1238c.016-.1477.061-.2909.132-.4212.071-.1304.167-.2455.283-.3387.115-.0932.248-.1627.391-.2045.142-.0419.292-.0552.44-.0393.147.0158.29.0607.421.1319.13.0712.245.1674.338.283.094.1157.163.2486.205.3912.042.1425.055.2919.039.4396.015.0464.015.096 0 .1424z" />
        <path d="m235.766 50.3791 13.482-3.7302c.146-.0412.298-.0532.449-.0355.15.0178.296.0651.428.1391s.249.1734.343.2925c.093.119.163.2553.204.4012.043.1458.057.2988.04.45-.017.1511-.064.2974-.138.4301-.075.1327-.175.2491-.295.3425s-.258.1618-.404.2011h-.186z" />
        <path d="m233.883 56.7578 14.436 6.4921c.274.1272.486.3564.593.6384.106.2821.098.5947-.023.8707-.127.2698-.355.4791-.634.5829-.28.1038-.589.0939-.861-.0276-.055-.0145-.104-.0441-.143-.0854z" />
        <path d="m229.766 60.5 3.174 17.5685c.055.2926-.009.5947-.177.8402-.169.2455-.427.4143-.719.4696-.293.0542-.595-.0098-.841-.1779-.245-.1681-.414-.4266-.469-.719v-.1424z" />
        <path d="m223.991 57.8125-10.522 14.2371c-.176.2388-.439.3983-.733.4436-.293.0454-.593-.0271-.833-.2016-.239-.1789-.399-.4449-.445-.7406-.045-.2957.028-.5973.203-.8397l.1-.1139z" />
        <path d="m221.069 51.9883-15.505 3.246c-.146.0309-.298.0325-.445.0049-.148-.0277-.288-.0841-.414-.1661-.125-.082-.233-.1879-.318-.3117-.085-.1237-.144-.263-.175-.4098-.031-.1467-.033-.2981-.005-.4455s.084-.2879.166-.4135.188-.2338.312-.3185.263-.1441.41-.175h.156z" />
        <path d="m224.008 46.589-11.233-12.8134c-.112-.2238-.146-.4785-.097-.7238s.179-.4672.369-.6305c.189-.1634.428-.2589.678-.2714.249-.0126.496.0585.701.202l.086.1282z" />
        <path d="m263.867 39.8715 12.23-3.844c.136-.0451.284-.0344.412.0296.128.0641.226.1763.271.3121.041.1398.027.29-.039.4197-.066.1298-.179.2292-.317.2779z" />
        <path d="m259.367 73.0469 10.977 6.2643c.076.03.144.0759.2.1345.057.0586.1.1286.127.2053s.037.1583.03.2393-.032.1595-.072.2303-.094.1322-.16.1801-.141.0811-.221.0975c-.079.0164-.161.0155-.241-.0025-.079-.0181-.154-.0529-.218-.1022z" />
        <path d="m232.344 93.2891v10.3649c0 .151-.06.296-.167.402-.107.107-.252.167-.403.167-.074 0-.147-.015-.216-.043-.068-.029-.13-.071-.182-.125-.051-.053-.092-.115-.119-.185-.027-.069-.04-.142-.038-.216z" />
        <path d="m205.403 85.2305-8.186 10.4784c-.091.1041-.217.1716-.354.1898s-.276-.014-.391-.0907-.198-.1925-.234-.326-.022-.2755.039-.3995z" />
        <path d="m191.542 57.3824-13.482.4271c-.328 0-.584 0-.598-.3417-.015-.3417.227-.3701.541-.3701z" />
        <path d="m202.789 21.1484-8.542-9.4677c-.083-.1097-.122-.2453-.113-.3821.01-.1367.069-.2653.166-.3623.097-.0969.225-.1556.362-.1653.137-.0098.272.0302.382.1124z" />
        <path d="m237.25 11.9244 3.047-11.489296c.016-.074191.048-.144212.092-.205737.045-.061526.102-.113245.167-.1519492.065-.0387038.138-.063576.213-.07306883.076-.00949285.152-.00340629.225.01787883.075.0166685.145.0481282.207.0925122.062.044383.115.100791.155.165863.039.065071.066.137483.077.212939.011.075455.007.152407-.012.226299z" />
        <path d="m227.52 42.789-1.153-12.6425c.006-.138.062-.2691.157-.3689.096-.0999.224-.1616.362-.1738.137-.0122.275.026.386.1075.112.0815.19.2008.22.3355z" />
        <path d="m234.914 42.0065 3.588-9.0975c.054-.1387.16-.2506.296-.3118s.29-.0668.43-.0156c.14.0571.252.1661.313.3041.061.1381.066.2944.014.4362v.0712z" />
        <path d="m237.414 47.1026 8.428-4.2711c.131-.0649.282-.0759.42-.0306.139.0453.254.1432.321.2726.065.1326.077.2855.031.4263-.045.1408-.143.2586-.273.3283h-.043z" />
        <path d="m238.633 56.3164 7.545.9397c.147.0212.279.0984.369.2153s.132.2643.116.4111c-.009.075-.033.1475-.07.2133s-.087.1236-.146.17c-.06.0464-.128.0804-.201.1001-.073.0198-.149.0248-.224.0149h-.071z" />
        <path d="m233.883 59.375 5.567 4.7552c.066.0449.123.1036.166.1722.042.0685.07.1453.081.2252.01.08.004.1613-.019.2386s-.063.1488-.116.2097c-.052.061-.118.1101-.191.1439-.073.0339-.153.0518-.233.0526-.081.0008-.161-.0155-.235-.0479-.074-.0325-.14-.0802-.194-.1401z" />
        <path d="m226.938 63.875.57 8.9978c.003.073-.007.1459-.032.2148-.024.0688-.062.1322-.111.1864s-.108.0982-.174.1296c-.066.0313-.137.0493-.21.0529-.074.0059-.149-.0033-.219-.0268-.071-.0235-.136-.0609-.192-.1101-.055-.0491-.101-.1089-.133-.1759-.032-.0669-.051-.1397-.054-.2139z" />
        <path d="m221.828 63.875-3.033 5.7803c-.072.1314-.192.2299-.335.275-.144.045-.299.0332-.433-.033-.066-.0329-.125-.079-.173-.1354-.047-.0564-.083-.122-.104-.1927-.022-.0706-.028-.145-.02-.2183.009-.0734.032-.1442.069-.2082z" />
        <path d="m218.823 55.1641-5.267 3.1464c-.062.0394-.131.0663-.203.0791s-.146.0112-.217-.0047c-.072-.0159-.139-.0457-.199-.0878s-.111-.0956-.15-.1575c-.074-.131-.095-.2857-.058-.4316s.129-.2718.257-.3514h.1z" />
        <path d="m221.073 57.8125-4.67 5.1253c-.108.0842-.242.1276-.379.1227s-.268-.0578-.37-.1495-.168-.2162-.187-.3519c-.02-.1356.01-.2737.082-.3901h.071z" />
        <path d="m218.513 50.9484-4.499.5552c-.073.01-.147.0053-.218-.0137s-.138-.052-.196-.097c-.059-.0451-.107-.1013-.144-.1654-.036-.0641-.059-.1348-.068-.2079-.01-.0749-.005-.151.015-.2239.019-.0729.053-.1412.1-.2008.046-.0596.104-.1094.17-.1465s.138-.0607.213-.0695h.128z" />
        <path d="m220.069 46.1667-6.407-4.442c-.099-.0935-.161-.2193-.175-.3545s.021-.2709.099-.3827c.077-.1117.192-.1921.323-.2266.132-.0346.271-.0209.394.0384h.085z" />
        <path d="m22.4456 135.131-2.52-16.672c-.0409-.272.0276-.549.1903-.77.1628-.222.4066-.37.6782-.412.2734-.041.5521.027.7758.19.2237.162.3746.406.4201.679.0067.042.0067.085 0 .128z" />
        <path d="m28.0078 138.207 11.1192-6.549c.2397-.14.5252-.179.7935-.108s.4976.245.6373.485.1784.525.1077.794c-.0708.268-.2452.497-.485.637h-.1566z" />
        <path d="m27.8672 144.328 14.4079 2.349c.1361.021.2667.069.3843.14.1175.072.2197.166.3005.278.0807.111.1386.238.1701.372s.0361.273.0135.409c-.0227.135-.0721.265-.1454.382-.0732.116-.169.217-.2815.296-.1126.08-.2398.136-.3743.166-.1344.029-.2734.032-.4089.007h-.1424z" />
        <path d="m25.0703 148.656 7.0331 14.892c.0672.124.1079.261.1198.402.0118.14-.0055.282-.051.416-.0454.133-.118.256-.2131.361-.0951.104-.2108.187-.3397.245-.1237.069-.2607.112-.4019.125s-.2836-.004-.4179-.05c-.1343-.045-.2574-.119-.3615-.215-.104-.097-.1867-.214-.2424-.344v-.128z" />
        <path d="m19.3052 147.617-5.9938 15.12c-.1044.251-.3027.451-.5525.557-.2499.106-.5315.111-.7845.012s-.4574-.292-.5694-.54c-.112-.247-.1227-.528-.0299-.784v-.128z" />
        <path d="m15.2992 143.148-13.04112 6.592c-.12375.066-.25945.106-.39898.117-.13953.012-.28-.005-.41296-.048-.13297-.044-.2557-.115-.36083-.207-.105125-.093-.190481-.205-.250934-.332-.063522-.124-.101759-.259-.112498-.398s.006239-.279.049943-.412c.043705-.132.113274-.255.204663-.36.091386-.105.202776-.191.327716-.253h.14237z" />
        <path d="m16.6429 137.637-13.06959-8.684c-.16264-.172-.26172-.395-.28085-.63-.01912-.236.04284-.472.17566-.668s.32854-.34.55477-.41c.22623-.069.46943-.06.68938.028l.09966.085z" />
        <path d="m18.8698 133.41-4.0291-10.991c-.0278-.125-.0092-.256.0524-.368.0616-.113.162-.199.2824-.242.1203-.044.2524-.042.3716.005.1192.046.2173.135.276.249z" />
        <path d="m25.2891 130.932 1.0108-8.955c.0071-.069.0279-.136.0611-.198.0333-.061.0784-.115.1326-.159.0543-.043.1168-.076.1837-.095.0669-.02.1371-.026.2063-.018.1359.021.2587.094.3433.202.0847.108.1248.245.1123.382z" />
        <path d="m28.6875 134.889 6.4779-5.852c.0476-.047.1042-.085.1665-.111s.129-.039.1965-.039.1343.013.1966.039.1188.064.1665.111c.0477.048.0856.105.1115.167s.0392.129.0392.197c0 .067-.0133.134-.0392.196s-.0638.119-.1115.167z" />
        <path d="m31.9922 142.807 6.9334-.954c.0685-.01.1383-.006.2051.012.0669.017.1295.049.1841.091.0545.043.0999.096.1333.156.0335.061.0543.128.0613.197.01.067.0065.136-.0104.202-.0168.066-.0467.128-.0878.182s-.0926.1-.1515.134c-.059.034-.1241.057-.1917.065z" />
        <path d="m28.4688 146.664 6.0792 2.847c.1227.059.2171.164.2625.292s.038.269-.0205.392-.1634.217-.2916.262c-.1281.046-.2691.038-.3918-.02z" />
        <path d="m18.8158 153.555-1.324 5.865c-.0276.136-.1072.255-.2217.332s-.2547.106-.3905.081c-.1357-.03-.2538-.113-.3285-.231-.0747-.117-.0999-.259-.0702-.395-.0067-.023-.0067-.048 0-.071z" />
        <path d="m14.0584 146.508-4.0149 4.086c-.09552.092-.22312.144-.3559.144-.13279 0-.26039-.052-.35593-.144-.09738-.099-.15198-.232-.15198-.37 0-.139.0546-.272.15198-.37h.08543z" />
        <path d="m16.6888 148.328-2.9329 5.695c-.0385.057-.088.106-.1457.143-.0577.038-.1224.063-.1901.075-.0678.012-.1373.01-.2043-.005-.067-.016-.1303-.044-.186-.085-.0981-.064-.1715-.159-.2077-.271-.0363-.111-.0333-.232.0084-.341z" />
        <path d="m12.8014 142.805-3.87249 1.58c-.06449.025-.13335.038-.20263.036-.06927-.001-.1376-.016-.20109-.044-.06348-.027-.12086-.067-.16885-.117-.04798-.05-.08563-.109-.1108-.174-.02751-.062-.04195-.13-.0424-.198s.01308-.136.03977-.198c.02669-.063.06596-.12.11539-.166.04943-.047.10796-.084.17198-.107h.12813z" />
        <path d="m13.0227 138.193-6.77681-2.505c-.12731-.051-.23047-.149-.28862-.273-.05814-.124-.06694-.266-.0246-.397.02367-.064.0599-.122.10655-.172s.10279-.09.16512-.118.12961-.043.19786-.044c.06826-.002.13612.01.19962.035z" />
        <path d="m21.4563 160.958c.2847.954.7545 2.149.9966 3.203.2636 1.694.2636 3.418 0 5.111-.3737 1.638-.607 3.305-.6977 4.983.0218.811.2057 1.61.541 2.349.5434 1.01.8501 2.129.897 3.275.1139 6.663.484 21.882.4698 23.89.3417 0 .6122-16.629.541-24.716-.0612-1.332-.4007-2.637-.9966-3.83-.0997-.232-.1714-.476-.2135-.726-.1052-.921-.0668-1.853.1139-2.762.1708-.811.3986-1.424.5694-2.278.3764-2 .4245-4.049.1424-6.065-.1097-1.047-.4344-2.06-.9539-2.975-.3158-.43-.4488-.968-.3701-1.495.291-1.823.2185-3.686-.2136-5.481-.1139-.399-.4413-.784-.4556-.442-.1993 6.891-.9823 5.88-.3701 7.959z" />
        <path d="m23.6767 143.046c-.0037.322-.1344.63-.3636.857-.2292.226-.5385.353-.8608.353-.3209 0-.6287-.127-.8557-.354-.2269-.227-.3544-.535-.3544-.856s.1275-.629.3544-.856c.227-.227.5348-.354.8557-.354.3223 0 .6316.127.8608.353.2292.227.3599.535.3636.857z" />
        <path d="m424.874 94.485-3.46-22.281c.013-.33.14-.6454.36-.8919s.519-.409.845-.4594c.327-.0505.66.0141.944.1827s.501.4308.613.7415v.1709z" />
        <path d="m432.305 98.671 14.877-8.7558c.312-.1114.652-.1109.963.0014.311.1124.574.3295.742.6141.169.2847.233.619.181.9458-.051.3267-.214.6254-.462.8447h-.213z" />
        <path d="m432.117 106.785 19.263 3.132c.212-.009.423.029.618.111.196.083.37.208.511.367.141.158.244.346.303.55s.071.418.037.627c-.035.21-.116.408-.238.582-.121.174-.28.319-.464.424-.184.104-.39.167-.601.182-.212.015-.424-.018-.622-.095h-.185z" />
        <path d="m428.398 112.566 9.397 19.932c.149.334.164.712.042 1.057-.121.344-.371.629-.697.794-.332.15-.709.168-1.053.049-.344-.12-.63-.366-.798-.69v-.17z" />
        <path d="m420.678 111.188-8.016 20.23c-.029.214-.107.418-.226.597-.12.179-.279.328-.465.437-.185.109-.393.174-.608.191s-.431-.015-.631-.094c-.201-.079-.381-.202-.527-.36s-.254-.348-.316-.554-.077-.424-.043-.636c.034-.213.116-.415.239-.591.009-.058.034-.113.071-.157z" />
        <path d="m415.309 105.203-17.441 8.827c-.33.126-.695.125-1.024-.004-.329-.128-.598-.374-.756-.69-.158-.315-.193-.679-.098-1.019s.313-.633.611-.821h.199z" />
        <path d="m417.14 97.8291-17.469-11.6032c-.243-.2738-.371-.6307-.358-.9966.014-.3659.167-.7126.43-.9681.191-.1684.424-.2823.674-.3299.251-.0476.51-.0271.749.0594l.157.1139z" />
        <path d="m420.048 92.1935-5.367-14.7068c-.034-.0859-.05-.1777-.048-.2699.003-.0922.024-.183.062-.2668.039-.0838.094-.1589.163-.2208.068-.062.148-.1094.235-.1395.172-.06.361-.0503.526.0268s.293.2157.357.3861z" />
        <path d="m428.68 88.8772 1.423-11.9734c.007-.0912.033-.18.075-.2612s.1-.1532.17-.2116c.071-.0584.152-.1021.239-.1285.088-.0264.18-.0349.271-.0251.092.0089.181.0359.262.0794.081.0436.153.1028.212.1743.058.0715.101.1538.128.2422.026.0884.034.1812.024.2729v.0854z" />
        <path d="m433.219 94.1566 8.67-7.8162c.062-.0633.137-.1135.219-.1478.081-.0343.169-.052.258-.052s.177.0177.259.052.156.0845.218.1478c.064.0635.114.139.149.222.034.0831.052.1721.052.2621 0 .0899-.018.179-.052.2621-.035.083-.085.1585-.149.222h-.071z" />
        <path d="m437.641 104.737 9.268-1.282c.093-.012.188-.006.279.019s.177.067.251.124c.075.058.138.129.185.211s.077.172.089.266c.013.093.006.188-.018.279-.025.091-.067.176-.125.251-.057.075-.129.137-.211.184-.081.047-.171.078-.265.09h-.1z" />
        <path d="m432.953 109.906 8.13 3.901c.165.08.292.222.354.396.061.173.051.363-.027.53-.083.163-.225.287-.397.348-.173.061-.362.054-.528-.021z" />
        <path d="m419.992 119.117-1.766 7.859c-.044.181-.156.337-.312.438s-.345.138-.528.103c-.18-.044-.335-.156-.433-.313-.099-.157-.132-.346-.094-.527 0 0 0 0 0-.1z" />
        <path d="m413.656 109.703-5.367 5.453c-.062.063-.137.113-.218.148-.082.034-.17.052-.259.052s-.177-.018-.259-.052c-.081-.035-.156-.085-.218-.148-.13-.131-.202-.307-.202-.491s.072-.361.202-.491h.1z" />
        <path d="m417.143 112.125-3.901 7.603c-.109.15-.272.254-.454.288-.183.035-.372-.002-.528-.103-.132-.087-.231-.216-.28-.366s-.046-.312.009-.46h.085z" />
        <path d="m411.96 104.781-5.183 2.093c-.086.04-.179.061-.274.063-.094.003-.189-.014-.277-.05-.087-.035-.167-.088-.234-.155s-.119-.147-.154-.235c-.035-.089-.051-.183-.049-.277.003-.095.025-.188.065-.274s.097-.163.168-.226c.07-.063.153-.111.243-.141h.156z" />
        <path d="m412.275 98.5855-9.069-3.3173c-.097-.0222-.188-.0648-.267-.1251-.079-.0602-.144-.1366-.192-.2241-.047-.0875-.075-.1841-.081-.2832-.007-.0992.007-.1986.042-.2917s.089-.1777.159-.2481.155-.1251.248-.1602c.093-.0352.192-.0501.291-.0437.1.0063.196.0338.284.0806h.085z" />
        <path d="m423.559 129.026c.37 1.267 1.011 2.847 1.324 4.271.357 2.259.357 4.56 0 6.82-.491 2.192-.805 4.42-.939 6.663.031 1.086.277 2.156.726 3.146.724 1.354 1.138 2.852 1.21 4.385.156 8.898.64 29.257.612 31.934.456 0 .84-22.224.74-33.044-.087-1.78-.548-3.522-1.352-5.112-.107-.327-.174-.667-.2-1.01-.153-1.229-.105-2.475.143-3.688.213-1.096.527-1.979.74-3.061.51-2.671.578-5.408.199-8.101-.147-1.396-.578-2.748-1.267-3.972-.421-.578-.6-1.299-.498-2.007.388-2.439.296-4.929-.27-7.332-.171-.527-.598-1.025-.613-.584-.327 9.226-1.381 7.916-.555 10.692z" />
        <path d="m426.496 105.076c0 .321-.095.635-.273.902-.179.267-.432.475-.729.598-.296.122-.623.155-.938.092-.314-.063-.604-.217-.831-.444s-.381-.516-.444-.831c-.062-.315-.03-.641.093-.938.122-.297.33-.55.597-.728.267-.179.581-.274.902-.274.43 0 .843.171 1.148.475.304.305.475.718.475 1.148z" />
      </g>
    </motion.svg>
  );
};

export default Salute;
