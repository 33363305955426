import React, { FC } from "react";

type Props = {
  isActive?: boolean;
};
export const DocAsideIcon: FC<Props> = ({ isActive }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="fluent:document-one-page-multiple-16-filled">
        <path
          id="Vector"
          d="M7.0695 2.23291C5.6505 2.23291 4.5 3.38491 4.5 4.80391V17.6619C4.5 19.0824 5.6505 20.2329 7.0695 20.2329H15.4305C16.8495 20.2329 18 19.0809 18 17.6619V4.80391C18 3.38491 16.8495 2.23291 15.4305 2.23291H7.0695ZM14.25 15.7434C14.2501 15.9189 14.1885 16.0889 14.0762 16.2238C13.9638 16.3587 13.8077 16.4498 13.635 16.4814L13.5 16.4934H9C8.81258 16.4938 8.63182 16.4239 8.49332 16.2976C8.35483 16.1714 8.26862 15.9978 8.25169 15.8112C8.23475 15.6245 8.28832 15.4383 8.40183 15.2892C8.51534 15.14 8.68058 15.0388 8.865 15.0054L9 14.9934H13.5C13.6989 14.9934 13.8897 15.0724 14.0303 15.2131C14.171 15.3537 14.25 15.5445 14.25 15.7434ZM13.635 11.9709L13.5 11.9829H9C8.81258 11.9833 8.63182 11.9134 8.49332 11.7871C8.35483 11.6609 8.26862 11.4873 8.25169 11.3007C8.23475 11.114 8.28832 10.9278 8.40183 10.7787C8.51534 10.6295 8.68058 10.5283 8.865 10.4949L9 10.4829H13.5C13.6874 10.4826 13.8682 10.5524 14.0067 10.6787C14.1452 10.8049 14.2314 10.9785 14.2483 11.1651C14.2652 11.3518 14.2117 11.538 14.0982 11.6872C13.9847 11.8363 13.8194 11.9375 13.635 11.9709ZM14.25 6.72091C14.2504 6.89671 14.1891 7.06706 14.0766 7.20222C13.9642 7.33739 13.8079 7.42877 13.635 7.46041L13.5 7.47241H9C8.81258 7.47276 8.63182 7.40292 8.49332 7.27665C8.35483 7.15038 8.26862 6.97683 8.25169 6.79018C8.23475 6.60352 8.28832 6.4173 8.40183 6.26816C8.51534 6.11903 8.68058 6.0178 8.865 5.98441L9 5.97241H13.5C13.6989 5.97241 13.8897 6.05143 14.0303 6.19208C14.171 6.33273 14.25 6.522 14.25 6.72091ZM19.5 8.23291C19.8978 8.23291 20.2794 8.39095 20.5607 8.67225C20.842 8.95355 21 9.33509 21 9.73291V17.5479C21 19.0557 20.401 20.5017 19.3349 21.5678C18.2688 22.634 16.8228 23.2329 15.315 23.2329H9C8.60218 23.2329 8.22064 23.0749 7.93934 22.7936C7.65804 22.5123 7.5 22.1307 7.5 21.7329H15C16.1935 21.7329 17.3381 21.2588 18.182 20.4149C19.0259 19.571 19.5 18.4264 19.5 17.2329V8.23291Z"
          fill={isActive ? "#6100FF" : "white"}
        />
      </g>
    </svg>
  );
};
