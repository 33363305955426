import React, { FC } from "react";

type Props = {
  isActive?: boolean;
};

export const RecommendIcon: FC<Props> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_5289_8285"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5289_8285)">
        <path
          d="M4 14.0005C4 12.1172 4.55833 10.3088 5.675 8.57549C6.79167 6.84216 8.325 5.32549 10.275 4.02549C10.6417 3.77549 11.0208 3.76299 11.4125 3.98799C11.8042 4.21299 12 4.55049 12 5.00049V6.30049C12 6.86716 12.1958 7.34216 12.5875 7.72549C12.9792 8.10883 13.4583 8.30049 14.025 8.30049C14.3083 8.30049 14.5792 8.23799 14.8375 8.11299C15.0958 7.98799 15.325 7.80883 15.525 7.57549C15.6583 7.40883 15.8292 7.30466 16.0375 7.26299C16.2458 7.22133 16.4417 7.26716 16.625 7.40049C17.675 8.15049 18.5 9.10883 19.1 10.2755C19.7 11.4422 20 12.6838 20 14.0005C20 15.4672 19.6417 16.8047 18.925 18.013C18.2083 19.2213 17.2667 20.1755 16.1 20.8755C16.3833 20.4755 16.6042 20.038 16.7625 19.563C16.9208 19.088 17 18.5838 17 18.0505C17 17.3838 16.875 16.7547 16.625 16.163C16.375 15.5713 16.0167 15.0422 15.55 14.5755L12 11.1005L8.475 14.5755C7.99167 15.0588 7.625 15.5922 7.375 16.1755C7.125 16.7588 7 17.3838 7 18.0505C7 18.5838 7.07917 19.088 7.2375 19.563C7.39583 20.038 7.61667 20.4755 7.9 20.8755C6.73333 20.1755 5.79167 19.2213 5.075 18.013C4.35833 16.8047 4 15.4672 4 14.0005ZM12 13.9005L14.125 15.9755C14.4083 16.2588 14.625 16.5755 14.775 16.9255C14.925 17.2755 15 17.6505 15 18.0505C15 18.8672 14.7083 19.563 14.125 20.138C13.5417 20.713 12.8333 21.0005 12 21.0005C11.1667 21.0005 10.4583 20.713 9.875 20.138C9.29167 19.563 9 18.8672 9 18.0505C9 17.6672 9.075 17.2963 9.225 16.938C9.375 16.5797 9.59167 16.2588 9.875 15.9755L12 13.9005Z"
          fill={isActive ? "#6100FF" : "white"}
        />
      </g>
    </svg>
  );
};
