import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";

import styles from "./index.module.css";

type ButtonVariants = "primary" | "outlined" | "light" | "outlined-gray" | "white";

type Size = "large" | "medium" | "small";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariants;
  children?: string | ReactNode;
  size: Size;
  type?: "button" | "submit" | "reset";
  rightIcon?: JSX.Element | string;
  leftIcon?: JSX.Element | string | ReactNode;
  disabled?: boolean;
  danger?: boolean;
  className?: string;
}

export const Button: FC<Props> = ({
  variant,
  size,
  type = "button",
  children,
  onClick,
  leftIcon,
  rightIcon,
  disabled,
  danger,
  className,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={danger ? `${styles.danger} ${styles[size]}` : `${styles[variant]} ${styles[size]} ${className}`}
      onClick={onClick}
    >
      <>{leftIcon}</>
      {children}
      <>{rightIcon}</>
    </button>
  );
};
