import React, { useEffect, useRef } from "react";

import checkCircle from "../../images/check_circle.svg";
import useWindowSize from "../../lib/hooks/useWindowSize";
import { useRightBottomPopup } from "../../store/useRightBottomPopup";
import ConsultationsPopup from "../ConsultationsPopup";
import PatientConsultations from "../PatientConsultations";
import styles from "./index.module.css";

export const Popup = () => {
  const { popupIsOpen, closePopup, text } = useRightBottomPopup((state) => state);
  const { width } = useWindowSize();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (popupIsOpen) {
      setTimeout(() => {
        closePopup();
      }, 3000);
    }
  }, [popupIsOpen]);

  return (
    <>
      <div className={styles.layout}>
        {width > 988 && (
          <>
            <ConsultationsPopup>
              <PatientConsultations />
            </ConsultationsPopup>
          </>
        )}
        {popupIsOpen && (
          <div ref={ref} className={styles.container}>
            <img src={checkCircle} className={styles.image}></img>
            <h2 className={styles.header}>{text}</h2>
          </div>
        )}
      </div>
    </>
  );
};
