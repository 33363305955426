import React, { FC } from "react";

type Props = {
  direction?: "left" | "right";
};

const BigArrow: FC<Props> = ({ direction = "left" }) => {
  return (
    <>
      {direction === "left" ? (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "1.3em" }} viewBox="0 0 32 32" fill="currentColor">
            <mask
              id="mask0_12687_53102"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="32"
              height="32"
            >
              <rect width="32" height="32" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_12687_53102)">
              <path d="M11.4722 17.7673L18.5243 24.8195L16.0026 27.3079L4.69531 16.0006L16.0026 4.69336L18.5243 7.18179L11.4722 14.2339H27.3098V17.7673H11.4722Z" />
            </g>
          </svg>
        </>
      ) : (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "1.3em" }} viewBox="0 0 32 32" fill="currentColor">
            <mask
              id="mask0_12687_53111"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="32"
              height="32"
            >
              <rect width="32" height="32" transform="matrix(-1 0 0 1 32 0)" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_12687_53111)">
              <path d="M20.5278 17.7673L13.4757 24.8195L15.9974 27.3079L27.3047 16.0006L15.9974 4.69336L13.4757 7.18179L20.5278 14.2339H4.69015V17.7673H20.5278Z" />
            </g>
          </svg>
        </>
      )}
    </>
  );
};

export default BigArrow;
