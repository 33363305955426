import React, { FC } from "react";

import { Question } from "../../../lib/models/questionnaire";
import DateForm from "./DateForm";
import MultipleInputForm from "./MultipleInputForm";
import OpenAnswerForm from "./OpenAnswerForm";
import SingleInputForm from "./SingleInputForm";
import TimeForm from "./TimeForm";
import Timeline from "./Timeline";

type Props = {
  question: Question;
};

const QuestionnaireInputs: FC<Props> = ({ question }) => {
  if (question.markup.format === "date") {
    return <DateForm question={question} />;
  }
  if (question.markup.format === "time") {
    return <TimeForm question={question} />;
  }
  if (question.markup.type === "open" && question.markup.format === "pressure") {
    return <OpenAnswerForm format={question.markup.format} question={question} size={question.markup.size} />;
  }
  if (question.markup.type === "open" && question.markup.size === "small") {
    return <OpenAnswerForm question={question} size={question.markup.size} />;
  }
  if (question.markup.type === "open" && question.markup.size === "big") {
    return <OpenAnswerForm question={question} size={question.markup.size} />;
  }
  if (question.markup.type === "multiple") {
    return <MultipleInputForm question={question} />;
  }
  if (question.markup.type === "single" && question.markup.view === "timeline") {
    return <Timeline question={question} />;
  }
  if (question.markup.type === "single") {
    return <SingleInputForm question={question} />;
  }
  return <OpenAnswerForm question={question} size={"small"} />;
};

export default React.memo(QuestionnaireInputs);
