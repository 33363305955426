import { FC, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../layouts/AuthProvider/AuthProvider";
import { Button } from "../../../UI/Button";
import { Input } from "../../../UI/Input";
import styles from "./index.module.css";

type LoginFormType = {
  serialNumber: string;
};

const LoginViaTestSerialNumberForm: FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>(null);
  const { loginViaTestSerialNumber } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>();

  const onSubmit = async (data: LoginFormType) => {
    setError(null);
    try {
      await loginViaTestSerialNumber(data.serialNumber);
      navigate("/");
      setError(null);
    } catch (e: any) {
      console.log(e);
      if (e.response.status === 404) {
        setError("ID теста не найден");
      } else setError(e.response.data.detail);
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Введите ID теста</h3>
      <p className={styles.description}>
        Чтобы перейти к заполнению анкеты для исследования Novabiom®, введите, пожалуйста, ID теста, который указан на
        пробирке.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Input
          type="text"
          placeholder="0000-XXX-0000"
          register={register("serialNumber", {})}
          className={styles.input}
          error={error}
          errorClassName={styles.error}
        />
        <Button variant="primary" size="large" type="submit">
          Заполнить анкету
        </Button>
      </form>
    </div>
  );
};

export default LoginViaTestSerialNumberForm;
