import { http } from "../index";

export const getPatient = async (id: number) => {
  const response = await http.get(`/api/patients/${id}`, {});
  return response.data;
};

export const updatePatient = async (data: any) => {
  const response = await http.patch(`/auth/users/me/`, { ...data });
  return response.data;
};

export const deletePatient = async () => {
  return await http.delete(`/api/users/me`);
};

export const changePassword = async (data: any) => {
  return await http.post("/auth/users/set_password/", data);
};

export const changeEmail = async (data: any) => {
  return await http.post("/auth/users/set_email/", data);
};

export const uploadPhoto = async (data: any) => {
  await http.patch(`/auth/users/me/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
