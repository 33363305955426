import React, { FC } from "react";
import { Link } from "react-router-dom";

import arrow from "../../images/arrow.svg";
import { WarningIcon } from "../../images/warning";
import { useUser } from "../../lib/hooks/useUser";
import styles from "./index.module.css";

type Props = {
  setToggle: React.Dispatch<React.SetStateAction<number>>;
};

export const DeleteProfileForm: FC<Props> = ({ setToggle }) => {
  const email = useUser().user.email;

  return (
    <div className={styles.container}>
      <div className={styles.goBack} role="button" onClick={() => setToggle(1)}>
        <img src={arrow} />
        <Link to="/profile">Вернуться к профилю</Link>
      </div>
      <h2 className={styles.header}>Удаление аккаунта</h2>
      <h3 className={styles.emailHeader}>Подтвердите удаление аккаунта</h3>
      <p className={styles.secondaryText}>
        {`Ссылка отправлена на адрес ${email} Если вы не получили письмо, 
            проверьте папку «Спам» или попробуйте отправить письмо еще раз.`}
      </p>
      <div className={styles.danger}>
        <WarningIcon danger />
        <p>Внимание! Вы не сможете восстановить аккаунт после его удаления.</p>
      </div>
    </div>
  );
};
