import "./pdf.css";

import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { PropagateLoader } from "react-spinners";

import { ReactComponent as SaveIcon } from "../../images/saveCurrentColor.svg";
import { PrimaryColors } from "../../lib/consts/colors";
import useWindowSize from "../../lib/hooks/useWindowSize";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";

type Props = {
  file: any;
  fetchError: boolean;
  onDownload?: () => void;
};

const PDFViewer: FC<Props> = ({ file, fetchError, onDownload }) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPdfLoaded(true);
    setNumPages(numPages);
  }

  const [showPdf, setShowPdf] = useState<boolean>(false);

  const onPageRenderSuccess = (page: number) => {
    if (page === numPages) {
      setTimeout(() => setShowPdf(true), 3000);
    }
  };

  const [scaleFactor, setScaleFactor] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const pdfRef = useRef<HTMLDivElement>(null);

  const minWidth = 600;

  const maxWidth = useMemo(() => {
    if (containerRef.current) {
      return containerRef.current.clientWidth - 150;
    }
    return minWidth;
  }, [width, pdfLoaded]);

  const [pdfWidth, setPdfWidth] = useState<number>(minWidth);

  useEffect(() => {
    if (pdfRef.current) {
      setTimeout(() => {
        if (pdfRef.current) {
          setPdfWidth(pdfRef.current.getBoundingClientRect().width);
        }
      }, 500);
    }
  }, [width, scaleFactor]);

  useEffect(() => {
    setScaleFactor(1);
  }, [maxWidth]);

  const onZoomIn = () => {
    if (containerRef.current) {
      if (pdfRef.current) {
        if (pdfRef.current.getBoundingClientRect().width < maxWidth) {
          setScaleFactor(scaleFactor + 0.1);
        }
      }
    }
  };

  const canZoom = useMemo(() => {
    return pdfWidth + 10 <= maxWidth;
  }, [pdfWidth, pdfLoaded, maxWidth]);

  const onZoomOut = () => {
    if (pdfRef.current) {
      if (pdfRef.current.getBoundingClientRect().width > minWidth) {
        setScaleFactor(scaleFactor - 0.1);
      } else {
        setScaleFactor(1);
      }
    }
  };

  return (
    <div className={`${styles.container}`}>
      {showPdf && (
        <div className={styles.zoomButtons}>
          {onDownload && (
            <Button
              type={"button"}
              variant="primary"
              size="large"
              className={styles.downloadButton}
              onClick={onDownload}
              leftIcon={
                <div style={{ color: "white", zIndex: 100, width: 24, height: 24 }}>
                  <SaveIcon width={24} height={24} />
                </div>
              }
            />
          )}
          <Button
            disabled={fetchError || !canZoom}
            type={"button"}
            variant="primary"
            size="large"
            className={styles.zoomButton}
            onClick={onZoomIn}
          >
            <div>+</div>
          </Button>
          <Button
            disabled={fetchError || scaleFactor === 1}
            type={"button"}
            variant="primary"
            size="large"
            className={styles.zoomButton}
            onClick={onZoomOut}
          >
            <div>-</div>
          </Button>
        </div>
      )}
      <div
        ref={containerRef}
        className={`${styles.pdfWrapper} ${!showPdf && "hide-scrollbar"} ${!showPdf && styles.pdfWrapperNotLoaded} ${
          width < 788 && "hide-scrollbar"
        }`}
      >
        <div ref={pdfRef} className={styles.pdf} style={{ scale: `${scaleFactor}` }}>
          <div className={`${styles.loader} ${showPdf && styles.loaderFadeOut}`}>
            {fetchError ? (
              <p className={styles.error}>Отчет не найден</p>
            ) : (
              <>
                <p>
                  Ваш отчет <span>загружается...</span>
                </p>
                <PropagateLoader size={16} color={PrimaryColors._900} />
              </>
            )}
          </div>
          <Document
            renderMode={"canvas"}
            className={`${styles.documentPdf} ${showPdf && styles.documentPdfLoaded}`}
            noData={<></>}
            loading={<></>}
            error={<></>}
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                onRenderSuccess={(pdf) => {
                  onPageRenderSuccess(pdf.pageNumber);
                }}
                className={index !== 0 ? `${styles.notLoadedPage} ${showPdf && styles.pageLoaded}` : styles.pageLoaded}
                width={width < 1200 ? pdfWidth : minWidth}
                scale={width < 988 ? 1 : 2}
                noData={<></>}
                loading={<></>}
                error={<></>}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PDFViewer);
