export const MONTHS = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

export const getFirstDayOfWeek = (d: Date | string | number) => {
  const date = new Date(Date.parse(new Date(d).toLocaleString("en-US", { timeZone: "Europe/Moscow" })));
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};
export const getLastDayOfWeek = (d: Date | string | number) => {
  const lastDay = new Date(getFirstDayOfWeek(d));
  lastDay.setDate(lastDay.getDate() + 6);
  return lastDay;
};

export const fromISOtoRussianLocale = (d: string, fullMonth?: boolean, sep?: string) => {
  const russianDate = new Date(d).toLocaleString("ru-RU").split(", ")[0];
  if (fullMonth) {
    const date = new Date(d);
    const month = MONTHS[date.getMonth()];
    return `${date.getDate()} ${month} ${date.getFullYear()}`;
  }
  if (sep) {
    return russianDate.replaceAll(".", sep);
  }
  return russianDate;
};

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
