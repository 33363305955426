import React from "react";

import Salute from "../../images/Salute";
import styles from "./index.module.css";

const SaluteHeader = () => {
  return (
    <div className={styles.image}>
      <Salute />
    </div>
  );
};

export default SaluteHeader;
