import React, { FC } from "react";

type Props = {
  danger?: boolean;
};

export const WarningIcon: FC<Props> = ({ danger }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_573_2074"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_573_2074)">
        <path
          d="M10.0003 14.1666C10.2364 14.1666 10.4345 14.0866 10.5945 13.9266C10.7539 13.7672 10.8337 13.5694 10.8337 13.3333C10.8337 13.0972 10.7539 12.8991 10.5945 12.7391C10.4345 12.5797 10.2364 12.5 10.0003 12.5C9.76421 12.5 9.56644 12.5797 9.40699 12.7391C9.24699 12.8991 9.16699 13.0972 9.16699 13.3333C9.16699 13.5694 9.24699 13.7672 9.40699 13.9266C9.56644 14.0866 9.76421 14.1666 10.0003 14.1666ZM9.16699 10.8333H10.8337V5.83329H9.16699V10.8333ZM10.0003 18.3333C8.84755 18.3333 7.76421 18.1144 6.75033 17.6766C5.73644 17.2394 4.85449 16.6458 4.10449 15.8958C3.35449 15.1458 2.76088 14.2638 2.32366 13.25C1.88588 12.2361 1.66699 11.1527 1.66699 9.99996C1.66699 8.84718 1.88588 7.76385 2.32366 6.74996C2.76088 5.73607 3.35449 4.85413 4.10449 4.10413C4.85449 3.35413 5.73644 2.76024 6.75033 2.32246C7.76421 1.88524 8.84755 1.66663 10.0003 1.66663C11.1531 1.66663 12.2364 1.88524 13.2503 2.32246C14.2642 2.76024 15.1462 3.35413 15.8962 4.10413C16.6462 4.85413 17.2398 5.73607 17.677 6.74996C18.1148 7.76385 18.3337 8.84718 18.3337 9.99996C18.3337 11.1527 18.1148 12.2361 17.677 13.25C17.2398 14.2638 16.6462 15.1458 15.8962 15.8958C15.1462 16.6458 14.2642 17.2394 13.2503 17.6766C12.2364 18.1144 11.1531 18.3333 10.0003 18.3333ZM10.0003 16.6666C11.8614 16.6666 13.4378 16.0208 14.7295 14.7291C16.0212 13.4375 16.667 11.8611 16.667 9.99996C16.667 8.13885 16.0212 6.56246 14.7295 5.27079C13.4378 3.97913 11.8614 3.33329 10.0003 3.33329C8.13921 3.33329 6.56283 3.97913 5.27116 5.27079C3.97949 6.56246 3.33366 8.13885 3.33366 9.99996C3.33366 11.8611 3.97949 13.4375 5.27116 14.7291C6.56283 16.0208 8.13921 16.6666 10.0003 16.6666Z"
          fill={danger ? "#FF2E00" : "#6100FF"}
        />
      </g>
    </svg>
  );
};
