import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";

import {
  bindTest,
  changeTestIsViewed,
  getTestConclusion,
  getTestResult,
  getTestResultPdfFile,
  getTestsByUserId,
  testIsViewed,
} from "../../API/PatientService";
import { useUser } from "../../lib/hooks/useUser";
import { Conclusion } from "../../lib/models/Conclusion";
import { FullTestResult, Test } from "../../lib/models/test";
import { usePatientStore } from "../../store/usePatientStore";
import { useTestStore } from "../../store/useTestStore";

export const usePdfQuery = (testId?: number) => {
  return useQuery({
    queryKey: ["PDF_KEY", testId],
    queryFn: async () => {
      if (!testId) return null;
      return await getTestResultPdfFile(testId);
    },
    cacheTime: 10000,
  });
};

export const useFetchPatientTests = (args?: { options?: UseQueryOptions<Test[], Error> }) => {
  const { user } = useUser();
  const { tests, setTests } = usePatientStore((state) => state);
  const { ...rest } = useQuery<Test[], Error>(["tests", user.id], () => getTestsByUserId(user.id), {
    ...args?.options,
    onSuccess: (tests) => setTests(tests),
  });

  return { ...rest, data: tests };
};

export const useFetchTestsConclusion = (
  testId?: number,
  args?: { options?: UseQueryOptions<Conclusion | undefined, Error> }
) => {
  const { ...rest } = useQuery<Conclusion | undefined, Error>(
    ["tests", "conclusions", testId],
    async () => {
      if (testId) {
        return await getTestConclusion(testId);
      }
      return undefined;
    },
    {
      ...args?.options,
    }
  );

  return { ...rest };
};

export const useFetchMainTestIsViewed = (args?: { options?: UseQueryOptions<{ is_viewed: boolean }, Error> }) => {
  const { mainTest, setMainTestIsViewed } = usePatientStore((state) => state);
  return useQuery<{ is_viewed: boolean }, Error>(
    ["test", "is_viewed", mainTest?.id],
    () => {
      if (mainTest) {
        return testIsViewed(mainTest.id);
      } else {
        return { is_viewed: false };
      }
    },
    {
      ...args?.options,
      onSuccess: (data) => {
        setMainTestIsViewed(data.is_viewed);
      },
    }
  );
};

export const useChangeMainTestIsViewed = () => {
  const queryClient = useQueryClient();

  return useMutation(changeTestIsViewed, {
    onSettled: async () => {
      await queryClient.invalidateQueries(["test"]);
    },
  });
};

export const useBindTest = () => {
  const queryClient = useQueryClient();
  return useMutation(bindTest, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["tests"]);
    },
  });
};

export const useFetchMainTestResult = (args?: { options?: UseQueryOptions<FullTestResult, Error> }) => {
  const mainTest = usePatientStore((state) => state.mainTest);
  const setTestData = useTestStore((state) => state.setTest);
  return useQuery<FullTestResult, Error>(
    ["tests", "test", mainTest?.id],
    () => {
      if (!mainTest) return JSON.parse("") as FullTestResult;
      return getTestResult(mainTest.id);
    },
    {
      ...args?.options,
      onSuccess: (test) => {
        setTestData(test);
      },
    }
  );
};
