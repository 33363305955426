import { AnimatePresence } from "framer-motion";
import React, { FC, useCallback, useMemo, useState } from "react";

import { useQuestionnaireState } from "../../../../lib/hooks/useQuestionnaireState";
import { Question } from "../../../../lib/models/questionnaire";
import OtherAnswerPopup from "../../OtherAnswerPopup";
import QuestionnaireMultipleItem from "../../QuestionnaireMultipleItem";
import styles from "./index.module.css";

type Props = {
  question: Question;
};

const buttonVariants = {
  hidden: {
    scale: 0,
  },
  show: (index: number) => ({
    scale: 1,
    transition: { delay: index * 0.1 },
  }),
  exit: {
    scale: 0,
    opacity: 0,
  },
};

const MultipleInputForm: FC<Props> = ({ question }) => {
  const { answers } = question.markup;
  const {
    state: [value, setValue],
  } = useQuestionnaireState<string[]>({ question });
  const [otherAnswerPopupIsOpen, setOtherAnswerPopupIsOpen] = useState<boolean>(false);

  const onOtherClick = () => {
    setOtherAnswerPopupIsOpen(true);
  };

  const onOtherSave = (value: string) => {
    value && onClick(value);
    setOtherAnswerPopupIsOpen(false);
  };

  const otherAnswers = useMemo(() => {
    if (Array.isArray(question.answer?.unformatted_answer)) {
      return question.answer?.unformatted_answer.filter((e) => !answers?.includes(e)) || [];
    }
    return [];
  }, [question.answer]);

  const onClick = useCallback(
    (answer: string) => {
      if (answer === "Другое") {
        setOtherAnswerPopupIsOpen(true);
        return;
      }
      if (value.includes(answer)) {
        setValue(value.filter((e) => e !== answer));
      } else {
        setValue([...value, answer]);
      }
    },
    [setValue, value]
  );

  return (
    <>
      <OtherAnswerPopup
        isOpen={otherAnswerPopupIsOpen}
        onClose={() => setOtherAnswerPopupIsOpen(false)}
        onSave={onOtherSave}
      />
      <div className={styles.container}>
        <AnimatePresence mode="popLayout">
          {otherAnswers.map((answer, index) => (
            <QuestionnaireMultipleItem
              key={answer}
              variants={buttonVariants}
              initial="hidden"
              animate="show"
              exit="exit"
              custom={index}
              selected={true}
              caption={answer}
              onClick={onClick}
            />
          ))}{" "}
          {answers?.map((answer, index) => (
            <QuestionnaireMultipleItem
              onOtherClick={onOtherClick}
              selected={value.includes(answer)}
              onClick={onClick}
              variants={buttonVariants}
              initial="hidden"
              animate="show"
              custom={index + otherAnswers.length}
              key={answer}
              caption={answer}
            />
          ))}
        </AnimatePresence>
      </div>
    </>
  );
};

export default MultipleInputForm;
