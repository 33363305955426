import React, { useEffect, useMemo } from "react";

import { Consultation } from "../../lib/models/Consultation";
import { useConsultationsStore } from "../../store/useConsultationsStore";
import ConsultationPopupCard from "../ConsultationPopupCard";
import styles from "./index.module.css";

const PatientConsultations = () => {
  const { consultations, fetchConsultations } = useConsultationsStore((state) => state);

  useEffect(() => {
    fetchConsultations();
  }, []);

  const notReadyConsultations = useMemo<Consultation[]>(() => {
    return consultations.filter((c) => c.status !== "проведена");
  }, [consultations]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Запланированные встречи</h2>
          <p>Не забудьте о запланированных консультациях специалистов</p>
        </div>
        <div className={styles.cards}>
          {notReadyConsultations.map((consultation) => (
            <ConsultationPopupCard key={consultation.id} consultation={consultation} />
          ))}
        </div>
      </div>
    </>
  );
};

export default PatientConsultations;
