import axios from "axios";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { PropagateLoader } from "react-spinners";

import { ReactComponent as SaveIcon } from "../../images/save.svg";
import { PrimaryColors } from "../../lib/consts/colors";
import { FullTestResult } from "../../lib/models/test";
import cn from "../../lib/utils/cn";
import { Button } from "../../UI/Button";
import styles from "./DocReportPreview.module.css";

type Props = {
  testResult: FullTestResult;
};

export type DocReportPreviewRef = {
  refresh: () => void;
};

type LoaderProps = {
  isLoaded: boolean;
};

const EMBED_URL = process.env.REACT_APP_PDF_GENERATOR_URL?.length
  ? `${process.env.REACT_APP_PDF_GENERATOR_URL}/embed`
  : "http://-";

const DOWNLOAD_URL = process.env.REACT_APP_PDF_GENERATOR_URL + "/api/pdf?doc=true";

const Loader = (props: LoaderProps) => {
  const { isLoaded } = props;
  const [loaderIsVisible, setLoaderIsVisible] = useState<boolean>(true);

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        setLoaderIsVisible(false);
      }, 500);
    } else {
      setLoaderIsVisible(true);
    }
  }, [isLoaded]);
  return (
    <div
      style={{ display: loaderIsVisible ? "flex" : "none" }}
      className={cn(styles.loader, isLoaded && styles.loaderLoaded)}
    >
      <div>
        <p>
          Отчет <span>загружается...</span>
        </p>
        <PropagateLoader size={16} color={PrimaryColors._900} />
      </div>
    </div>
  );
};

const DownloadButton = ({ testResult }: { testResult: FullTestResult }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await axios.post(DOWNLOAD_URL, testResult, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `DOC-${testResult.patient_info.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setLoading(false);
    }
  };

  return (
    <Button
      className={styles.downloadButtton}
      onClick={handleDownload}
      variant="primary"
      size="small"
      disabled={loading}
      rightIcon={<SaveIcon />}
    >
      {loading ? "Загрузка..." : "Скачать PDF"}
    </Button>
  );
};

const DocReportPreview = forwardRef<DocReportPreviewRef, Props>(({ testResult }, ref) => {
  const [iframeHeight, setIframeHeight] = useState<string>("100%");
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.type !== "WINDOW_RESIZE") {
        return;
      }
      if (event.data.height !== iframeHeight) {
        setIframeHeight(`${event.data.height}px`);
      }
    });
  }, []);

  const [iframeIsLoaded, setIframeIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (iframeRef.current && iframeIsLoaded) {
      setTimeout(() => {
        iframeRef.current?.contentWindow?.postMessage({ type: "TEST_RESULT", test: testResult }, "*");
      }, 100);
    }
  }, [iframeIsLoaded]);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.src += "";
        setIframeIsLoaded(false);
      }
    },
  }));

  return (
    <>
      <Loader isLoaded={iframeIsLoaded} />
      <iframe
        ref={iframeRef}
        onLoad={() => setIframeIsLoaded(true)}
        style={{
          width: "100%",
          height: iframeHeight,
          overflow: "hidden",
          border: "none",
        }}
        src={EMBED_URL}
      />
      <DownloadButton testResult={testResult} />
    </>
  );
});

DocReportPreview.displayName = "DocReportPreview";

export default DocReportPreview;
