import React, { FC, useEffect, useRef } from "react";

import { useOnClickOutside } from "../../lib/hooks/useOnClickOutside";
import useWindowSize from "../../lib/hooks/useWindowSize";
import { useConsultationsStore } from "../../store/useConsultationsStore";
import styles from "./index.module.css";

type ConsultationsPopupProps = {
  children: React.ReactNode;
};

const ConsultationsPopup: FC<ConsultationsPopupProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { popupIsOpen, closePopup } = useConsultationsStore((state) => state);
  const { width } = useWindowSize();
  useOnClickOutside(ref, closePopup);

  useEffect(() => {
    if (width < 988) {
      closePopup();
    }
    return () => {
      closePopup();
    };
  }, [width]);

  return (
    <>
      {popupIsOpen && (
        <div ref={ref} className={styles.container}>
          {children}
        </div>
      )}
    </>
  );
};

export default ConsultationsPopup;
