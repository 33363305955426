import React, { useEffect, useRef } from "react";

import { useOnClickOutside } from "../../lib/hooks/useOnClickOutside";
import useWindowSize from "../../lib/hooks/useWindowSize";
import { useMobilePopup } from "../../store/useMobilePopup";
import styles from "./index.module.css";

const BottomMobilePopup = () => {
  const { popupIsOpen, openPopup, closePopup, switchPopup, children } = useMobilePopup((state) => state);
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  useOnClickOutside(ref, closePopup);

  useEffect(() => {
    if (width > 988 && popupIsOpen) {
      closePopup();
    }
  }, [width]);

  return (
    <>
      {popupIsOpen && (
        <div className={styles.containerBackground}>
          <div ref={ref} className={styles.container}>
            <>{children}</>
          </div>
        </div>
      )}
    </>
  );
};

export default BottomMobilePopup;
