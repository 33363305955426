import classNames from "classnames";
import { motion } from "framer-motion";
import React, { FC, useEffect, useRef, useState } from "react";

import { useQuestionnaireState } from "../../../../lib/hooks/useQuestionnaireState";
import useWindowSize from "../../../../lib/hooks/useWindowSize";
import { Question } from "../../../../lib/models/questionnaire";
import styles from "./index.module.css";

type Props = {
  question: Question;
};

const variants = {
  hidden: {
    scale: 0,
  },
  show: (index: number) => ({
    scale: 1,
    transition: { delay: index * 0.1 },
  }),
  whileHover: {
    scale: 1.5,
  },
};

const selectedCircleVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
    left: 0,
  },
  show: ({ left, delay }: { left: number; delay: number }) => ({
    scale: 1,
    opacity: 1,
    left: left,
  }),
};

const lineVariants = {
  hidden: {
    opacity: 0,
    width: 0,
  },
  show: ({ index, width }: { index: number; width: number }) => ({
    opacity: 1,
    width: width,
    transition: { delay: index * 0.1 },
  }),
};

const Timeline: FC<Props> = ({ question }) => {
  const { answers } = question.markup;
  const {
    state: [value, setValue],
  } = useQuestionnaireState<string>({ question });
  const leftCircleRef = useRef<HTMLDivElement>(null);
  const rightCircleRef = useRef<HTMLDivElement>(null);
  const [lineWidth, setLineWidth] = useState<number>(0);
  const [lineOffset, setLineOffset] = useState<number>(0);
  const selectedCircleRef = useRef<HTMLDivElement>(null);
  const [selectedCirclePosition, setSelectedCirclePosition] = useState<number>(0);
  const { width } = useWindowSize();

  useEffect(() => {
    const leftCircle = leftCircleRef.current;
    const rightCircle = rightCircleRef.current;
    if (leftCircle && rightCircle) {
      setLineWidth(rightCircle.offsetLeft - leftCircle.offsetLeft);
      setLineOffset(leftCircle.offsetLeft);
    }
  }, [width]);

  useEffect(() => {
    setSelectedCirclePosition(selectedCircleRef?.current?.offsetLeft || 0);
  }, [selectedCircleRef?.current?.offsetLeft]);

  return (
    <div className={styles.container}>
      <div className={styles.answers}>
        {answers?.includes(question.answer?.answer as string) && (
          <motion.div
            variants={selectedCircleVariants}
            initial="hidden"
            animate="show"
            custom={{
              left: selectedCirclePosition,
              delay: answers?.length,
            }}
            className={styles.selectedCircle}
          />
        )}
        <motion.div
          variants={lineVariants}
          initial="hidden"
          animate="show"
          custom={{ index: answers?.length, width: lineWidth }}
          style={{ width: `${lineWidth}px`, left: `${lineOffset}px` }}
          className={styles.line}
        />
        {answers?.map((answer, index, array) => (
          <motion.button
            onClick={() => {
              setValue(answer);
            }}
            className={styles.answer}
            key={index}
          >
            <div ref={answer === value ? selectedCircleRef : null}>
              <motion.div
                variants={variants}
                initial="hidden"
                animate="show"
                custom={index}
                ref={index === 0 ? leftCircleRef : index === array.length - 1 ? rightCircleRef : null}
                className={classNames(styles.circle)}
              />
            </div>
            <motion.span variants={variants} initial="hidden" animate="show" custom={index}>
              {answer}
            </motion.span>
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
