import React from "react";
import { Link } from "react-router-dom";

import { Logo } from "../../../../images/logo";
import { useUser } from "../../../../lib/hooks/useUser";
import OrgPatientPicker from "../../OrgPatientPicker";
import styles from "./NovabiomOrgAsideHeaderVariant.module.css";

const NovabiomOrgAsideHeaderVariant = () => {
  const { profile_image } = useUser().user;
  return (
    <div className={styles.container}>
      <div className={styles.logosWrapper}>
        <div className={styles.logo}>
          <Link to="/">
            <Logo crop={true} white={true} />
          </Link>
        </div>
        {profile_image && (
          <div className={styles.orgLogoContainer}>
            <img className={styles.orgLogo} alt="org_log" src={profile_image} />
          </div>
        )}
      </div>
      <OrgPatientPicker />
    </div>
  );
};

export default NovabiomOrgAsideHeaderVariant;
