import React, { FC, ReactNode } from "react";

import { useAsideContext } from "../../generic/providers/AsideProvider";
import Portal from "../../UI/Portal";

type Props = {
  children: ReactNode;
};

const AsideMobileHeaderPortal: FC<Props> = ({ children }) => {
  const { asideMobilePortalElement } = useAsideContext();

  return <>{asideMobilePortalElement && <Portal element={asideMobilePortalElement}>{children}</Portal>}</>;
};

export default AsideMobileHeaderPortal;
