import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({
  children,
  element,
  onUnmount,
}: {
  children: ReactNode;
  element?: HTMLElement;
  onUnmount?: () => void;
}) => {
  const [container] = useState(() => document.createElement("div"));

  useEffect(() => {
    if (!element) {
      document.body.appendChild(container);
      return () => {
        onUnmount && onUnmount();
        document.body.removeChild(container);
      };
    }
  }, []);

  if (element) {
    return createPortal(children, element);
  } else {
    return createPortal(children, container);
  }
};

export default Portal;
