import React, { FC } from "react";

type Props = {
  isActive?: boolean;
};

const ReportIcon: FC<Props> = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 20.5V3.5H20.5V20.5H3.5ZM4.99997 19H19V4.99997H4.99997V19ZM7.36538 16.75H8.86535V12H7.36538V16.75ZM15.1346 16.75H16.6346V6.99998H15.1346V16.75ZM11.25 16.75H12.75V14H11.25V16.75ZM11.25 12H12.75V9.99998H11.25V12Z"
        fill={isActive ? "#6100FF" : "#FFFFFF"}
      />
    </svg>
  );
};

export default ReportIcon;
