import { motion } from "framer-motion";
import React, { FC, ReactNode } from "react";

import BigArrow from "../../../images/arrows/BigArrow";
import styles from "./index.module.css";

const Check = ({ white = false }: { white?: boolean }) => (
  <svg
    style={{ height: "1.3em" }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill={white ? "#FFF" : "#6100FF"}
  >
    <mask
      id="mask0_12687_53106"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect width="32" height="32" />
    </mask>
    <g mask="url(#mask0_12687_53106)">
      <path d="M12.7342 24.6037L4.53125 16.4008L7.05302 13.879L12.7342 19.5602L24.9487 7.3457L27.4705 9.86747L12.7342 24.6037Z" />
    </g>
  </svg>
);

type Props = {
  children?: ReactNode;
  primary?: boolean;
  skipAnswer?: string;
  direction?: "left" | "right";
  finishButton?: boolean;
  onClick: () => void;
  isActive?: boolean;
};

const QuestionnaireFooterButton: FC<Props> = ({
  children,
  onClick,
  primary = true,
  skipAnswer,
  finishButton = false,
  direction,
  isActive = true,
}) => {
  return (
    <motion.button
      disabled={!isActive}
      onClick={onClick}
      className={`${styles.container} ${primary ? styles.primary : styles.outlined}`}
    >
      {finishButton && <Check white />}
      {skipAnswer ? (
        <>
          <Check /> {skipAnswer}
        </>
      ) : direction === "left" ? (
        <BigArrow direction={"left"} />
      ) : direction === "right" ? (
        <BigArrow direction={"right"} />
      ) : (
        <>{children}</>
      )}
    </motion.button>
  );
};

export default QuestionnaireFooterButton;
