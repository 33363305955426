import { Conclusion } from "../../lib/models/Conclusion";
import { FullTestResult } from "../../lib/models/test";
import { http } from "../index";

export const getTestsByUserId = async (userId: number) => {
  const response = await http.get(`/api/patients/${userId}/tests`);
  return response.data;
};

export const getTestById = async (testId: number) => {
  return await http.get(`/api/tests/${testId}`);
};

export const getTestConclusion = async (testId: number) => {
  const response = await http.get<Conclusion>(`/api/tests/${testId}/conclusion`);
  return response.data;
};

export const bindTest = async ({ testSerialNumber }: { testSerialNumber: string }) => {
  const response = await http.post("/api/tests/bind", {
    serial_number: testSerialNumber,
  });
  return response.data;
};
export const changeTestIsViewed = async ({ testId, is_viewed }: { testId: number; is_viewed: boolean }) => {
  const response = await http.put(`/api/tests/${testId}/is_viewed`, {
    is_viewed: is_viewed,
  });
  return response.data;
};
export const testIsViewed = async (testId: number) => {
  const response = await http.get<{ is_viewed: boolean }>(`/api/tests/${testId}/is_viewed`);
  return response.data;
};

export const getTestResult = async (testId: number) => {
  const response = await http.get(`/api/tests/${testId}/result`);
  return response.data.result as FullTestResult;
};

export const getTestResultPdfFile = async (testId: string | number) => {
  return await http.get(`/api/tests/${testId}/result/pdf`, {
    responseType: "blob",
  });
};
