import React, { createContext, useEffect, useState } from "react";
import { createSearchParams, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { shallow } from "zustand/shallow";

import { http } from "../API";
import { useDoctorStore } from "../store/useDoctorStore";
import { usePatientStore } from "../store/usePatientStore";
import { useTestStore } from "../store/useTestStore";
import { Patient } from "./models/Pateint";
import { Test } from "./models/test";
import { User } from "./models/User";

type ReviewPatientProviderContextType = {
  user: User;
};

export const ReviewPatientProviderContext = createContext<ReviewPatientProviderContextType | null>(null);

const ReviewPatientProvider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { reviewPatient, setReviewPatient, setPatients } = useDoctorStore((state) => state, shallow);
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<User | null>(null);
  const fetchTest = useTestStore((state) => state.fetchTest);
  const { setMainTest } = usePatientStore((state) => state);

  useEffect(() => {
    if (reviewPatient?.patient) {
      setUser(reviewPatient.patient);
      fetchTest(reviewPatient.test.id);
    }
  }, [reviewPatient]);

  const _fetchPatients = async () => {
    try {
      const response = await http.get("/api/patients/");
      const patients: Patient[] = response.data;
      setPatients(patients);
      const patient = patients.find((e) => e.id === Number(searchParams.get("patient")));
      if (patient) {
        const foundTest: Test | undefined = patient.tests.find((test) => test.id === Number(searchParams.get("test")));
        if (foundTest) {
          setMainTest(foundTest);
          setReviewPatient({
            patient: patient,
            test: foundTest,
          });
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (e) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (reviewPatient) {
      window.history.pushState(
        "",
        "",
        `${window.location.pathname}?${createSearchParams({
          patient: String(reviewPatient.patient.id),
          test: String(reviewPatient.test.id),
        }).toString()}`
      );
    } else if (searchParams.get("patient") && searchParams.get("test")) {
      _fetchPatients();
    } else {
      navigate("/");
    }
  }, [reviewPatient, searchParams, location]);

  return (
    <>
      {user ? (
        <ReviewPatientProviderContext.Provider value={{ user }}>
          <Outlet />
        </ReviewPatientProviderContext.Provider>
      ) : null}
    </>
  );
};

export default ReviewPatientProvider;
