import React, { FC } from "react";

type Props = {
  isActive?: boolean;
};

export const KnowledgeIcon: FC<Props> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9807 20.3653C11.1743 19.7704 10.3076 19.3108 9.38073 18.9865C8.45379 18.6621 7.49353 18.5 6.49995 18.5C5.68328 18.5 4.85028 18.6208 4.00093 18.8624C3.15158 19.1041 2.31793 19.4486 1.5 19.8961V6.83845C2.1923 6.39487 2.99806 6.0609 3.91727 5.83655C4.83649 5.61218 5.69738 5.5 6.49995 5.5C7.47303 5.5 8.42047 5.63783 9.34225 5.91348C10.2641 6.18913 11.1499 6.57695 11.9999 7.07695V18.5615C12.8435 18.0282 13.7336 17.6346 14.6701 17.3807C15.6067 17.1269 16.5499 17 17.4999 17C18.0743 17 18.665 17.0532 19.2721 17.1596C19.8791 17.266 20.4551 17.416 21 17.6096V6.11925C21.1923 6.18335 21.4439 6.28463 21.7548 6.42308C22.0656 6.56154 22.314 6.7 22.4999 6.83845V19.8961C21.682 19.4486 20.8483 19.1041 19.999 18.8624C19.1496 18.6208 18.3166 18.5 17.4999 18.5C16.4999 18.5 15.5301 18.6573 14.5903 18.972C13.6506 19.2868 12.7807 19.7512 11.9807 20.3653ZM13.9999 16V7L19 2V11.5L13.9999 16ZM10.5 17.8365V7.9846C9.87306 7.67435 9.2237 7.43268 8.5519 7.2596C7.8801 7.08652 7.19612 6.99997 6.49995 6.99997C5.88328 6.99997 5.27207 7.06152 4.6663 7.1846C4.06052 7.30767 3.50507 7.48587 2.99995 7.7192V17.625C3.54482 17.434 4.10796 17.282 4.68937 17.1692C5.27077 17.0564 5.8743 17 6.49995 17C7.2474 17 7.96151 17.0772 8.64227 17.2317C9.32306 17.3862 9.94229 17.5878 10.5 17.8365Z"
        fill={isActive ? "#6100FF" : "white"}
      />
    </svg>
  );
};
