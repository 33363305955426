export enum PrimaryColors {
  _900 = "#6100FF",
  _850 = "#6d15ff",
  _800 = "#7929ff",
  _650 = "#863cff",
  _600 = "#9857ff",
  _550 = "#a066fe",
  _500 = "#aa77ff",
  _450 = "#b789ff",
  _400 = "#c49dff",
  _350 = "#cfb2ff",
  _300 = "#dbc5ff",
  _250 = "#e7d9ff",
  _200 = "#f3eaff",
  _150 = "#f9f5ff",
}
