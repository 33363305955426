import { useCallback, useMemo, useState } from "react";

export const useCurrentItemState = <T>({ array, findIndexFn }: { array: T[]; findIndexFn: (item: T) => number }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const setCurrentIndexFn = useCallback(
    (index: number) => {
      setCurrentIndex(Math.max(0, Math.min(index, array.length - 1)));
    },
    [currentIndex, array]
  );

  const setCurrentItem = useCallback(
    (item: T) => {
      setCurrentIndexFn(findIndexFn ? findIndexFn(item) : 0);
    },
    [currentIndex, array, findIndexFn]
  );
  const currentItem = useMemo(() => array[currentIndex] || null, [currentIndex, array]);
  const nextItem = useMemo(() => array[currentIndex + 1] || null, [currentIndex, array]);
  const prevItem = useMemo(() => array[currentIndex - 1] || null, [currentIndex, array]);
  const isLastItem = useMemo(() => currentIndex === array.length - 1, [currentIndex, array]);
  const isFirstItem = useMemo(() => currentIndex === 0, [currentIndex]);

  const goToNext = useCallback(() => {
    nextItem && setCurrentIndex(currentIndex + 1);
  }, [nextItem, currentIndex]);

  const goToPrev = useCallback(() => {
    prevItem && setCurrentIndex(currentIndex - 1);
  }, [prevItem, currentIndex]);

  return {
    array,
    setCurrentItem,
    isFirstItem,
    isLastItem,
    currentIndex,
    setCurrentIndex: setCurrentIndexFn,
    currentItem,
    nextItem,
    prevItem,
    goToNext,
    goToPrev,
  } as const;
};
