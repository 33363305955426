import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { updatePatient, uploadPhoto } from "../../API/ProfileService";
import { ReactComponent as UploadIcon } from "../../images/backup.svg";
import avatar from "../../images/default_avatar.png";
import { ReactComponent as DeleteIcon } from "../../images/delete_icon.svg";
import { useAuth } from "../../layouts/AuthProvider/AuthProvider";
import { useUser } from "../../lib/hooks/useUser";
import { User } from "../../lib/models/User";
import { useRightBottomPopup } from "../../store/useRightBottomPopup";
import { Button } from "../../UI/Button";
import { Input } from "../../UI/Input";
import { ChangePasswordForm } from "./ChangePasswordForm";
import styles from "./index.module.css";

type Props = {
  setToggle: React.Dispatch<React.SetStateAction<number>>;
};

export const ProfileForm: FC<Props> = ({ setToggle }) => {
  const { fetchUser } = useAuth();
  const [error, setError] = useState<boolean>(false);
  const { user } = useUser();
  const { openPopup } = useRightBottomPopup((state) => state);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<User>();

  useEffect(() => {
    if (user.email) {
      setValue("first_name", user.first_name);
      setValue("last_name", user.last_name);
      setValue("middle_name", user.middle_name);
      setValue("phone_number", user.phone_number);
      setValue("address", user.address);
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      await updatePatient(data);
      fetchUser();
      openPopup("Изменения успешно сохранены");
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const onFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      uploadPhoto({ profile_image: event.target.files[0] }).then(() => {
        fetchUser();
      });
    }
  };

  const deletePhoto = () => {
    uploadPhoto({ profile_image: "" }).then(() => {
      fetchUser();
    });
  };

  const filePickerRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        style={{
          display: "none",
          width: 0,
          height: 0,
          position: "fixed",
          left: 0,
        }}
        ref={filePickerRef}
        type="file"
        onChange={onFile}
        accept="image/*,.png,.jpg"
      />
      <div className={styles.headerContainer}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask
            id="mask0_7908_22776"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_7908_22776)">
            <path
              d="M12 13C12.9667 13 13.7917 12.6583 14.475 11.975C15.1583 11.2917 15.5 10.4667 15.5 9.5C15.5 8.53333 15.1583 7.70833 14.475 7.025C13.7917 6.34167 12.9667 6 12 6C11.0333 6 10.2083 6.34167 9.525 7.025C8.84167 7.70833 8.5 8.53333 8.5 9.5C8.5 10.4667 8.84167 11.2917 9.525 11.975C10.2083 12.6583 11.0333 13 12 13ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V17.85C18.1 16.9667 17.0542 16.2708 15.8625 15.7625C14.6708 15.2542 13.3833 15 12 15C10.6167 15 9.32917 15.2542 8.1375 15.7625C6.94583 16.2708 5.9 16.9667 5 17.85V19Z"
              fill="#1C1B1F"
            />
          </g>
        </svg>
        <h3>Личные данные</h3>
      </div>
      <form key={1} className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label>
          <p>Ваше фото</p>
          <div className={styles.avatarWrapper}>
            <img className={styles.avatar} src={user.profile_image ? user.profile_image : avatar} alt="avatar" />
            <Button
              onClick={() => {
                filePickerRef.current?.click();
              }}
              rightIcon={<UploadIcon width="24" />}
              type="button"
              variant={"primary"}
              size={"medium"}
            >
              Загрузить
            </Button>
            {user.profile_image && (
              <Button
                className={styles.deleteAvatarButton}
                type="button"
                onClick={deletePhoto}
                variant="outlined-gray"
                size="small"
              >
                <DeleteIcon width="16px" />
              </Button>
            )}
          </div>
        </label>
        <label className={styles.labelInput}>
          <p>Фамилия</p>
          <Input placeholder={"Введите вашу фамилию"} register={register("last_name", {})} />
        </label>
        <label className={styles.labelInput}>
          <p>Имя</p>
          <Input
            error={errors.first_name?.type}
            placeholder={"Введите ваше имя"}
            register={register("first_name", {
              required: true,
            })}
          />
        </label>
        <label className={styles.labelInput}>
          <p>Отчество</p>
          <Input placeholder={"Введите ваше отчество"} register={register("middle_name", {})} />
        </label>
        <label>
          <p>Номер телефона</p>
          <Input type="tel" register={register("phone_number", { required: true })} />
        </label>
        <label className={styles.labelInput}>
          <p>Адрес</p>
          <Input placeholder={"Введите ваш адрес: Город, Улица, Дом"} register={register("address", {})} />
        </label>

        <div className={styles.buttons}>
          <div></div>
          <Button className={styles.saveButton} type="submit" variant="primary" size="large">
            Сохранить
          </Button>
        </div>
      </form>
      <hr />
      <ChangePasswordForm setToggle={setToggle} />
    </>
  );
};
