import React, { FC, useState } from "react";

import { ReactComponent as DeleteIcon } from "../../../../images/delete_icon.svg";
import { ReactComponent as PaperAndPen } from "../../../../images/paperWithPen.svg";
import useWindowSize from "../../../../lib/hooks/useWindowSize";
import { Button } from "../../../../UI/Button";
import styles from "./index.module.css";

type Props = {
  onSave?: (value: string) => void;
  onClose?: () => void;
  question?: string;
};

const OtherInput: FC<Props> = ({ onSave, question, onClose }) => {
  const [value, setValue] = useState<string>("");
  const { width } = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PaperAndPen />
        <div>
          <h1>Вариант “Другое”</h1>
          <p>
            {question
              ? `Введите ваш собственный ответ на вопрос “${question}”`
              : "Введите ваш собственный ответ на вопрос"}
          </p>
        </div>
      </div>
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Введите ваш текст"
        className={styles.textarea}
      />
      <div className={styles.footer}>
        <button onClick={() => setValue("")} className={styles.cleanButton}>
          <DeleteIcon fill="#6100FF" />
          {width > 988 && " Очистить"}
        </button>
        <div>
          <Button variant={"outlined"} size={"large"} onClick={onClose} type={"button"}>
            Отмена
          </Button>
          <Button
            variant={"primary"}
            onClick={
              onSave &&
              (() => {
                onSave(value);
                onClose && onClose();
              })
            }
            size={"large"}
            type={"button"}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OtherInput;
