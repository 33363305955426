import React, { FC, useCallback } from "react";

import { RegisteredToastMessage, ToastMessage } from "../../lib/models/ToastMessage";
import ToastPopup from "../../UI/ToastPopup";

type ToastContextType = {
  toast: ({ message }: { message: ToastMessage }) => void;
};

const ToastContext = React.createContext<ToastContextType | null>(null);

export const useToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

type Props = {
  children: React.ReactNode;
};
const ToastProvider: FC<Props> = ({ children }) => {
  const [messages, setMessages] = React.useState<RegisteredToastMessage[]>([]);

  const toast = useCallback(
    ({ message }: { message: ToastMessage }) => {
      const registeredMessage: RegisteredToastMessage = {
        id: new Date().getTime(),
        ...message,
      };
      setMessages([...messages, registeredMessage]);
    },
    [messages]
  );

  const closeToast = useCallback(
    (id: number) => {
      setMessages(messages.filter((message) => message.id !== id));
    },
    [messages]
  );

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      {messages.map((message) => (
        <ToastPopup key={message.id} message={message} closeToast={closeToast} />
      ))}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
