import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";

import { useFetchMainTestIsViewed, useFetchMainTestResult, useFetchPatientTests } from "../../queries/tests";

const PatientProvider = () => {
  const { isLoading: areTestsLoading } = useFetchPatientTests();
  const { isLoading: isLoadingTestResult } = useFetchMainTestResult();
  const { isLoading: isLoadingTestIsViewed } = useFetchMainTestIsViewed();

  const isLoading = useMemo(
    () => areTestsLoading || isLoadingTestResult || isLoadingTestIsViewed,
    [areTestsLoading, isLoadingTestResult, isLoadingTestIsViewed]
  );

  return <>{isLoading ? null : <Outlet />}</>;
};

export default PatientProvider;
