import { FC, useEffect, useMemo, useRef } from "react";
import React from "react";

import { useAsideContext } from "../../generic/providers/AsideProvider";
import { useAsideData } from "../../lib/hooks/useAsideData";
import AsideElement from "../AsideElement/index.";
import AsideHeader from "./AsideHeader";
import { CloseIcon } from "./assets/closeIcon";
import styles from "./index.module.css";

type Props = {
  onClose?: () => void;
  isPopup?: boolean;
};

export const Aside: FC<Props> = ({ onClose, isPopup }) => {
  const asideRef = useRef<HTMLDivElement | null>(null);
  const asideHeaderPortalRef = useRef<HTMLDivElement | null>(null);
  const { setAsideElement, setAsideHeaderPortalElement, setTestPickerPopupIsOpen } = useAsideContext();
  const { nodes } = useAsideData(setTestPickerPopupIsOpen);
  const topSections = useMemo(() => nodes.filter((e) => e.position === undefined), [nodes]);
  const bottomSections = useMemo(() => nodes.filter((e) => e.position === "bottom"), [nodes]);

  useEffect(() => {
    if (asideRef.current && asideHeaderPortalRef.current) {
      setAsideElement(asideRef.current);
      setAsideHeaderPortalElement(asideHeaderPortalRef.current);
    }
    return () => {
      setAsideElement(null);
      setAsideHeaderPortalElement(null);
    };
  }, []);

  return (
    <div ref={asideRef} className={styles.containerWrapper}>
      {!isPopup && <div ref={asideHeaderPortalRef} />}
      <div className={styles.container} style={isPopup ? {} : { borderRadius: "24px" }}>
        {onClose && (
          <button className={styles.closeIcon} onClick={onClose}>
            <CloseIcon />
          </button>
        )}
        <AsideHeader />
        <div className={styles.navContainer}>
          {topSections.map((e, index) => (
            <AsideElement onClick={onClose} key={"topAside" + index} node={e} />
          ))}
        </div>

        {bottomSections.length !== 0 && (
          <div className={`${styles.navContainer} ${styles.bottomNavContainer}`}>
            {bottomSections.map((e, index) => (
              <AsideElement onClick={onClose} key={"bottomAside" + index} node={e} />
            ))}
          </div>
        )}
        <div className={styles.bottomCaption}>
          <a target="_blank" rel="noreferrer" href="https://intc-sirius.ru/">
            Резидент Сириуса
          </a>
        </div>
      </div>
    </div>
  );
};
