import React, { FC } from "react";

import SaluteHeader from "../../../components/SaluteHeader";
import { Button } from "../../../UI/Button";
import OverlayingPopup from "../../../UI/OverlayingPopup";
import styles from "./index.module.css";

type QuestionnaireFinishProps = {
  onClose: () => void;
  sectionName?: string;
  isOpen: boolean;
  sectionFinishVariant?: boolean;
};

const QuestionnaireFinish: FC<QuestionnaireFinishProps> = ({
  onClose,
  sectionName,
  isOpen,
  sectionFinishVariant = true,
}) => {
  return (
    <OverlayingPopup isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.salute}>
          <SaluteHeader />
        </div>
        {sectionFinishVariant ? (
          <>
            {sectionName ? (
              <h1>🎉 Поздравляем! Блок “{sectionName}” завершен!</h1>
            ) : (
              <h1>🎉 Поздравляем! Блок завершен!</h1>
            )}
            <p>Ответьте на остальные вопросы в других блоках, чтобы получить более точные персональные рекомендации</p>
          </>
        ) : (
          <>
            <h1>🎉 Спасибо, что заполнили всю анкету!</h1>
            <p>
              Это поможет нам подстроить рекомендации персонально под вас и адаптировать их под привычный вам образ
              жизни!
            </p>
          </>
        )}
        <Button onClick={onClose} size="large" variant="primary">
          Отлично
        </Button>
      </div>
    </OverlayingPopup>
  );
};

export default QuestionnaireFinish;
