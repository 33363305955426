import React, { FC } from "react";

import { ReactComponent as Beta } from "../../images/beta.svg";
import styles from "./index.module.css";

type Props = {
  isActive?: boolean;
};
const MenuGenerationAsideCaption: FC<Props> = () => {
  return (
    <div className={styles.container}>
      Генератор меню
      <span className={styles.betaCaption}>
        <Beta />
      </span>
    </div>
  );
};

export default MenuGenerationAsideCaption;
