import React, { FC } from "react";

import { useScreenType } from "../../../lib/hooks/useScreenType";
import Drawer from "../../../UI/Drawer";
import OverlayingPopup from "../../../UI/OverlayingPopup";
import OtherInput from "../QuestionnaireInputs/OtherInput";
import styles from "./index.module.css";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
};
const OtherAnswerPopup: FC<Props> = ({ isOpen, onClose, onSave }) => {
  const screenType = useScreenType();

  if (screenType === "mobile") {
    return (
      <Drawer isOpen={isOpen} onClose={onClose}>
        <div className={styles.mobileWrapper}>
          <OtherInput onSave={onSave} onClose={onClose} />
        </div>
      </Drawer>
    );
  }
  return (
    <OverlayingPopup isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <OtherInput onSave={onSave} onClose={onClose} />
        </div>
      </div>
    </OverlayingPopup>
  );
};

export default OtherAnswerPopup;
