import React from "react";

import QuestionnaireWidget from "../../features/questionnaire/QuestionnaireWidget";
import styles from "./index.module.css";

const QuestionnairePage = () => {
  return (
    <div className={styles.container}>
      <QuestionnaireWidget />
    </div>
  );
};

export default QuestionnairePage;
