import Cleave from "cleave.js/react";
import { motion } from "framer-motion";
import React, { FC } from "react";
import { DateObject } from "react-multi-date-picker";

import CustomDatePicker from "../../../../components/CustomDatePicker";
import { ReactComponent as CalendarIcon } from "../../../../images/questionnaireCalendar.svg";
import { useQuestionnaireState } from "../../../../lib/hooks/useQuestionnaireState";
import { Question } from "../../../../lib/models/questionnaire";
import styles from "./index.module.css";

type Props = {
  question: Question;
};

const variants = {
  hidden: {
    scale: 0,
  },
  show: (index: number) => ({
    scale: 1,
    transition: { delay: index * 0.1 },
  }),
};

const isValidCleaverDate = (date: string): Date | undefined => {
  const _date = date.split(" / ").reverse().join("/");
  if (_date.length !== 10) return;
  const timestamp = Date.parse(_date);
  if (isNaN(timestamp)) {
    return;
  }
  return new Date(timestamp);
};

const convertJSDateToRussianDate = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }
  return new DateObject(date).toString().split("/").reverse().join(".");
};

const toCleaverDateString = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }
  return convertJSDateToRussianDate(date).replace(".", " / ");
};

const dateToISODateString = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }
  const jsonDate = JSON.stringify(date);
  return String(JSON.parse(jsonDate || "")) || "";
};

const ISOStringToDate = (date: string | undefined): Date | undefined => {
  if (!date || !date.length) {
    return;
  }
  const timestamp = Date.parse(date);
  if (isNaN(timestamp)) {
    return;
  }
  return new Date(date);
};

const DateForm: FC<Props> = ({ question }) => {
  const {
    state: [value, setValue],
  } = useQuestionnaireState<undefined | string>({
    question,
    sendFormat: (value) => convertJSDateToRussianDate(ISOStringToDate(value)),
  });

  const onDateInputChange = (date: string) => {
    if (!date.length) {
      setValue(undefined);
    }
    const validDate = isValidCleaverDate(date);
    if (validDate) {
      setValue(dateToISODateString(new Date(validDate.getTime() + 3 * 60 * 60 * 1000)));
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <motion.div variants={variants} initial="hidden" animate="show" className={styles.pickerWrapper}>
          <div
            className={styles.backgroundIcon}
            style={{ backgroundImage: `url(${question.markup.iconBackgroundUrl})` }}
          />
          <div className={styles.inputWrapper}>
            <CalendarIcon />
            <Cleave
              placeholder={convertJSDateToRussianDate(new Date()).replaceAll(".", " / ")}
              className={styles.input}
              value={toCleaverDateString(new Date(value || ""))}
              onChange={(e) => {
                onDateInputChange(e.target.value);
              }}
              options={{
                date: true,
                delimiter: " / ",
                datePattern: ["d", "m", "Y"],
              }}
            />
            <CalendarIcon opacity={0} />
          </div>
          <CustomDatePicker
            value={ISOStringToDate(value) || new Date()}
            onChange={(date) => {
              setValue(dateToISODateString(new Date(date.getTime() + 3 * 60 * 60 * 1000)));
            }}
            defaultValue={ISOStringToDate(value) || new Date()}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default DateForm;
