import React from "react";
import { Link } from "react-router-dom";

import { Logo } from "../../../../images/logo";
import styles from "./NovabiomAsideHeaderVariant.module.css";

const NovabiomAsideHeaderVariant = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Link to="/">
          <Logo white={true} />
        </Link>
      </div>
    </div>
  );
};

export default NovabiomAsideHeaderVariant;
