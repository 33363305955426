import { useEffect, useState } from "react";

type ScreenTypes = "mobile" | "desktop" | "tablet" | string;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function getScreenType({ width, widthBreakpoints }: { width: number; widthBreakpoints: Record<number, ScreenTypes> }) {
  const breakpoints = Object.keys(widthBreakpoints).map((e) => Number(e));

  let newScreenType: ScreenTypes = "desktop";

  for (const breakpoint of breakpoints) {
    if (width <= breakpoint) {
      newScreenType = widthBreakpoints[breakpoint];
      break;
    }
  }
  return newScreenType;
}

export const useScreenType = (widthBreakpoints: Record<number, ScreenTypes> = { 988: "mobile" }) => {
  const [screenType, setScreenType] = useState<ScreenTypes>(
    getScreenType({ width: getWindowDimensions().width, widthBreakpoints })
  );
  useEffect(() => {
    function handleResize() {
      const { width } = getWindowDimensions();

      setScreenType(getScreenType({ width, widthBreakpoints }));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenType;
};
