import { useContext } from "react";

import { OrgUserProviderContext } from "../../generic/providers/OrgUserProvider";
import { useAuth } from "../../layouts/AuthProvider/AuthProvider";
import { User } from "../models/User";
import { ReviewPatientProviderContext } from "../ReviewPatientProvider";

type ContextType = "org_user" | "patient" | "global";

export const useUser = (getGlobalUser = false): { user: User; context: ContextType } => {
  let user = useAuth().user;

  if (getGlobalUser) {
    if (!user) {
      throw new Error("User not found");
    }
    return { user, context: "global" };
  }

  const orgUserContext = useContext(OrgUserProviderContext);
  const reviewPatientProviderContext = useContext(ReviewPatientProviderContext);
  if (orgUserContext) {
    user = orgUserContext.user;
  }
  if (reviewPatientProviderContext) {
    user = reviewPatientProviderContext.user;
  }
  if (!user) {
    throw new Error("User not found");
  }
  if (orgUserContext) {
    return { user: orgUserContext.user, context: "org_user" };
  }
  if (reviewPatientProviderContext) {
    return { user: reviewPatientProviderContext.user, context: "patient" };
  }
  return { user, context: "global" };
};
