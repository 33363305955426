import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { ORG_USER_ACCESS_TOKEN } from "../lib/consts/consts";
import { OrgUser } from "../lib/models/OrgUser";

type OrgStore = {
  selectedOrgUser: OrgUser | null;
  setSelectedOrgUser: (orgUser: OrgUser | null) => void;
};

export const useOrgStore = create<OrgStore>()(
  devtools(
    (set, get) => ({
      selectedOrgUser: null,
      setSelectedOrgUser: (orgUser) => {
        if (orgUser) {
          sessionStorage.setItem(ORG_USER_ACCESS_TOKEN, orgUser.access);
        } else {
          sessionStorage.removeItem(ORG_USER_ACCESS_TOKEN);
        }
        set({ selectedOrgUser: orgUser });
      },
    }),
    { name: "OrgStore" }
  )
);
