import React, { useEffect } from "react";
import { pdfjs } from "react-pdf";

import { reportMetrica } from "../../API/MetricaService";
import { getTestResultPdfFile } from "../../API/PatientService";
import PDFViewer from "../../components/PDFViewer";
import { useHideBodyScrollBar } from "../../lib/hooks/useHideBodyScrollBar";
import { hideChat } from "../../lib/utils/hideChat";
import { usePdfQuery } from "../../queries/tests";
import { usePatientStore } from "../../store/usePatientStore";
import styles from "./index.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReportPage = () => {
  const { mainTest } = usePatientStore((state) => state);
  const { data, error: pdfFetchError } = usePdfQuery(mainTest?.id);

  const reportYandexMetrica = async () => {
    if (mainTest?.id) {
      try {
        await reportMetrica(mainTest.id);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onDownloadClick = async (testId?: number) => {
    if (!testId) return;
    const response = await getTestResultPdfFile(testId);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", "results.pdf");
    tempLink.click();
  };

  useHideBodyScrollBar();

  useEffect(() => {
    reportYandexMetrica();
    hideChat();
  }, [mainTest]);

  return (
    <div className={styles.container}>
      <PDFViewer onDownload={() => onDownloadClick(mainTest?.id)} file={data?.data} fetchError={!!pdfFetchError} />
    </div>
  );
};

export default ReportPage;
