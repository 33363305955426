import React, { FC } from "react";

import Popover, { PopoverProps } from "../../UI/Popover";
import PatientConsultations from "../PatientConsultations";
import styles from "./index.module.css";

type Props = Omit<PopoverProps, "children">;

const ConsultationsPopover: FC<Props> = (props) => {
  return (
    <Popover {...props} modifiers={[{ name: "offset", options: { offset: [0, 10] } }]}>
      <div className={styles.container}>
        <PatientConsultations />
      </div>
    </Popover>
  );
};

export default ConsultationsPopover;
