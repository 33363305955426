import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Arrow } from "../../images/arrows/orgPatientPickerArrow.svg";
import { OrgUrls } from "../../lib/routes";
import { useOrgStore } from "../../store/useOrgStore";
import styles from "./OrgPatientPicker.module.css";

const OrgPatientPicker = () => {
  const { selectedOrgUser } = useOrgStore((state) => state);
  const location = useLocation();
  const isActive = useMemo(() => {
    return location.pathname === OrgUrls.ORG_PATIENTS;
  }, [location]);
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <p>Выбор клиента:</p>
      <button
        onClick={() => navigate(OrgUrls.ORG_PATIENTS)}
        className={`${styles.picker} ${isActive ? styles.activeStatus : styles.notActiveStatus}`}
      >
        <span>{selectedOrgUser ? selectedOrgUser.test.serial_number : "Выбрать клиента"}</span>
        <Arrow />
      </button>
    </div>
  );
};

export default OrgPatientPicker;
