import axios from "axios";

import { ACCESS_TOKEN } from "../lib/consts/consts";

export const baseURL: string = process.env.REACT_APP_URL || "";

export const publicHttp = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const http = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const axiosInterceptorsAddHeader = http.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  return config;
});
