import { Consultation } from "../../lib/models/Consultation";
import { Timetable, TimetableTimeslot } from "../../lib/models/Timetable";
import { http } from "../index";

export const getConsultations = async () => {
  const response = await http.get<Consultation[]>("/api/consultations/");
  return response.data;
};

export const getConsultation = async (consultationId: string | number) => {
  return await http.get(`/api/consultations/${consultationId}/`);
};

export const updateConsultationDescription = async (
  consultationId: string | number,
  description: string,
  status: string
) => {
  return await http.patch(`/api/consultations/${consultationId}/`, {
    description,
    status,
  });
};

export const getDoctorFreeTimeSlots = async (doctorId: number) => {
  return await http.get("/api/free_timeslots", {
    params: { doctor_id: doctorId },
  });
};

export const getSingleTimeslots = async () => {
  return await http.get("/api/timeslots");
};

export const addSingleTimeSlots = async (doctorId: number, timeslots: TimetableTimeslot[]) => {
  return await http.post(
    "/api/timeslots",
    { timeslots: timeslots },
    {
      params: { doctor_id: doctorId },
    }
  );
};

export const deleteSingleTimeSlotById = async (timeslotId: number) => {
  return await http.delete(`/api/timeslots/${timeslotId}`);
};

export const updateDoctorTimeTableByDoctorId = async (doctorId: number, data: Timetable) => {
  return await http.patch(`/api/doctor/${doctorId}/timetable`, data);
};

export const getTimeTable = async (doctorId: number) => {
  return await http.get(`/api/doctor/${doctorId}/timetable`);
};

export const createTimeTable = async (data: Timetable) => {
  return await http.post("/api/doctor/timetable", data);
};

export const createConsultation = async (day: string, time: string, doctorId: number, is_regular: boolean) => {
  return await http.post("/api/consultations/", {
    day: day,
    time: time,
    user: doctorId,
    is_regular: is_regular,
  });
};

export const changeConsultationLink = async (consultationId: number, link: string) => {
  return await http.patch(`/api/consultations/${consultationId}/`, {
    link: link,
  });
};

export const deleteConsultation = async (consultationId: number) => {
  return await http.delete(`/api/consultations/${consultationId}/`);
};
