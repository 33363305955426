import React, { FC } from "react";

import { useAsideContext } from "../../generic/providers/AsideProvider";
import CalendarAsideIcon from "../../images/CalendarAsideIcon";
import { useScreenType } from "../../lib/hooks/useScreenType";
import { useFetchPatientConsultations } from "../../queries/patient/consultations";
import { usePatientStore } from "../../store/usePatientStore";
import Drawer from "../../UI/Drawer";
import ConsultationsPopover from "../ConsultationsPopover";
import PatientConsultations from "../PatientConsultations";
import styles from "./index.module.css";

type Props = {
  variant?: "white" | "black";
};

const AsideConsultationsButton: FC<Props> = ({ variant = "white" }) => {
  const { data: consultations } = useFetchPatientConsultations();
  const { setConsultationsPopupIsOpen, consultationsPopupIsOpen, setAsideAsPopupIsOpen } = useAsideContext();
  const screenType = useScreenType();
  const { asideElement } = useAsideContext();

  return (
    <>
      <div
        className={styles.cart}
        onClick={() => {
          setConsultationsPopupIsOpen(true);
          setAsideAsPopupIsOpen(false);
        }}
      >
        <div>
          <CalendarAsideIcon variant={variant} popupIsOpen={consultationsPopupIsOpen} />
        </div>
        <div className={styles.cartLabel}>{consultations.filter((c) => c.status !== "проведена").length}</div>
      </div>
      {screenType === "mobile" ? (
        <Drawer
          isOpen={consultationsPopupIsOpen}
          onClose={() => setConsultationsPopupIsOpen(!consultationsPopupIsOpen)}
        >
          <PatientConsultations />
        </Drawer>
      ) : (
        <>
          {asideElement && (
            <ConsultationsPopover
              isOpen={consultationsPopupIsOpen}
              onClose={() => setConsultationsPopupIsOpen(false)}
              reference={asideElement}
              placement="right-start"
            />
          )}
        </>
      )}
    </>
  );
};

const AsideConsultationsButtonProvider: FC<Props> = (props) => {
  const { isTestReady } = usePatientStore((state) => state);
  return <>{isTestReady && <AsideConsultationsButton {...props} />}</>;
};

export default AsideConsultationsButtonProvider;
