import React, { FC, useContext, useMemo } from "react";

import { useCurrentItemState } from "../../../lib/hooks/useCurrentItemState";
import { Question, Section } from "../../../lib/models/questionnaire";
import QuestionnairePassing from "./index";

type SectionPassingContextType = {
  onFinishButton: () => void;
  section: Section;
  questions: Question[];
  currentQuestion: Question;
  nextQuestion: Question | null;
  prevQuestion: Question | null;
  sectionReadyButtonIsActive: boolean;
  displayNumbers: string[];
  requiredVersion: boolean;
  setCurrentQuestion: (question: Question) => void;
  goToNextQuestion: () => void;
  goToPrevQuestion: () => void;
  isLastQuestion: boolean;
  currentIndex: number;
};

const SectionPassingContext = React.createContext<SectionPassingContextType | null>(null);

export const useSectionPassingContext = () => {
  const context = useContext(SectionPassingContext);

  if (!context) {
    throw new Error("useQuestionnairePassingContext must be used within a QuestionnaireSectionPassingProvider");
  }

  return context;
};

type SectionPassingProviderProps = {
  section: Section;
  questions: Question[];
  requiredVersion?: boolean;
  nextSection?: Section;
  onSectionReadyButton?: () => boolean;
  sectionReadyButtonIsActive?: boolean;
  onFinishPopupSuccessButton?: () => void;
};

const SectionPassingProvider: FC<SectionPassingProviderProps> = ({
  section,
  questions,
  requiredVersion = false,
  onSectionReadyButton,
  sectionReadyButtonIsActive = true,
}) => {
  const {
    currentItem: currentQuestion,
    prevItem: prevQuestion,
    nextItem: nextQuestion,
    currentIndex: currentIndex,
    goToNext: goToNextQuestion,
    setCurrentItem: setCurrentQuestion,
    goToPrev: goToPrevQuestion,
    isLastItem: isLastQuestion,
  } = useCurrentItemState<Question>({
    array: questions,
    findIndexFn: (item) => {
      return questions.findIndex((e) => e.question_id === item.question_id);
    },
  });

  const onFinishButton = () => {
    onSectionReadyButton && onSectionReadyButton();
  };

  const displayNumbers = useMemo(() => {
    const numbers: string[] = [];
    questions.forEach((question) => {
      const lastCalculatedNumber =
        numbers
          .at(-1)
          ?.split(".")
          .map((e) => Number(e)) || [];
      if (lastCalculatedNumber.length) {
        if (!question.markup.show_if) {
          numbers.push(String(lastCalculatedNumber[0] + 1));
        } else {
          const subNumber = lastCalculatedNumber[1] ? lastCalculatedNumber[1] + 1 : 1;
          numbers.push(`${lastCalculatedNumber[0]}.${subNumber}`);
        }
      } else {
        numbers.push("1");
      }
    });
    return numbers;
  }, [questions]);

  if (!currentQuestion) {
    return null;
  }

  return (
    <SectionPassingContext.Provider
      value={{
        displayNumbers,
        onFinishButton,
        currentIndex,
        isLastQuestion,
        setCurrentQuestion,
        goToPrevQuestion,
        goToNextQuestion,
        sectionReadyButtonIsActive,
        section,
        nextQuestion,
        prevQuestion,
        currentQuestion,
        requiredVersion,
        questions,
      }}
    >
      <QuestionnairePassing requiredVersion={requiredVersion} />
    </SectionPassingContext.Provider>
  );
};

export default SectionPassingProvider;
