import { create } from "zustand";

import { http } from "../API";
import { Patient } from "../lib/models/Pateint";
import { ReviewPatient } from "../lib/models/ReviewPatient";

type PatientStore = {
  reviewPatient: ReviewPatient | null;
  setReviewPatient: (reviewPatient: ReviewPatient | null) => void;
  patients: Patient[];
  setPatients: (patients: Patient[]) => void;
  fetchPatients: () => void;
  patientsAreLoading: boolean;
  patientsError: string;
};

export const useDoctorStore = create<PatientStore>()((set, get) => ({
  reviewPatient: null,
  setReviewPatient: (reviewPatient) => set((state) => ({ reviewPatient })),
  patients: [],
  patientsAreLoading: false,
  patientsError: "",
  setPatients: (patients) => set((state) => ({ patients })),
  fetchPatients: async () => {
    try {
      set({ patientsAreLoading: true });
      const response = await http.get("/api/patients/");
      set({ patients: response.data });
      return response;
    } catch (e) {
      set({ patientsError: "error" }); // TODO: заменить на настоящую ошибку
    } finally {
      set({ patientsAreLoading: false });
    }
  },
}));
