import React, { FC, ReactElement, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AsideNode } from "../../lib/hooks/useAsideData";
import styles from "./index.module.css";

type Props = {
  node: AsideNode;
  onClick?: () => void;
  isChildNode?: boolean;
};

const startWithCheck = (path: string, currentPath: string) => {
  return currentPath.startsWith(path);
};

const fullMatchCheck = (path: string, currentPath: string) => {
  return currentPath === path;
};

const AsideElement: FC<Props> = ({ node, onClick, isChildNode = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = useMemo(() => {
    switch (node.isActiveStrategy) {
      case "pathnameStartWith": {
        return startWithCheck(node.link || "", location.pathname);
      }
      case "pathnameFullMatch": {
        return fullMatchCheck(node.link || "", location.pathname);
      }
      default: {
        return fullMatchCheck(node.link || "", location.pathname);
      }
    }
  }, [location.pathname, node.link]);

  if (isChildNode) {
    return (
      <Link className={`${styles.children} ${isActive && styles.childrenActive}`} to={node.link || "#"}>
        {node.caption}
      </Link>
    );
  }

  return (
    <>
      <div onClick={onClick} className={styles.container}>
        <div className={styles.containerParent}>
          <button
            className={`${styles.parent} ${isActive && styles.parentActive}`}
            onClick={() => {
              node.onClick && node.onClick();
              if (node.link) {
                navigate(node.link);
              }
            }}
          >
            <div>
              {React.isValidElement(node.icon) && React.cloneElement(node.icon as ReactElement, { isActive: isActive })}
            </div>
            {typeof node.caption === "string" ? (
              <>{node.caption}</>
            ) : (
              <>
                {React.isValidElement(node.caption) &&
                  React.cloneElement(node.caption as ReactElement, { isActive: isActive })}
              </>
            )}
            <div>
              {React.isValidElement(node.rightBlock) &&
                React.cloneElement(node.rightBlock as ReactElement, { isActive: isActive })}
            </div>
          </button>
        </div>
        {node.children?.length && (
          <div className={styles.containerChildren}>
            {node.children.map((e, index) => (
              <AsideElement node={e} key={index} isChildNode={true} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AsideElement;
