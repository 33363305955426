import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { computed } from "zustand-computed";

import { Test } from "../lib/models/test";

interface patientState {
  initialLoaded: boolean;
  tests: Test[];
  mainTest: Test | null;
  setTests: (tests: Test[]) => void;
  // bindTest: (testSerialNumber: string) => void;
  bindTestError: string | null;
  setMainTestById: (id: number | string) => void;
  setMainTest: (test: Test | null) => void;
  testPicker: boolean;
  mainTestIsViewed: boolean;
  setMainTestIsViewed: (value: boolean) => void;
  setTestPicker: (bool: boolean) => void;
  initialDataIsLoading: boolean;
  setInitialDataIsLoading: (value: boolean) => void;
}

interface patientComputedState {
  someTestIsReady: boolean;
  needCourierTest: Test | null;
  isTestReady: boolean;
}

const computedState = (state: patientState): patientComputedState => ({
  someTestIsReady: !!state.tests.find((e) => e.status === "ready"),
  needCourierTest: state.tests.find((test) => test.status === "delivered_to_user") || null,
  isTestReady: state.mainTest?.status === "ready",
});

export const usePatientStore = create<patientState>()(
  devtools(
    computed(
      (set, get) => ({
        initialDataIsLoading: false,
        setInitialDataIsLoading: (value: boolean) => {
          set({ initialDataIsLoading: value });
        },
        initialLoaded: false,
        isTestReady: false,
        testPicker: false,
        changeIsViewedLoading: false,
        tests: [],
        mainTest: null,
        changeIsViewedValue: true,
        bindTestError: null,
        testsAreLoading: false,
        setTestPicker: (bool) => set((state) => ({ testPicker: bool })),
        // bindTest: async (testSerialNumber: string) => {
        //   set({ bindTestError: null });
        //   try {
        //     await bindTest(testSerialNumber);
        //     const userId = useUserNewStore.getState().user.id;
        //     const response = await getTestsByUserId(userId);
        //     get().setTests(response.data);
        //     set({ bindTestError: null });
        //     useRightBottomPopup.setState({
        //       popupIsOpen: true,
        //       text: "ID вашего теста сохранен!",
        //     });
        //   } catch (e: any) {
        //     set({ bindTestError: e.response ? e.response.data.detail : e });
        //   }
        // },
        mainTestIsViewed: false,
        setMainTestIsViewed: (value: boolean) => set(() => ({ mainTestIsViewed: value })),
        setMainTest: (test: Test | null) => set(() => ({ mainTest: test })),
        setMainTestById: (id) => set((state) => ({ mainTest: state.tests.find((e) => e.id === id) })),
        setTests: (tests: Test[]) => {
          const { mainTest } = get();
          const sortedTests = tests.sort((a, b) => Date.parse(String(b.date)) - Date.parse(String(a.date)));
          const firstTest = sortedTests.at(0);
          if (firstTest && !mainTest) {
            set({ tests: sortedTests, mainTest: sortedTests.at(0) });
          } else {
            set({ tests: sortedTests });
          }
        },
      }),
      computedState
    ),
    { name: "PatientStore" }
  )
);
