import React, { FC, useEffect } from "react";

import LoginViaTestSerialNumberForm from "../../components/Login/LoginViaTestSerialNumberForm";
import { Logo } from "../../images/logo";
import { hideChat } from "../../lib/utils/hideChat";
import styles from "./index.module.css";

const LoginViaTestSerialNumberPage: FC = () => {
  useEffect(() => {
    hideChat();
  }, []);
  return (
    <div className={styles.container}>
      <header>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.form}>
          <LoginViaTestSerialNumberForm />
        </div>
      </main>
    </div>
  );
};

export default LoginViaTestSerialNumberPage;
