import { motion } from "framer-motion";
import React from "react";

import { useFetchAvailableSections } from "../../../queries/questionnaire";
import QuestionnairesListElement from "../QuestionnairesListElement";
import { useQuestionnaireWidgetContext } from "../QuestionnaireWidget";
import styles from "./index.module.css";

const container = {
  hidden: { opacity: 1, scale: 0 },
  exit: {
    opacity: 0,
    scale: 1,
    x: -100,
    transition: {
      delayChildren: 0.0,
      staggerChildren: 0.1,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const QuestionnairesList = () => {
  const { data: sections } = useFetchAvailableSections();
  const { setCurrentSection, setMenuIsOpen } = useQuestionnaireWidgetContext();

  return (
    <>
      <div>
        <h1 className={styles.h1}>Выбор раздела</h1>
        <motion.ul
          variants={container}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={styles.list}
          key="list"
        >
          {sections.map((e, index) => (
            <motion.li style={{ listStyle: "none" }} variants={item} key={"QuestionnaireElement" + index}>
              <QuestionnairesListElement
                section={e}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setCurrentSection(e);
                  setMenuIsOpen(false);
                }}
              />
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </>
  );
};

export default QuestionnairesList;
