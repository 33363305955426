import React from "react";

const BurgerMenuIcon = ({ big }: { big?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={big ? "40" : "24"}
      height={big ? "40" : "24"}
      viewBox="0 0 40 41"
      fill="none"
    >
      <mask
        id="mask0_12520_10434"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="41"
      >
        <rect y="0.5" width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12520_10434)">
        <path
          d="M5 30.5V27.1667H35V30.5H5ZM5 22.1667V18.8333H35V22.1667H5ZM5 13.8333V10.5H35V13.8333H5Z"
          fill="#6100FF"
        />
      </g>
    </svg>
  );
};

export default BurgerMenuIcon;
