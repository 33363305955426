import { motion } from "framer-motion";
import React, { forwardRef } from "react";

import { ReactComponent as AddIcon } from "../../../images/add.svg";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";
import { ReactComponent as PenIcon } from "../../../images/edit.svg";
import styles from "./index.module.css";

type Props = {
  caption: string;
  selected?: boolean;
  onClick: (answer: string) => void;
  onOtherClick?: () => void;
};

const QuestionnaireMultipleItem = forwardRef<HTMLButtonElement, Props>(
  ({ selected, onOtherClick, caption, onClick }, ref) => {
    return (
      <motion.button
        ref={ref}
        onClick={caption === "Другое" ? onOtherClick : () => onClick(caption)}
        whileHover={{ y: -5, opacity: 1 }}
        whileTap={{ opacity: 0.5, y: 0 }}
        className={`${styles.container} ${selected ? styles.selected : styles.unselected}`}
      >
        <div className={styles.icon}>{selected ? <CheckIcon /> : caption === "Другое" ? <PenIcon /> : <AddIcon />}</div>
        <p>{caption}</p>
      </motion.button>
    );
  }
);

QuestionnaireMultipleItem.displayName = "QuestionnaireMultipleItem";
export default motion(QuestionnaireMultipleItem);
