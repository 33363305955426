import React, { FC, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { useAsideContext } from "../../generic/providers/AsideProvider";
import burger from "../../images/burgerMenu.svg";
import { Logo } from "../../images/logo";
import { ROLE } from "../../lib/consts/consts";
import AsideCartButton from "../AsideCartButton";
import AsideConsultationsButton from "../AsideConsultationsButton";
import styles from "./index.module.css";

type Props = {
  popupVersion?: boolean;
};

export const AsideMobile: FC<Props> = ({ popupVersion = false }) => {
  const role = localStorage.getItem(ROLE);
  const { setAsideMobilePortalElement, setAsideAsPopupIsOpen } = useAsideContext();
  const asideMobileHeaderPortalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!popupVersion) {
      if (asideMobileHeaderPortalRef.current) {
        setAsideMobilePortalElement(asideMobileHeaderPortalRef.current);
      }
      return () => {
        setAsideMobilePortalElement(null);
      };
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <Link className={styles.logoWrapper} to="/">
            <Logo />
          </Link>
          {role === "patient" && (
            <div className={styles.headIcons}>
              <AsideConsultationsButton variant="black" />
              <AsideCartButton variant="black" />
            </div>
          )}
        </div>
        <img src={burger} className={styles.icon} onClick={() => setAsideAsPopupIsOpen(true)} />
      </div>
      <div ref={asideMobileHeaderPortalRef} />
    </>
  );
};
