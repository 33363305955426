import React, { FC, useEffect, useState } from "react";

import checkCircle from "../../images/check_circle.svg";
import errorCross from "../../images/error_cross.svg";
import { RegisteredToastMessage } from "../../lib/models/ToastMessage";
import OverlayingPopup from "../OverlayingPopup";
import styles from "./index.module.css";

type Props = {
  message: RegisteredToastMessage;
  closeToast: (id: number) => void;
};
const ToastPopup: FC<Props> = ({ message, closeToast }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
      closeToast(message.id);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <OverlayingPopup overlayPointerEvents="none" isOpen={isOpen} overlayIsVisible={false} disablePageScroll={false}>
      <div className={`${styles.container} ${styles[`container-${message.type}`]}`}>
        <img src={message.type === "success" ? checkCircle : errorCross} className={styles.image}></img>
        <p>{message.text}</p>
      </div>
    </OverlayingPopup>
  );
};

export default ToastPopup;
