import { useEffect, useState } from "react";

export const useIsTouchScreen = () => {
  const [isTouchScreen, setIsTouchScreen] = useState(false);

  useEffect(() => {
    const checkTouchScreen = () => {
      try {
        document.createEvent("TouchEvent");
        setIsTouchScreen(true);
      } catch (e) {
        setIsTouchScreen(false);
      }
    };

    checkTouchScreen();

    window.removeEventListener("touchstart", checkTouchScreen);
  }, []);

  return isTouchScreen;
};
