import { create } from "zustand";

interface RightBottomPopupState {
  popupIsOpen: boolean;
  switchPopup: () => void;
  closePopup: () => void;
  text: string;
  openPopup: (text: string) => void;
}

export const useRightBottomPopup = create<RightBottomPopupState>((set, get) => ({
  text: "",
  popupIsOpen: false,
  switchPopup: () => {
    set({ popupIsOpen: !get().popupIsOpen });
  },
  closePopup: () => {
    set({ popupIsOpen: false });
  },
  openPopup: (text: string) => {
    set({ popupIsOpen: true, text: text });
  },
}));
