import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useScrollToTopOfPage } from "../../lib/hooks/useScrollToTopOfPage";

type ScrollTopLayoutContextType = {
  disabled: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const ScrollTopLayoutContext = React.createContext<ScrollTopLayoutContextType>({
  disabled: false,
  setDisabled: () => {},
});

export const useDisablePageScrollTop = () => {
  const { setDisabled } = React.useContext(ScrollTopLayoutContext);

  useEffect(() => {
    setDisabled(true);
    return () => {
      setDisabled(false);
      window.scrollTo(0, 0);
    };
  }, []);
};

const ScrollTopLayout = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  useScrollToTopOfPage(disabled);
  return (
    <ScrollTopLayoutContext.Provider value={{ disabled: disabled, setDisabled: setDisabled }}>
      <Outlet />
    </ScrollTopLayoutContext.Provider>
  );
};

export default ScrollTopLayout;
