import { Placement } from "@popperjs/core";
import React, { FC, useEffect, useRef } from "react";
import { Modifier, Popper } from "react-popper";

import Portal from "../Portal";
import styles from "./index.module.css";

export interface PopoverProps {
  onClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  reference: HTMLDivElement;
  placement: Placement;
  modifiers?: Modifier<any, any>[];
}
const Popover: FC<PopoverProps> = ({ children, reference, modifiers, placement, onClose, isOpen }) => {
  const popperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const popoverElement = popperRef.current;
      if (popoverElement && !popoverElement.contains(event.target as Node) && isOpen) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Popper modifiers={modifiers} innerRef={popperRef} referenceElement={reference} placement={placement}>
        {({ ref, style }) => (
          <div ref={ref} style={style} className={styles.container}>
            {children}
          </div>
        )}
      </Popper>
    </Portal>
  );
};

export default Popover;
