import React, { FC } from "react";

type Props = {
  visible: boolean;
  onClick: () => void;
};

const VisibilityIcon: FC<Props> = ({ visible, onClick }) => {
  return (
    <div style={{ height: "24px", width: "24px" }} onClick={onClick}>
      {!visible ? (
        <>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
              id="mask0_12752_10257"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_12752_10257)">
              <path
                d="M12 16C13.25 16 14.3127 15.5627 15.188 14.688C16.0627 13.8127 16.5 12.75 16.5 11.5C16.5 10.25 16.0627 9.18733 15.188 8.312C14.3127 7.43733 13.25 7 12 7C10.75 7 9.68733 7.43733 8.812 8.312C7.93733 9.18733 7.5 10.25 7.5 11.5C7.5 12.75 7.93733 13.8127 8.812 14.688C9.68733 15.5627 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6127 13.9373 10.088 13.412C9.56267 12.8873 9.3 12.25 9.3 11.5C9.3 10.75 9.56267 10.1123 10.088 9.587C10.6127 9.06233 11.25 8.8 12 8.8C12.75 8.8 13.3877 9.06233 13.913 9.587C14.4377 10.1123 14.7 10.75 14.7 11.5C14.7 12.25 14.4377 12.8873 13.913 13.412C13.3877 13.9373 12.75 14.2 12 14.2ZM12 19C9.56667 19 7.35 18.3207 5.35 16.962C3.35 15.604 1.9 13.7833 1 11.5C1.9 9.21667 3.35 7.39567 5.35 6.037C7.35 4.679 9.56667 4 12 4C14.4333 4 16.65 4.679 18.65 6.037C20.65 7.39567 22.1 9.21667 23 11.5C22.1 13.7833 20.65 15.604 18.65 16.962C16.65 18.3207 14.4333 19 12 19Z"
                fill="#151515"
                fillOpacity="0.16"
              />
            </g>
          </svg>
        </>
      ) : (
        <>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2846_18270" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2846_18270)">
              <path
                d="M19.8 22.6L15.6 18.45C15.0167 18.6334 14.4293 18.7711 13.838 18.8631C13.246 18.9544 12.6333 19 12 19C9.48333 19 7.24167 18.3044 5.275 16.913C3.30833 15.521 1.88333 13.7167 1 11.5C1.35 10.6167 1.79167 9.79571 2.325 9.03705C2.85833 8.27905 3.46667 7.60005 4.15 7.00005L1.4 4.20005L2.8 2.80005L21.2 21.2001L19.8 22.6ZM12 16C12.1833 16 12.354 15.9917 12.512 15.975C12.6707 15.9584 12.8417 15.925 13.025 15.875L7.625 10.475C7.575 10.6584 7.54167 10.8294 7.525 10.988C7.50833 11.146 7.5 11.3167 7.5 11.5C7.5 12.75 7.93733 13.8127 8.812 14.688C9.68733 15.5627 10.75 16 12 16ZM19.3 16.45L16.125 13.3C16.2417 13.0167 16.3333 12.7294 16.4 12.438C16.4667 12.146 16.5 11.8334 16.5 11.5C16.5 10.25 16.0627 9.18738 15.188 8.31205C14.3127 7.43738 13.25 7.00005 12 7.00005C11.6667 7.00005 11.354 7.03338 11.062 7.10005C10.7707 7.16672 10.4833 7.26672 10.2 7.40005L7.65 4.85005C8.33333 4.56672 9.03333 4.35405 9.75 4.21205C10.4667 4.07072 11.2167 4.00005 12 4.00005C14.5167 4.00005 16.7583 4.69572 18.725 6.08705C20.6917 7.47905 22.1167 9.28338 23 11.5C22.6167 12.4834 22.1127 13.396 21.488 14.238C20.8627 15.0794 20.1333 15.8167 19.3 16.45ZM14.675 11.85L11.675 8.85005C12.1417 8.76672 12.571 8.80405 12.963 8.96205C13.3543 9.12072 13.6917 9.35005 13.975 9.65005C14.2583 9.95005 14.4627 10.296 14.588 10.688C14.7127 11.0794 14.7417 11.4667 14.675 11.85Z"
                fill="#151515"
                fillOpacity="0.16"
              />
            </g>
          </svg>
        </>
      )}
    </div>
  );
};

export default VisibilityIcon;
