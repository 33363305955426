import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { auth, authViaTestSerialNumber, me } from "../../API/AuthService";
import { useRoleContext } from "../../App";
import { User } from "../../lib/models/User";

interface AuthContextType {
  user: User | null;
  loading: boolean;
  fetchUser: () => void;
  setUser: (user: User | null) => void;
  logout: () => void;
  login: (data: { email: string; password: string }) => Promise<void>;
  loginViaTestSerialNumber: (serialNumber: string) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null
  );
  const [loading, setLoading] = useState(false);
  const { setRole } = useRoleContext();

  useEffect(() => {
    fetchUser();
  }, []);

  const queryClient = useQueryClient();

  const setUserHandler = (user: User | null) => {
    if (user) {
      setUser(user);
      setRole(user.type);
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      setUser(null);
      setRole(null);
      localStorage.removeItem("user");
    }
  };

  const fetchUser = async () => {
    try {
      const _user: User = await me();
      setUserHandler(_user);
    } catch (e) {
      setUser(null);
      setRole(null);
    }
  };

  const login = async (data: { email: string; password: string }) => {
    await auth(data);
    await fetchUser();
  };

  const loginViaTestSerialNumber = async (serialNumber: string) => {
    await authViaTestSerialNumber({ serialNumber });
    await fetchUser();
  };

  const logout = () => {
    setUser(null);
    setRole(null);
    localStorage.removeItem("user");
    localStorage.clear();
    queryClient.clear();
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, fetchUser, setUser: setUserHandler, logout, login, loginViaTestSerialNumber }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
