import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { hideChat } from "../../lib/utils/hideChat";
import { Button } from "../../UI/Button";

export const NotFound: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    hideChat();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
        height: "100%",
      }}
    >
      Страница не найдена
      <Button variant={"primary"} size={"large"} type={"button"} onClick={() => navigate("/")}>
        Вернуться на главную
      </Button>
    </div>
  );
};
