import React, { FC, useState } from "react";

import { ReactComponent as CancelIcon } from "../../images/doNotDisturb.svg";
import { ReactComponent as CancelWhiteIcon } from "../../images/doNotDisturbWhite.svg";
import { ReactComponent as RightArrowIcon } from "../../images/rightBlackArrow.svg";
import { ReactComponent as VideoChat } from "../../images/videoChat.svg";
import { Consultation } from "../../lib/models/Consultation";
import { fromISOtoRussianLocale } from "../../lib/utils/DateUtils";
import { useConsultationsStore } from "../../store/useConsultationsStore";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";

type ConsultationPopupCardProps = {
  consultation: Consultation;
};

const ConsultationPopupCard: FC<ConsultationPopupCardProps> = ({ consultation }) => {
  const [cancel, setCancel] = useState<boolean>(false);

  const { deleteConsultation } = useConsultationsStore((state) => state);

  const onDelete = () => {
    deleteConsultation(consultation.id);
  };

  return (
    <>
      {cancel ? (
        <>
          <div className={styles.cancelContainer}>
            <h3>Хотите отменить сессию?</h3>
            <p>Отменить консультацию специалиста можно не позже чем за два дня до назначенной даты</p>
            <div className={styles.buttons}>
              <Button
                onClick={onDelete}
                leftIcon={<CancelWhiteIcon />}
                danger
                variant="primary"
                size="medium"
                type="button"
              >
                Подтвердить
              </Button>
              <Button
                variant="white"
                size="medium"
                type="button"
                onClick={() => {
                  setCancel(false);
                }}
                className={styles.notCancel}
              >
                Не отменять
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.date}>
              <span>{fromISOtoRussianLocale(consultation.day)}</span>
              <span>{consultation.time}</span>
            </div>
            <Button
              onClick={() => {
                setCancel(true);
              }}
              variant="white"
              size="small"
              type="button"
            >
              <CancelIcon />
            </Button>
          </div>
          <h3>Нутрицолог</h3>
          <p>
            {consultation.user.first_name} {consultation.user.last_name && consultation.user.last_name}
          </p>
          <Button
            onClick={() => {
              location.href = consultation.link;
            }}
            className={styles.consultationLink}
            leftIcon={<VideoChat />}
            disabled={!consultation.link}
            rightIcon={<RightArrowIcon />}
            variant="outlined-gray"
            size="medium"
            type="button"
          >{`Подключиться по видеозвонку`}</Button>
        </div>
      )}
    </>
  );
};

export default ConsultationPopupCard;
