import { motion } from "framer-motion";
import React, { FC, useEffect, useRef, useState } from "react";

import styles from "./index.module.css";

type Props = {
  max?: number;
  min?: number;
  setValue: (value: string) => void;
  initValue: string;
};

const inputVariants = {
  hidden: (up: boolean) => ({
    y: up ? 20 : -20,
    opacity: 0,
  }),
  show: {
    opacity: 1,
    y: 0,
  },
  exit: {
    y: -20,
  },
};

const numberToString = (number: number): string => {
  if (number >= 0 && number <= 9) {
    return "0" + number;
  }
  return String(number);
};

const QuestionnaireTimer: FC<Props> = ({ min = 0, max = 24, setValue: setPropsValue, initValue }) => {
  const [value, setValue] = useState<string>(initValue || "00");
  const [offAnimation, setOffAnimation] = useState<boolean>(false);
  const [up, setUp] = useState<boolean>(false);
  const isInitialMount = useRef(true);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setPropsValue(value);
    }
  }, [value]);

  const onChange = (input: string) => {
    const number = Number(input);
    if (number >= min && number < max) {
      setValue(numberToString(number));
    }
  };

  const upClick = () => {
    setValue((v) => {
      setOffAnimation(false);
      setUp(true);
      let number = Number(v);
      number += 1;
      if (number >= min && number < max) {
        return numberToString(number);
      }
      return numberToString(min);
    });
  };

  const downClick = () => {
    setValue((v) => {
      setOffAnimation(false);
      setUp(true);
      let number = Number(v);
      number -= 1;
      if (number >= min && number < max) {
        return numberToString(number);
      }
      return numberToString(max - 1);
    });
  };

  const startHold = (callback: () => void) => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      callback();
    }, 150);
  };

  const stopHold = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <div className={styles.container}>
      <button
        className={styles.button}
        onMouseDown={() => startHold(upClick)}
        onTouchStart={() => startHold(upClick)}
        onTouchCancel={stopHold}
        onTouchMove={stopHold}
        onTouchEnd={stopHold}
        onClick={upClick}
        onMouseLeave={stopHold}
        onMouseUp={stopHold}
      >
        &#9650;
      </button>
      <div className={styles.inputWrapper}>
        <motion.input
          autoFocus={offAnimation}
          onFocus={() => setOffAnimation(true)}
          key={value}
          variants={inputVariants}
          initial={offAnimation ? "" : "hidden"}
          animate={offAnimation ? "" : "show"}
          custom={up}
          className={styles.input}
          value={String(value)}
          onChange={(e) => onChange(e.target.value)}
          type="text"
        />
      </div>
      <button
        onMouseDown={() => startHold(downClick)}
        onClick={downClick}
        onTouchStart={() => startHold(upClick)}
        onTouchCancel={stopHold}
        onTouchMove={stopHold}
        onTouchEnd={stopHold}
        onMouseLeave={stopHold}
        onMouseUp={stopHold}
        className={styles.button}
      >
        &#9660;
      </button>
    </div>
  );
};

export default QuestionnaireTimer;
