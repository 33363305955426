import React, { createContext, useEffect } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";

import { User } from "../../lib/models/User";

type OrgUserProviderContextType = {
  user: User;
};

export const OrgUserProviderContext = createContext<OrgUserProviderContextType | null>(null);

const OrgUserProvider = () => {
  const navidate = useNavigate();
  const user = useLoaderData() as User;

  useEffect(() => {
    window.onmessage = function (e) {
      const data = e.data;
      if (typeof data === "string" && data.startsWith("goto:")) {
        const path = data.split(":").at(1);
        if (path) {
          navidate(path);
        }
      }
    };
  }, [navidate]);

  return (
    <>
      {user ? <OrgUserProviderContext.Provider value={{ user }}>{<Outlet />}</OrgUserProviderContext.Provider> : null}
    </>
  );
};

export default OrgUserProvider;
