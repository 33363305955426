import { Answer, DetailQuestionnaire, Question, Section } from "../../lib/models/questionnaire";
import { http } from "../index";

export const getAvailableSections = async () => {
  const response = await http.get<Section[]>("/api/questionnaire/available_sections");
  return response.data;
};

export const getPatientQuestionnaire = async (patientId: number) => {
  const response = await http.get<DetailQuestionnaire[]>(`/api/questionnaire/patients/${patientId}/questionnaire/`);
  return response.data;
};

export const getSectionAvailableQuestions = async (sectionId: number) => {
  const response = await http.get<Question[]>(`/api/questionnaire/available_sections/${sectionId}/available_questions`);
  return response.data;
};

export const sendAnswer = async (questionId: string, answer: Answer) => {
  const response = await http.post<Answer>(`/api/questionnaire/send_answer/`, {
    question_id: questionId,
    ...answer,
  });
  return response.data;
};

export const getRequiredQuestions = async () => {
  const response = await http.get<Question[]>("/api/questionnaire/required_questions/");
  return response.data;
};
