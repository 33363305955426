import { animate } from "framer-motion";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { PrimaryColors } from "../../../lib/consts/colors";
import styles from "./CircularProgressBar.module.css";

interface CircleProgressBarProps {
  primaryColor?: string;
  backgroundColor?: string;
  percentage: number;
  size: number;
}

const CircularProgressBarWrapper: FC<Omit<CircleProgressBarProps, "size">> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      ref.current && observer.unobserve(ref.current);
    };
  }, []);

  return (
    <div ref={ref} className={styles.container}>
      {width && <CircularProgressBar {...props} size={width} />}
    </div>
  );
};

const CircularProgressBar: React.FC<CircleProgressBarProps> = (props) => {
  const { percentage = 50, primaryColor = PrimaryColors._900, backgroundColor = PrimaryColors._300 } = props;
  const { size, strokeWidth } = useMemo(() => {
    const strokeWidth = (props.size / 100) * 5;
    const size = props.size - strokeWidth / 2;
    return {
      size,
      strokeWidth,
    };
  }, [props]);
  const center = useMemo(() => size / 2, [size]);
  const radius = useMemo(() => center - strokeWidth, [center, strokeWidth]);
  const [animatedPercentage, setAnimatedPercentage] = useState<number>(0);
  const circumference = useMemo(() => 2 * Math.PI * radius, [radius]);

  const offset = useMemo(() => ((100 - percentage) / 100) * circumference, [percentage, circumference]);
  const [animatedOffset, setAnimatedOffset] = useState(circumference);

  useEffect(() => {
    animate(animatedPercentage, percentage, {
      duration: 1.5,
      onUpdate: (v) => {
        setAnimatedPercentage(Math.round(v));
      },
      type: "tween",
    });
  }, [percentage]);

  useEffect(() => {
    animate(animatedOffset, offset, {
      duration: 1.5,
      onUpdate: (v) => {
        setAnimatedOffset(v);
      },
      type: "tween",
    });
  }, [offset]);

  return (
    <div className={styles.container}>
      <div className={styles.percentage} style={{ fontSize: `${(size / 100) * 24}px`, color: primaryColor }}>
        {animatedPercentage}%
      </div>
      <div className={styles.progressWrapper} style={{ width: `${size}px`, height: `${size}px` }}>
        <svg className={styles.CircleProgress} width={center * 2} height={center * 2}>
          <circle
            fill="transparent"
            style={{ stroke: backgroundColor }}
            strokeWidth={strokeWidth}
            r={radius}
            cx={center}
            cy={center}
          />
          <circle
            className={styles.CircleProgressBar}
            fill="transparent"
            strokeWidth={strokeWidth}
            style={{ stroke: primaryColor, strokeDashoffset: animatedOffset }}
            strokeDasharray={`${circumference} ${circumference}`}
            r={radius}
            cx={center}
            cy={center}
          />
        </svg>
      </div>
    </div>
  );
};

export default CircularProgressBarWrapper;
